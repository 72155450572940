import { UseQueryResult } from "@tanstack/react-query";
import { useCallback, useState } from "react";

// 100 per page

export interface IApiQuery {
  take: number;
  skip: number;
}

export class ApiQuery {
  _query: IApiQuery = {
    take: 100,
    skip: 0,
  };
  constructor(skip: number) {
    this._query = {
      take: 100,
      skip: skip,
    };
  }
  serialize(): string {
    return `?Skip=${this._query.skip}&Take=${this._query.take}`;
  }
}

// export const useDataProvider = <T>(props: UseQueryResult<T[], Error>) => {
export const useDataProvider = () => {
  const [query, setQuery] = useState<IApiQuery>({
    take: 100,
    skip: 0,
  });
  const fetchMore = useCallback(() => {
    setQuery((q) => {
      return {
        take: 100,
        skip: q.skip + 100,
      };
    });
  }, []);

  return { fetchMore, query: new ApiQuery(0) };
};
