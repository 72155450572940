import { useState } from "react";
import "./QuickRoll.css";
import { rollDice } from "../../../../shared/services/cuni/utils";
import { DiceRollView } from "../../../character/components/dice-roll/DiceRollModal";
import { TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export const QuickRoll = () => {
  const { t } = useTranslation();
  const [result, setResult] = useState();
  const [lastFormula, setLastFormula] = useState("");
  return (
    <div className="">
      <Typography>{t("GM.QUICK_ROLL")}</Typography>
      <div className="quick-roll-root">
        <DiceRollView
          onClick={(formula) => {
            setLastFormula(formula);
            const expressionResult = rollDice(formula);
            setResult(expressionResult);
          }}
        />
        <TextField disabled value={result} style={{ maxWidth: "4rem" }} />
        <TextField
          label={t("GM.PRESS_ENTER")}
          value={lastFormula}
          onChange={(e) => setLastFormula(e.target.value)}
          onKeyDown={(e) => {
            if (e.code === "Enter") {
              const expressionResult = rollDice(lastFormula);
              setResult(expressionResult);
            }
          }}
        />
      </div>
    </div>
  );
};
