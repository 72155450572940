import { useDrag } from "react-dnd";
import { useCards, useDecks } from "../chat/hooks/useCards";
import { CardFlyweight, CharacterCardPanel } from "../../model/Game";
import { MapHexDrop } from "../map-hex/MapHexDrop";
import { CardView } from "./CardView";
import { usePutOnTable } from "../board/use-put-on-table";

export interface GameCardViewProps {
  card: CardFlyweight;
  panel?: CharacterCardPanel;
  actions?: JSX.Element;
}

export const GameCardView = (props: GameCardViewProps) => {
  const cards = useCards();
  const decks = useDecks();

  const card = cards.find((x) => x.key === props.card.cardKey);
  const deck = decks.find((x) => x.key === card?.deckKey);

  const createIem = () => {
    const drop: MapHexDrop = {
      instance: props.card,
      card: card!,
      panel: props.panel!,
      type: "FROM_HAND",
    };
    return drop;
  };

  const [{ isDragging }, dragRef] = useDrag(
    () => ({
      type: "TOKEN-CARD",
      item: createIem(),
      collect: (monitor) => {
        return {
          opacity: monitor.isDragging() ? 0 : 1,
          isDragging: monitor.isDragging(),
        };
      },
    }),
    [props.card.key, props.panel?.key],
  );

  const { dropOnTable } = usePutOnTable();

  const onDoubleClick = () => {
    dropOnTable(createIem());
  };

  if (!card) return <></>;
  return (
    <CardView
      dragRef={dragRef}
      {...card}
      color={card.color || deck?.cardColor}
      textColor={card.textColor || deck?.cardTextColor}
      isDragging={isDragging}
      uiType={card.uiType}
      onDoubleClick={onDoubleClick}
      actions={props.actions}
    />
  );
};
