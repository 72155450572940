import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { ModalProps } from "../../../../shared/components/modal/ModalProps";
import "./DiceRollModal.css";
import { cuni } from "../../../../shared/services/cuni/cuni";
import { useTranslation } from "react-i18next";

type DiceRollModalProps = ModalProps;

export const DiceRollModal = (props: DiceRollModalProps) => {
  const { open, handleClose } = props;
  const { t } = useTranslation();
  return (
    <Modal open={open} onClose={handleClose}>
      <Box className="modal">
        <Typography variant="h6" component="h2">
          {t("ROLL.ROLL")}
        </Typography>
        <Typography id="modal-avatar-description" sx={{ mt: 2 }}>
          {t("ROLL.DESC")}
        </Typography>
        {/* TODO: think about single css for avatars */}
        <Box className="avatarModal__container">
          <DiceRollView
            onClick={(formula) => {
              cuni.userRoll(formula);
              handleClose();
            }}
          />
        </Box>
        <Button onClick={handleClose}> {t("COMMON.CLOSE")}</Button>
      </Box>
    </Modal>
  );
};

interface DiceRollViewProps {
  onClick: (formula: string) => void;
}

export const DiceRollView = (props: DiceRollViewProps) => {
  const { onClick } = props;
  const all = [4, 6, 8, 10, 12, 20, 100].map((x) => {
    const formula = `k${x}`;
    return (
      <div
        key={x}
        className="avatarModal__item dice-roll-modal"
        onClick={() => {
          onClick(formula);
        }}
      >
        {formula}
      </div>
    );
  });

  return <>{all}</>;
};
