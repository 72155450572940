import { Typography, Button } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { GameDice, SETTINGS_ADMIN_KEY } from "../../model/Game";
import { cuni } from "../../shared/services/cuni/cuni";
import { uuid } from "../../shared/utils/uuid";
import { useGame } from "../character/hooks/useGame";
import { QuickEditModalProvider } from "../quick-edit/hooks/useQuickEdit";
import { Table, TableCellInputProps } from "../../shared/components/ui/Table";
import { SwitchView } from "../../shared/components/ui/switch-view/SwitchView";
import { DiceDetailEditView } from "./DiceDetailEditView";
import { DiceView } from "./DiceView";

export const DiceEditView = () => {
  const { game } = useGame();
  const { t } = useTranslation();

  const upload = useCallback(
    (key: string) => {
      const rows = game.root.settings?.dices || [];
      rows.push({
        key,
        noParse: true,
      });
      cuni.update(SETTINGS_ADMIN_KEY, "dices", rows);
    },
    [game],
  );

  const add = useCallback(() => upload(uuid()), [upload]);
  const [view, setView] = useState<string | null>(null);

  const [value, setValue] = useState<GameDice>();
  const [tokenIndex, setTokenIndex] = useState<number>();

  // Update view when token is updated
  useEffect(() => {
    if (!value) return;
    const dices = game.root.settings?.dices.find((x) => x.key === value.key);
    setValue(dices);
  }, [game, value]);

  const { rows, headers } = useMemo(() => {
    const items = game.root.settings?.dices || [];
    const headers = [
      {
        key: "key",
        title: t("GAME.DICE"),
        cell: (
          value: GameDice,
          idx: number,
          { variant }: TableCellInputProps,
        ) =>
          variant === "card" ? (
            <DiceView item={value} fixed />
          ) : value.name ? (
            value.name
          ) : (
            value.key
          ),
      },
      {
        key: "action",
        title: t("COMMON.ACTION"),
        cell: (value: GameDice, index: number) => (
          <Button
            onClick={() => {
              setValue(value);
              setTokenIndex(index);
              setView("detail");
            }}
          >
            {t("COMMON.OPEN")}
          </Button>
        ),
      },
    ];
    const rows = items.map((x) => ({
      ...x,
      action: "",
    }));
    return { rows, headers };
  }, [game, t]);

  return (
    <QuickEditModalProvider
      character={{ ...game.root.settings!, key: SETTINGS_ADMIN_KEY }}
    >
      <SwitchView
        setView={setView}
        view={view}
        viewComponents={{
          detail: <DiceDetailEditView index={tokenIndex!} dice={value!} />,
        }}
      >
        <div className="column" style={{ paddingRight: "1rem" }}>
          <Typography>{t("CARD.OVERRIDE_INFO")}</Typography>
          <Button variant="outlined" onClick={add}>
            {t("GAME.ADD_DICE")}
          </Button>
          <Table
            titleSufix="dices"
            directList
            root={{ ...game.root.settings, key: SETTINGS_ADMIN_KEY }}
            headers={headers}
            className="paper-outline"
            rows={rows}
          />
        </div>
      </SwitchView>
    </QuickEditModalProvider>
  );
};
