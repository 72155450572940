import React, { PropsWithChildren, useState } from "react";
import { Popover as PopoverMui } from "@mui/material";

interface PopoverComponentProps {
  renderButton: (
    handleClick: (e: React.MouseEvent<HTMLElement>) => void,
  ) => JSX.Element;
  forceClose?: boolean;
  minWidth?: React.CSSProperties["minWidth"];
  anchor?: "top" | "bottom";
}

export const Popover = ({
  children,
  renderButton,
  forceClose,
  minWidth,
  anchor = "bottom",
}: PropsWithChildren<PopoverComponentProps>) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl) && !forceClose;
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      {renderButton(handleClick)}
      <PopoverMui
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: anchor,
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: anchor === "top" ? "bottom" : "top",
          horizontal: "center",
        }}
      >
        <div style={{ minWidth }} className="paper column">
          {children}
        </div>
      </PopoverMui>
    </>
  );
};
