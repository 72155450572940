import AWS from "aws-sdk";
import { uuid } from "../utils/uuid";
import { useCallback, useMemo } from "react";
import { useResourceCount } from "./use-resources";
import { Resource } from "../../model/Resource";
import { apiClient } from "../services/apiClient";
import { config } from "../../config";
import { useUser } from "../../features/auth/useUser";

const spacesEndpoint = new AWS.Endpoint("fra1.digitaloceanspaces.com"); // Replace with your region's endpoint
const s3 = new AWS.S3({
  endpoint: spacesEndpoint,
  accessKeyId: "DO00LJWYL9BNQ6KZMEPR", // Replace with your access key
  secretAccessKey: "MtTY4qXqbmnPkps7yzxN9dGd65szShKSqm1pSgmPYZA", // Replace with your secret key
});

export const useAws = () => {

  const { data: count, refetch: refetchCount } = useResourceCount();
  const currentValue = count?.size || 0;

  const user = useUser();

  const upload = useCallback(
    async (files: File[]) => {
      const filteredFiles = files.filter((file) => {
        if (file.size > 1024 * 1024 * 10) {
          alert(`File ${file.name} is too large. Max size is 10MB.`);
          return false;
        }
        return true;
      });

      const sumFile = filteredFiles.reduce((p, c) => p + c.size, 0);
      const alreadySavedFiles = currentValue;

      if (sumFile + alreadySavedFiles > user.licensePlanData.size) {
        alert(`Maximum size of your plan is 100MB.`);
        return;
      }

      if (filteredFiles.length === 0) return;

      const result: { key: string; location: string }[] = [];
      for (const file of filteredFiles) {
        try {
          const nameParts = file.name.split("/");
          const fileName = nameParts.pop();
          if (!fileName) throw new Error("File has no name");
          const ext = fileName.split(".")[1];
          const key = [...nameParts, `${uuid()}.${ext}`].join("/");

          const params = {
            Bucket: "cuni", // Replace with your Space name
            Key: key,
            Body: file,
            ACL: "public-read", // or 'private'
          };

          const uploadResult = await new Promise<{
            key: string;
            location: string;
          }>((resolve, reject) => {
            s3.upload(
              params,
              async (err: any, data: AWS.S3.ManagedUpload.SendData) => {
                if (err) {
                  console.error(`Error uploading file ${file.name}: `, err);
                  reject(err);
                  return;
                }
                try {
                  console.log(`File uploaded successfully: ${data.Location}`);
                  await apiClient.post<Resource>(
                    `${config.baseUrl}/api/resource`,
                    {
                      key: data.Key,
                      url: data.Location,
                      size: file.size,
                    },
                  );
                  // onUpload(data.Key, data.Location);
                  resolve({ key: data.Key, location: data.Location });
                } catch (apiError) {
                  console.error(
                    `Error calling API for file ${file.name}: `,
                    apiError,
                  );
                  reject(apiError); // Reject the promise on API error
                }
              },
            );
          });
          result.push(uploadResult);
        } catch (err) {
          console.error(`Failed to upload ${file.name}: `, err);
        }
      }
      return result;
    },
    [currentValue, user.licensePlanData.size],
  );

  const remove = useCallback(
    async (id: number | string | string[]) => {
      const removeOne = async (resource?: Resource) => {
        if (!resource) {
          console.error("There is no resource.");
          return;
        }
        const params: AWS.S3.DeleteObjectRequest = {
          Bucket: "cuni", // Replace with your Space name
          Key: resource.key,
        };

        await apiClient.delete<Resource>(
          `${config.baseUrl}/api/resource/${resource.id}`,
        );
        s3.deleteObject(params, (err, data) => {
          console.log(err, data);
        });

        // refetch();
        refetchCount();
      };

      if (Array.isArray(id)) {
        const tasks = id?.map(async (y) => {
          const resource = await apiClient.get<Resource[]>(
            `${config.baseUrl}/api/resource/?Where= Key == "${id}"`,
          );

          if (resource.data.length === 1)
            return await removeOne(resource.data[0]);
        });
        await Promise.all(tasks);
      } else if (typeof id === "string") {
        const resource = await apiClient.get<Resource[]>(
          `${config.baseUrl}/api/resource/?Where= Key == "${id}"`,
        );
        if (resource.data.length === 1)
          return await removeOne(resource.data[0]);
      } else {
        const resource = await apiClient.get<Resource>(
          `${config.baseUrl}/api/resource/${id}`,
        );
        await removeOne(resource.data);
      }
    },
    [refetchCount],
  );

  return useMemo(() => {
    return {
      upload,
      remove,
    };
  }, [remove, upload]);
};
