import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

const appName = "Cuni - board game helper";
const contactAdress = "cuni@hydrasoftware.pl";

const components: Record<string, JSX.Element> = {
  en: (
    <Box maxWidth={"800px"} className="column paper">
      <h1>Privacy Policy</h1>

      <div className="document-section">
        <h2>1. General Information</h2>
        <p>
          This privacy policy describes how {appName} (“Application”) processes,
          stores, and protects the data provided by users (“Users”) who use our
          application. Please read the following terms carefully before using
          the Application.
        </p>
      </div>

      <div className="document-section">
        <h2>2. Content Provided by Users</h2>
        <p>
          The Application allows Users to upload content in the form of digital
          images, such as PNG, JPEG, and other graphic file types. Users can
          scan and share images that contain elements of games that they
          physically own.
        </p>
      </div>

      <div className="document-section">
        <h2>3. Responsibility for Uploaded Content</h2>
        <p>
          Each User is fully responsible for the content they upload to the
          Application. This includes, but is not limited to, responsibility for
          infringement of copyright, intellectual property rights, privacy, or
          other legal provisions.
        </p>
        <p>
          By uploading any content, the User declares that they hold all
          necessary rights to its use and that the content does not infringe on
          the rights of third parties. {appName} is not responsible for any
          claims or damages resulting from rights violations caused by content
          uploaded by the User.
        </p>
      </div>

      <div className="document-section">
        <h2>4. Use of Data</h2>
        <p>
          The Application does not share or sell content or data uploaded by
          Users to third parties. Collected data is used solely to ensure the
          application’s functionality and purpose.
        </p>
      </div>

      <div className="document-section">
        <h2>5. Data Storage</h2>
        <p>
          Content uploaded by Users is stored on {appName}'s servers in
          compliance with security and data protection requirements.
          {appName} takes all technical and organizational measures to protect
          data from unauthorized access, modification, or loss.
        </p>
      </div>

      <div className="document-section">
        <h2>6. Consent to Data Processing</h2>
        <p>
          By using the Application, the User consents to data processing in
          accordance with this Privacy Policy. The User can withdraw consent at
          any time by deleting their account in the application or by contacting
          us at {contactAdress}.
        </p>
      </div>

      <div className="document-section">
        <h2>7. User Rights</h2>
        <p>
          Users have the right to access, correct, delete, and restrict the
          processing of their data. To exercise these rights, please contact us
          at {contactAdress}.
        </p>
      </div>

      <div className="document-section">
        <h2>8. Changes to the Privacy Policy</h2>
        <p>
          We reserve the right to make changes to this Privacy Policy. Users
          will be informed of any changes via a notice in the Application or by
          email to the address provided during registration.
        </p>
      </div>

      <div className="document-section">
        <h2>9. Contact</h2>
        <p>
          For any questions or concerns regarding this Privacy Policy, please
          contact us at: {contactAdress}.
        </p>
      </div>
    </Box>
  ),
  pl: (
    <Box maxWidth={"800px"} className="column paper">
      <h1>Polityka Prywatności</h1>

      <div className="document-section">
        <h2>1. Informacje ogólne</h2>
        <p>
          Niniejsza polityka prywatności opisuje sposób, w jaki {appName} („Aplikacja”) przetwarza, przechowuje i chroni dane
          przekazywane przez użytkowników („Użytkownicy”) korzystających z
          naszej aplikacji. Przed rozpoczęciem korzystania z Aplikacji prosimy o
          uważne zapoznanie się z poniższymi warunkami.
        </p>
      </div>

      <div className="document-section">
        <h2>2. Przekazywanie Treści przez Użytkowników</h2>
        <p>
          Aplikacja umożliwia Użytkownikom przesyłanie treści w formie obrazów
          cyfrowych, takich jak pliki PNG, JPEG oraz inne typy plików
          graficznych. Użytkownicy mogą skanować i udostępniać obrazy
          zawierające elementy gier, które posiadają fizycznie.
        </p>
      </div>

      <div className="document-section">
        <h2>3. Odpowiedzialność za Przesyłane Treści</h2>
        <p>
          Każdy Użytkownik ponosi pełną odpowiedzialność za treści, które
          przesyła do Aplikacji. Obejmuje to, ale nie ogranicza się do,
          odpowiedzialności za naruszenie praw autorskich, praw własności
          intelektualnej, prywatności lub innych przepisów prawa.
        </p>
        <p>
          Przesyłając jakiekolwiek treści, Użytkownik oświadcza, że posiada
          wszelkie prawa do ich użycia oraz że treści te nie naruszają praw osób
          trzecich. {appName} nie ponosi odpowiedzialności za
          jakiekolwiek roszczenia lub szkody wynikające z naruszenia praw przez
          przesłane przez Użytkownika treści.
        </p>
      </div>

      <div className="document-section">
        <h2>4. Wykorzystanie Danych</h2>
        <p>
          Aplikacja nie udostępnia ani nie sprzedaje treści ani danych
          przesłanych przez Użytkowników osobom trzecim. Zgromadzone dane są
          wykorzystywane jedynie w celu zapewnienia działania aplikacji oraz
          dostarczania jej funkcjonalności zgodnie z jej przeznaczeniem.
        </p>
      </div>

      <div className="document-section">
        <h2>5. Przechowywanie Danych</h2>
        <p>
          Treści przesyłane przez Użytkowników są przechowywane na serwerach
          {appName} zgodnie z wymogami bezpieczeństwa i ochrony danych
          osobowych. {appName} podejmuje wszelkie techniczne i
          organizacyjne środki, aby chronić dane przed nieuprawnionym dostępem,
          modyfikacją lub utratą.
        </p>
      </div>

      <div className="document-section">
        <h2>6. Zgoda na Przetwarzanie Danych</h2>
        <p>
          Korzystając z Aplikacji, Użytkownik wyraża zgodę na przetwarzanie
          danych zgodnie z niniejszą Polityką Prywatności. Użytkownik może w
          każdej chwili cofnąć zgodę, usuwając konto w aplikacji lub kontaktując
          się z nami pod adresem {contactAdress}.
        </p>
      </div>

      <div className="document-section">
        <h2>7. Prawa Użytkowników</h2>
        <p>
          Użytkownikom przysługują prawa do wglądu, poprawiania, usuwania i
          ograniczenia przetwarzania swoich danych. W celu skorzystania z tych
          praw należy skontaktować się z nami pod adresem {contactAdress}.
        </p>
      </div>

      <div className="document-section">
        <h2>8. Zmiany Polityki Prywatności</h2>
        <p>
          Zastrzegamy sobie prawo do wprowadzenia zmian w niniejszej Polityce
          Prywatności. O wszelkich zmianach Użytkownicy zostaną poinformowani
          poprzez komunikat w Aplikacji lub drogą mailową na adres podany
          podczas rejestracji.
        </p>
      </div>

      <div className="document-section">
        <h2>9. Kontakt</h2>
        <p>
          W razie jakichkolwiek pytań lub wątpliwości dotyczących niniejszej
          Polityki Prywatności prosimy o kontakt pod adresem: {contactAdress}
        </p>
      </div>
    </Box>
  ),
};
export const PrivacyPolicyPage = () => {
  const { i18n } = useTranslation();
  return (
    <Box
      className="page"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      {components[i18n.language || "en"]}
    </Box>
  );
};
