import {
  Box,
  Typography,
  Divider as MuiDivider,
  SxProps,
  Theme,
} from "@mui/material";

interface DividerProps {
  title: string;
  sx?: SxProps<Theme>;
}

export const Divider = (props: DividerProps) => {
  const { title, sx } = props;
  return (
    <Box display="flex" alignItems="center" gap="1rem" sx={sx}>
      <Typography variant="caption">{title}</Typography>
      <MuiDivider style={{ flex: 1 }} />
    </Box>
  );
};
