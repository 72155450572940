import { config } from "../../config";
import { apiClient } from "../../shared/services/apiClient";
import { uuid } from "../../shared/utils/uuid";

export const usePayments = () => {};

export const testPayments = async () => {
  const response = await apiClient
    .post(`${config.baseUrl}/payments/test`)
    .then((res) => res.data);
  console.log(response);
};

export const completePayments = async () => {
  try {
    const response = await apiClient
      .post(`${config.baseUrl}/payments`, {
        paymentSessionId: uuid(),
        totalPriceGross: 1,
        paymentProductType: 1,
      })
      .then((res) => res.data);
    window.location.replace(response.redirectUrl)
  } catch (error) {}
};

export const updatePayments = async () => {
    try {
      const response = await apiClient
        .post(`${config.baseUrl}/payments/update`, {
          paymentSessionId: uuid(),
          totalPriceGross: 1,
          paymentProductType: 1,
        })
        .then((res) => res.data);
    } catch (error) {}
  };
  