import { useEffect } from "react";
import { Game } from "../../../model/Game";
import { gameStorage } from "../../../shared/services/storage";
import { webHookStore } from "../../../store/WebHookStore";

export const useGameConnection = () => {
  useEffect(() => {
    const game = gameStorage.get<Game>();
    if (!game) throw new Error("There should be game already");
    webHookStore.connect(game.id);
    return () => {
      webHookStore.disconnect();
    };
  }, []);
};
