import { useMemo } from "react";
import { User } from "../../model/User";
import { useAuth } from "./useAuth";
import { PLAN_DATA_MAP } from "./AuthContext";
import { usePaymentTypes } from "../../pages/PaymentsPage";

export const useUser = (): User => {
  const { data: auth } = useAuth();

  const plans = usePaymentTypes();
  const authJson = JSON.stringify(auth);
  return useMemo(() => {
    if (!auth) throw new Error("No user");

    const licensePlanData = auth?.licensePlan
      ? plans.data?.find((x) => x.name === auth?.licensePlan) ||
        plans.data?.find((x) => x.name === "Free") ||
        PLAN_DATA_MAP.Free
      : PLAN_DATA_MAP.Free;
    return {
      id: auth.userId,
      licensePlanData,
      ...auth,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authJson, plans.data]);
};

export const useUnsafeUser = (): User | null => {
  const { data: auth } = useAuth();

  const authJson = JSON.stringify(auth);
  return useMemo(() => {
    if (!auth) return null;

    const licensePlanData = auth?.licensePlan
      ? PLAN_DATA_MAP[auth?.licensePlan]
      : PLAN_DATA_MAP.Free;
    return {
      id: auth.userId,
      licensePlanData,
      ...auth,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authJson]);
};
