import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

const appName = "Cuni - board game helper";
const fullName =
  "HYDRA SOFTWARE Hubert Nosek, ul. Kazimierza Jarochowskiego 39A/12, 60-248 Poznań, NIP: 7851805090, REGON: 369831710";
const contactAdress = "cuni@hydrasoftware.pl";
const date = "10.11.2024";

const components: Record<string, JSX.Element> = {
  en: (
    <Box maxWidth="800px" className="column paper">
      <h1>Terms of Use for the {appName} App</h1>

      <div className="document-section">
        <h2>§ 1 General Provisions</h2>
        <p>
          These Terms of Use specify the rules and conditions for using the
          {appName} mobile and web application (hereinafter: the “Application”),
          owned by {fullName} (hereinafter: the “Service Provider”).
        </p>
        <p>
          The Terms of Use are available free of charge in electronic form on
          the website and in the Application itself, enabling users to review
          them before using the Application.
        </p>
        <p>Use of the Application implies acceptance of the Terms of Use.</p>
      </div>

      <div className="document-section">
        <h2>§ 2 Definitions</h2>
        <p>
          <strong>User</strong> – a natural or legal person using the
          Application.
        </p>
        <p>
          <strong>Service Provider</strong> – the entity managing and providing
          access to the Application.
        </p>
        <p>
          <strong>Application</strong> – software enabling users to add and
          store digital images, particularly those related to games, such as
          cards and other elements.
        </p>
      </div>

      <div className="document-section">
        <h2>§ 3 Scope and Rules of Using the Application</h2>
        <p>
          The Application allows Users to upload digital images of game elements
          (e.g., cards) and store them in their Application account to
          facilitate gameplay with other Users.
        </p>
        <p>
          Adding and sharing content in the Application is voluntary and is done
          at the User’s own responsibility.
        </p>
        <p>
          The User agrees to use the Application in a manner consistent with
          applicable law, social standards, and these Terms of Use.
        </p>
      </div>

      <div className="document-section">
        <h2>§ 4 User’s Responsibility for Content</h2>
        <p>
          The User is fully responsible for any content, including images, they
          post in the Application.
        </p>
        <p>
          The User declares that they hold all necessary rights to publish and
          use the content in the Application, including copyrights and
          intellectual property rights.
        </p>
        <p>
          The Service Provider is not liable for violations of third-party
          rights arising from content posted by Users.
        </p>
        <p>
          If a third party raises claims due to the infringement of their
          rights, the User is required to indemnify the Service Provider and
          cover any damages and costs arising from such claims.
        </p>
      </div>

      <div className="document-section">
        <h2>§ 5 Service Provider’s Responsibility</h2>
        <p>
          The Service Provider strives to ensure uninterrupted operation of the
          Application but does not guarantee full availability or continuity of
          the Application’s operation.
        </p>
        <p>
          The Service Provider is not responsible for any interruptions in the
          Application’s functionality caused by technical issues beyond its
          control or by Users’ actions.
        </p>
        <p>
          The Service Provider reserves the right to remove content that
          violates the Terms of Use, applicable law, good practices, or the
          rights of third parties.
        </p>
      </div>

      <div className="document-section">
        <h2>§ 6 Registration and User Account</h2>
        <p>
          Using the Application requires the User to register and create an
          account.
        </p>
        <p>
          The User is obligated to provide truthful information during
          registration and update it in case of changes.
        </p>
        <p>
          The User is required to keep their login information confidential and
          not share it with third parties.
        </p>
      </div>

      <div className="document-section">
        <h2>§ 7 Payments</h2>
        <p>
          Some features of the Application may require payment. In such cases,
          fees will be charged according to the current pricing available in the
          Application.
        </p>
        <p>
          All payments are made through the Przelewy24 system or another
          selected online payment platform.
        </p>
        <p>
          The Service Provider reserves the right to modify the price list and
          introduce new paid features.
        </p>
      </div>

      <div className="document-section">
        <h2>§ 8 Copyright and Intellectual Property</h2>
        <p>
          The Application and all graphical, textual elements, and source code
          are protected by copyright and are the property of the Service
          Provider or entities with whom the Service Provider has concluded
          relevant agreements.
        </p>
        <p>
          The User agrees not to infringe upon copyright and intellectual
          property rights related to the Application.
        </p>
      </div>

      <div className="document-section">
        <h2>§ 9 Complaints</h2>
        <p>
          Any complaints regarding the Application's operation can be submitted
          via email to: {contactAdress}.
        </p>
        <p>
          A complaint should include a detailed description of the issue and the
          User's contact details.
        </p>
        <p>
          The Service Provider commits to resolving complaints within 14
          business days of receiving them.
        </p>
      </div>

      <div className="document-section">
        <h2>§ 10 Amendments to the Terms of Use</h2>
        <p>
          The Service Provider reserves the right to amend the Terms of Use at
          any time, particularly in case of changes in law or Application
          functionality.
        </p>
        <p>
          Users will be informed of any changes to the Terms of Use via a
          notification in the Application or electronically.
        </p>
        <p>
          The amended Terms of Use will become effective on the date indicated
          by the Service Provider, no earlier than 7 days from the date of
          publication.
        </p>
      </div>

      <div className="document-section">
        <h2>§ 11 Final Provisions</h2>
        <p>
          Matters not regulated by these Terms of Use are subject to Polish law.
        </p>
        <p>
          Any disputes arising from the use of the Application will be resolved
          by the court competent for the Service Provider's registered office.
        </p>
      </div>

      <div className="document-section">
        <h2>§ 12 Withdrawal from the Agreement</h2>
        <p>
          In accordance with the Consumer Rights Act, the User has the right to
          withdraw from the agreement within 14 calendar days, unless:
        </p>
        <ul>
          <li>
            The digital product was delivered immediately upon payment, and the
            User consented to begin service provision before the withdrawal
            period ended.
          </li>
          <li>
            The User, by proceeding with the purchase, consents to immediate
            service commencement, thereby waiving the right to withdraw once
            downloading or using the digital product begins.
          </li>
        </ul>
        <p>This Terms of Use document takes effect on {date}.</p>
      </div>
    </Box>
  ),
  pl: (
    <Box maxWidth={"800px"} className="column paper">
      <h1>Regulamin Korzystania z Aplikacji {appName}</h1>

      <div className="document-section">
        <h2>§ 1 Postanowienia ogólne</h2>
        <p>
          Niniejszy Regulamin określa zasady i warunki korzystania z aplikacji
          mobilnej i internetowej {appName} (dalej: „Aplikacja”), której
          właścicielem jest {fullName}
          (dalej: „Usługodawca”).
        </p>
        <p>
          Regulamin jest dostępny nieodpłatnie w formie elektronicznej na
          stronie internetowej oraz w samej Aplikacji, co umożliwia jego
          zapoznanie się przed rozpoczęciem korzystania z Aplikacji.
        </p>
        <p>
          Korzystanie z Aplikacji jest równoznaczne z akceptacją postanowień
          Regulaminu.
        </p>
      </div>

      <div className="document-section">
        <h2>§ 2 Definicje</h2>
        <p>
          <strong>Użytkownik</strong> – osoba fizyczna lub prawna korzystająca z
          Aplikacji.
        </p>
        <p>
          <strong>Usługodawca</strong> – podmiot zarządzający i udostępniający
          Aplikację.
        </p>
        <p>
          <strong>Aplikacja</strong> – oprogramowanie umożliwiające użytkownikom
          dodawanie i przechowywanie obrazów cyfrowych, w szczególności obrazów
          związanych z grami, np. kart i innych elementów.
        </p>
      </div>

      <div className="document-section">
        <h2>§ 3 Zakres i zasady korzystania z Aplikacji</h2>
        <p>
          Aplikacja umożliwia Użytkownikom przesyłanie obrazów cyfrowych
          elementów gier (np. kart) oraz ich przechowywanie na koncie w
          Aplikacji, aby umożliwić rozgrywkę z innymi Użytkownikami.
        </p>
        <p>
          Dodawanie i udostępnianie treści w Aplikacji jest dobrowolne i odbywa
          się na odpowiedzialność Użytkownika.
        </p>
        <p>
          Użytkownik zobowiązuje się do korzystania z Aplikacji w sposób zgodny
          z obowiązującym prawem, zasadami współżycia społecznego oraz
          niniejszym Regulaminem.
        </p>
      </div>

      <div className="document-section">
        <h2>§ 4 Odpowiedzialność Użytkownika za Treści</h2>
        <p>
          Użytkownik ponosi pełną odpowiedzialność za wszelkie treści, w tym
          obrazy, które zamieszcza w Aplikacji.
        </p>
        <p>
          Użytkownik oświadcza, że posiada wszelkie niezbędne prawa do
          publikowania i wykorzystywania treści w Aplikacji, w tym praw
          autorskich oraz praw własności intelektualnej.
        </p>
        <p>
          Usługodawca nie ponosi odpowiedzialności za naruszenie praw osób
          trzecich wynikające z treści zamieszczanych przez Użytkowników.
        </p>
        <p>
          W przypadku zgłoszenia przez osobę trzecią roszczeń z tytułu
          naruszenia jej praw, Użytkownik jest zobowiązany zwolnić Usługodawcę z
          odpowiedzialności oraz pokryć wszelkie szkody i koszty wynikające z
          takich roszczeń.
        </p>
      </div>

      <div className="document-section">
        <h2>§ 5 Odpowiedzialność Usługodawcy</h2>
        <p>
          Usługodawca dokłada starań, aby Aplikacja działała bez zakłóceń,
          jednak nie gwarantuje pełnej dostępności ani ciągłości działania
          Aplikacji.
        </p>
        <p>
          Usługodawca nie ponosi odpowiedzialności za ewentualne przerwy w
          funkcjonowaniu Aplikacji spowodowane przyczynami technicznymi,
          niezależnymi od Usługodawcy lub działaniami Użytkowników.
        </p>
        <p>
          Usługodawca zastrzega sobie prawo do usuwania treści naruszających
          Regulamin, obowiązujące przepisy prawa, dobre obyczaje lub prawa osób
          trzecich.
        </p>
      </div>

      <div className="document-section">
        <h2>§ 6 Rejestracja i Konto Użytkownika</h2>
        <p>
          Korzystanie z Aplikacji wymaga rejestracji i utworzenia konta przez
          Użytkownika.
        </p>
        <p>
          Użytkownik jest zobowiązany do podania prawdziwych danych podczas
          rejestracji oraz do ich aktualizacji w przypadku zmiany.
        </p>
        <p>
          Użytkownik zobowiązany jest do zachowania poufności danych logowania
          do swojego konta i nieudostępniania ich osobom trzecim.
        </p>
      </div>

      <div className="document-section">
        <h2>§ 7 Płatności</h2>
        <p>
          Korzystanie z niektórych funkcji Aplikacji może być płatne. W takim
          przypadku opłaty będą pobierane zgodnie z obowiązującym cennikiem
          dostępnym w Aplikacji.
        </p>
        <p>
          Wszelkie płatności dokonywane są za pośrednictwem systemu Przelewy24
          lub innej wybranej platformy płatności online.
        </p>
        <p>
          Usługodawca zastrzega sobie prawo do zmiany cennika oraz wprowadzenia
          nowych funkcji płatnych.
        </p>
      </div>

      <div className="document-section">
        <h2>§ 8 Prawa Autorskie i Własność Intelektualna</h2>
        <p>
          Aplikacja oraz wszystkie elementy graficzne, tekstowe, a także kod
          źródłowy są chronione prawami autorskimi i stanowią własność
          Usługodawcy lub podmiotów, z którymi Usługodawca zawarł stosowne
          umowy.
        </p>
        <p>
          Użytkownik zobowiązuje się do nienaruszania praw autorskich i praw
          własności intelektualnej związanych z Aplikacją.
        </p>
      </div>

      <div className="document-section">
        <h2>§ 9 Reklamacje</h2>
        <p>
          Wszelkie reklamacje dotyczące działania Aplikacji można zgłaszać drogą
          mailową na adres: {contactAdress}.
        </p>
        <p>
          Reklamacja powinna zawierać szczegółowy opis problemu oraz dane
          kontaktowe Użytkownika.
        </p>
        <p>
          Usługodawca zobowiązuje się do rozpatrzenia reklamacji w terminie 14
          dni roboczych od jej otrzymania.
        </p>
      </div>

      <div className="document-section">
        <h2>§ 10 Zmiany Regulaminu</h2>
        <p>
          Usługodawca zastrzega sobie prawo do dokonywania zmian w Regulaminie w
          dowolnym czasie, w szczególności w przypadku zmiany przepisów prawa
          lub zmian w funkcjonalnościach Aplikacji.
        </p>
        <p>
          O każdej zmianie Regulaminu Użytkownicy zostaną poinformowani za
          pośrednictwem komunikatu w Aplikacji lub drogą elektroniczną.
        </p>
        <p>
          Zmieniony Regulamin wchodzi w życie w terminie wskazanym przez
          Usługodawcę, nie krótszym niż 7 dni od dnia jego udostępnienia.
        </p>
      </div>

      <div className="document-section">
        <h2>§ 11 Postanowienia końcowe</h2>
        <p>
          W sprawach nieuregulowanych niniejszym Regulaminem mają zastosowanie
          przepisy prawa polskiego.
        </p>
        <p>
          Ewentualne spory wynikające z korzystania z Aplikacji będą
          rozstrzygane przez sąd właściwy dla siedziby Usługodawcy.
        </p>
      </div>

      <div className="document-section">
        <h2>§ 12 Odstąpienie od umowy</h2>
        <p>
          Zgodnie z ustawą o prawach konsumenta, Użytkownikowi przysługuje prawo
          do odstąpienia od umowy w terminie 14 dni kalendarzowych, chyba że:
        </p>
        <ul>
          <li>
            Produkt cyfrowy został dostarczony niezwłocznie po dokonaniu
            płatności, a Użytkownik wyraził zgodę na rozpoczęcie świadczenia
            usługi przed upływem terminu do odstąpienia od umowy.
          </li>
          <li>
            Użytkownik, przystępując do zakupu, wyraża zgodę na natychmiastowe
            rozpoczęcie świadczenia usługi, co skutkuje utratą prawa do
            odstąpienia od umowy po rozpoczęciu pobierania lub korzystania z
            produktu cyfrowego.
          </li>
        </ul>
        <p>Niniejszy Regulamin wchodzi w życie z dniem {date}.</p>
      </div>
    </Box>
  ),
};

export const TermsPage = () => {
  const { i18n } = useTranslation();
  return (
    <Box
      className="page"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      {components[i18n.language || "en"]}
    </Box>
  );
};
