import { GameDice } from "../../model/Game";
import { useGame } from "../character/hooks/useGame";
import { DiceView } from "./DiceView";

interface DicePickerProps {
  onClick: (token: GameDice) => void;
}

export const DicePicker = (props: DicePickerProps) => {
  const { game } = useGame();

  const options = game.root.settings?.dices?.map((dice) => (
    <DiceView onClick={() => props.onClick(dice)} item={dice} fixed />
  ));
  return <div className="token-picker">{options}</div>;
};



