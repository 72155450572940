import { useTranslation } from "react-i18next";
import {
  Tooltip,
  IconButton,
  Box,
  Button,
  Modal,
  Typography,
  TextField,
  CircularProgress,
} from "@mui/material";
import { ModalProps } from "../../../shared/components/modal/ModalProps";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { cuni } from "../../../shared/services/cuni/cuni";
import {
  CardFlyweight,
  CharacterCardPanel,
  GameGrantPermission,
} from "../../../model/Game";
import { uuid } from "../../../shared/utils/uuid";
import { useUser } from "../../auth/useUser";
import { useMaps } from "../../map-hex/components/MapProvider";
import { useCards, useDecks } from "../../chat/hooks/useCards";
import { CardView } from "../CardView";
import { ReactComponent as CardInsertIcon } from "../../../assets/icons/card_insert.svg";
import { useGranPermission } from "./useGranPermission";
import { updatePanel, useGamePanels } from "../../tokens/hooks/useGameDbPanels";
import { Conditional } from "../../../shared/components/logic/Conditional";
import { findPath } from "../../../shared/utils/jsonUtils";

export const CardInsert = (props: { deckId: string }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const close = useCallback(() => setOpen(false), []);
  return (
    <>
      <CardLookThroughModal {...props} open={open} handleClose={close} />
      <Tooltip title={t("CARD.INSERT")}>
        <IconButton onClick={() => setOpen(true)}>
          <CardInsertIcon style={{ width: 24, height: 24 }} />
        </IconButton>
      </Tooltip>
    </>
  );
};

type CardLookThroughModalProps = ModalProps & {
  deckId: string;
};

interface FormProps {
  index: number;
}

export const CardLookThroughModal = (props: CardLookThroughModalProps) => {
  const { open, handleClose: close, deckId } = props;

  const { t } = useTranslation();
  const { register, handleSubmit, reset, getValues } = useForm<FormProps>({
    defaultValues: {
      index: 0,
    },
  });

  useEffect(() => {
    reset({
      index: 0,
    });
  }, [reset]);

  const user = useUser();
  const { accepted, askForPermission, handleClose, loading } =
    useGranPermission({ close });

  const decks = useDecks();
  const onSubmit = (form: FormProps) => {
    if (!user) return;
    let message = "";
    let deckPlace = current?.deckPlaces.find((x) => x.key === props.deckId);
    if (!deckPlace) return;
    if (deckPlace.subType === "discardPile") {
      const originalDeckPlace = current?.deckPlaces.find(
        (x) => x.key === deckPlace!.subTypeDeckInstanceKey,
      );
      message = t("CARD.DISCARDED");
      deckPlace = originalDeckPlace;
    }
    const deck = decks.find((x) => x.key === deckPlace?.deckKey);
    message = `${deck?.name}${message ? ` (${message})` : ""}`;

    const gamePermission: GameGrantPermission = {
      // TODO: remember key of submit!
      key: uuid(),
      creatorId: user.id,
      question: t("CARD.INSERT_QUESTION", {
        user: user.userName,
        deck: message,
      }),
      answers: [],
    };
    askForPermission(gamePermission);
  };

  const panels = useGamePanels(user?.id);
  const cards = useCards();
  const cardPanel = panels.find((x) => x.panel.__type === "card");

  const { current, all } = useMaps();
  const currentPlace = current?.deckPlaces.find((x) => x.key === deckId);
  const insertCardAt = (card: CardFlyweight) => {
    if (!current || !currentPlace || !cardPanel) return;

    const index = getValues("index");

    currentPlace.cards.splice(
      currentPlace.cards.length - index,
      0,
      card.cardKey,
    );
    const path = findPath(all, currentPlace.cards);
    cuni.updateGame(`maps.${path}`, currentPlace.cards);

    updatePanel(cardPanel.panel.key, (state: CharacterCardPanel) => {
      state.cards = state.cards.filter((x) => x.key !== card.key);
      return state;
    });
    handleClose();
  };

  const component = loading ? (
    <Box display="flex" justifyContent="center" padding="1rem">
      <CircularProgress />
    </Box>
  ) : accepted && cardPanel?.panel.cards ? (
    <>
      <Typography id="modal-avatar-description" sx={{ mt: 2 }}>
        {t("CARD.TAKE_DESC_ACCEPTED")}
      </Typography>
      <Box display="flex" gap="1rem" flexWrap="wrap">
        {(cardPanel.panel.cards as CardFlyweight[]).map((x: CardFlyweight) => {
          const data = cards.find((card) => card.key === x.cardKey);
          if (!data) return <></>;
          return (
            <CardView
              {...data}
              key={x.cardKey}
              onClick={() => {
                insertCardAt(x);
              }}
            />
          );
        })}
      </Box>
    </>
  ) : (
    <>
      <Typography id="modal-avatar-description" sx={{ mt: 2 }}>
        {t("CARD.INSERT_DESC")}
      </Typography>

      <TextField
        multiline
        type="number"
        label={t("COMMON.START")}
        {...register("index")}
      />
    </>
  );

  return (
    <Modal open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box className="modal">
          <Typography variant="h6" component="h2">
            {t("CARD.INSERT")}
          </Typography>
          {component}
          <Conditional condition={!accepted && !loading}>
            <Button type="submit" variant="contained">
              {t("COMMON.SEND")}
            </Button>
          </Conditional>
          <Button onClick={handleClose}>{t("COMMON.CLOSE")}</Button>
        </Box>
      </form>
    </Modal>
  );
};
