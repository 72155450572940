import CasinoIcon from "@mui/icons-material/Casino";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import { Box, IconButton, TextField } from "@mui/material";
import React, { useRef, useState } from "react";
import { WebHookSubType } from "../../model/WebHookMessage";
import { ChatActions } from "./components/ChatActions";
import { FormProvider, useForm } from "react-hook-form";
import { ActionFormValues } from "./components/ActionForm";
import { useGame } from "../character/hooks/useGame";
import { Conditional } from "../../shared/components/logic/Conditional";
import { canRoll } from "../../shared/services/cuni/utils";

export interface IChatInput {
  sendMessage: (
    message: string,
    subType: WebHookSubType,
    actionValues: ActionFormValues,
  ) => void;
}
const ChatInput: React.FC<IChatInput> = (props) => {
  const [message, setMessage] = useState("");
  const ref = useRef<WebHookSubType>("CHARACTER_CHAT");

  const { game } = useGame();

  const form = useForm<ActionFormValues>();

  const onSubmit = (e: any) => {
    e.preventDefault();

    const isMessageProvided = message && message !== "";

    if (isMessageProvided) {
      if (canRoll(message)) {
        props.sendMessage(message, "ACTION_ROLL", form.getValues());
      } else {
        if (ref.current === "ACTION_ROLL")
          props.sendMessage(message, "CHARACTER_CHAT", form.getValues());
        else props.sendMessage(message, ref.current, form.getValues());
      }
      setMessage("");
    } else {
      alert("Please insert an user and a message.");
    }
  };

  const onMessageUpdate = (e: any) => {
    setMessage(e.target.value);
  };

  return (
    <FormProvider {...form}>
      <div className="paper chat__input--container">
        <ChatActions />
        <form className="chat__input--form" onSubmit={onSubmit}>
          <br />
          <TextField
            id="message"
            multiline
            value={message}
            onChange={onMessageUpdate}
            style={{ width: "100%" }}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                onSubmit(e);
              }
            }}
            InputProps={{
              endAdornment: (
                <Box display="flex">
                  <Conditional condition={game.root?.showUserChat}>
                    <IconButton
                      type="submit"
                      onClick={() => {
                        ref.current = "USER_CHAT";
                      }}
                    >
                      <PeopleOutlineIcon />
                    </IconButton>
                  </Conditional>

                  <IconButton
                    type="submit"
                    onClick={() => {
                      ref.current = "CHARACTER_CHAT";
                    }}
                  >
                    <PeopleAltIcon />
                  </IconButton>
                  <IconButton
                    type="submit"
                    onClick={() => {
                      ref.current = "ACTION_ROLL";
                    }}
                  >
                    <CasinoIcon />
                  </IconButton>
                </Box>
              ),
            }}
          />
        </form>
      </div>
    </FormProvider>
  );
};

export default ChatInput;
