import { useMutation, useQuery } from "@tanstack/react-query";
import { UserTextMessage } from "../../../../model/WebHookMessage";
import { apiClient } from "../../../../shared/services/apiClient";
import { ActionSurvey, Survey, SurveyAnswer } from "../../../../model/Survey";
import { config } from "../../../../config";
import { Box, Button, LinearProgress, Typography } from "@mui/material";
import { useAuth } from "../../../auth/useAuth";
import { cuni } from "../../../../shared/services/cuni/cuni";
import React, { useEffect, useState } from "react";
import { useFriends } from "../../../invitation/FriendList";

interface SurveyMessageViewProps extends UserTextMessage {}
export const SurveyMessageView = (props: SurveyMessageViewProps) => {
  const { data } = useSurvey(props.contextObjectId!);
  const { mutate } = useSurveyAnswer();
  const auth = useAuth();
  const friends = useFriends();
  const [revealed, setRevealed] = useState(false);

  if (!data) return <></>;

  const handleAnswer = (action: ActionSurvey) => {
    if (!action.actionId || !props.contextObjectId)
      throw new Error("Missing mutate data!");
    mutate(
      {
        actionId: action.actionId,
        surveyId: props.contextObjectId,
      },
      {
        onSuccess: () => {
          if (!props.id) throw new Error("Message should have already Id");
          cuni.refreshMessage(props.id);
        },
      },
    );
  };

  const renderAnswers = (answer?: SurveyAnswer) => {
    return data.actions?.map((x) => (
      <Button
        key={x.actionId}
        disabled={Boolean(answer)}
        style={
          answer?.actionId === x.actionId
            ? {
                border: "1px solid white",
                color: "white",
              }
            : undefined
        }
        onClick={() => handleAnswer(x)}
      >
        {x.action.name}
      </Button>
    ));
  };

  const onReveal = () => {
    setRevealed(true);
    setTimeout(() => {
      if (!data.actions) return;
      const randomIdx = Math.floor(Math.random() * data.actions.length);
      handleAnswer(data.actions[randomIdx]);
    }, (data.timeout || 5) * 1000);
  };

  const answers = data.answers ?? [];
  let component: React.ReactNode = <></>;
  if (data.creatorId === auth.data?.userId) {
    component = answers
      .filter((x) => x.creatorId !== auth.data?.userId)
      .map((answer) => (
        <Box
          key={answer.id}
          padding="0.25rem"
          display="flex"
          justifyContent="center"
          alignItems="center"
          gap="1rem"
        >
          <Typography>
            {friends.data.find((x) => x.id === answer.creatorId)?.userName}
          </Typography>
          {renderAnswers(answer)}
        </Box>
      ));
  } else {
    const answer = answers.find((x) => x.creatorId === auth.data?.userId);
    if (data.quickAction && !revealed && !answer) {
      component = (
        <Button
          variant="outlined"
          onClick={onReveal}
        >{`Reveal - ${data.timeout} seconds`}</Button>
      );
    } else component = renderAnswers(answer);
  }

  return (
    <div>
      <div>{data.description}</div>
      <div>{component}</div>
      {revealed && <Progress timer={data.timeout} />}
    </div>
  );
};

const Progress = ({ timer = 5 }: { timer?: number }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const stepInSeconds = (timer * 1000) / 10;
    const interval = setInterval(() => {
      setProgress((y) => Math.min(10 + y, 100));
    }, stepInSeconds);
    setTimeout(function () {
      clearInterval(interval);
    }, (timer + 1) * 1000);
    return () => clearInterval(interval);
  }, [timer]);

  return <LinearProgress variant="determinate" value={progress} />;
};

export const useSurvey = (surveyId: number) => {
  return useQuery({
    queryKey: ["survey" + surveyId],
    queryFn: () =>
      apiClient
        .get<Survey>(
          `${config.baseUrl}/api/survey/${surveyId}?expand=Actions.Action&expand=Answers`,
        )
        .then((res) => res.data),
  });
};

export const useSurveyAnswer = () => {
  return useMutation({
    mutationFn: (data: SurveyAnswer) =>
      apiClient.post<SurveyAnswer>(`${config.baseUrl}/api/answer`, data),
  });
};
