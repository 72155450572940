import BookmarkRemoveIcon from "@mui/icons-material/BookmarkRemove";
import BookmarkAddIcon from "@mui/icons-material/BookmarkAdd";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
// import CodeIcon from "@mui/icons-material/Code";
import { useState } from "react";
import { AreYouSureModal } from "../../../../shared/components/modal/AreYouSureModal";
import { Divider, IconButton, Tooltip } from "@mui/material";
import { useGame } from "../../../character/hooks/useGame";
import { useDeleteCharacter } from "../../hooks/useDeleteCharacter";
import { rootStore } from "../../../../store/RootStore";
import { cuni } from "../../../../shared/services/cuni/cuni";
import { useCharacterTabs } from "../../../character/components/generic/CharacterTabProvider";
import { Conditional } from "../../../../shared/components/logic/Conditional";
import { useQuickEdit } from "../../../quick-edit/hooks/useQuickEdit";
import { useGameEdit } from "../../hooks/useGameEdit";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import UploadIcon from "@mui/icons-material/Upload";
import { NOT_EDITABLE_CHARACTER_KEYS } from "../../../quick-edit/QuickEditModal";
import BoltIcon from "@mui/icons-material/Bolt";
import { file } from "../../../../shared/utils/file";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import { useTranslation } from "react-i18next";
import { uuid } from "../../../../shared/utils/uuid";
import { usePlanPermissions } from "../../../../shared/hooks/usePlanPermissions";

export const EXPORTABLE_COLUMNS = [...NOT_EDITABLE_CHARACTER_KEYS, "name"];

export type ChacterEditActionView = "character" | "interactions";

interface GameCharacterEditActionsProps {
  view: ChacterEditActionView;
  setView: (input: ChacterEditActionView) => void;
}

export const GameCharacterEditActions = (
  props: GameCharacterEditActionsProps,
) => {
  const { view, setView } = props;
  const { game } = useGame();
  const { tab, tabId, character } = useCharacterTabs();
  const [modal, setModal] = useState("");
  const { hasPermission } = usePlanPermissions();

  const { t } = useTranslation();

  const { generic } = useGameEdit();

  const { edit } = useQuickEdit();

  const { mutate } = useDeleteCharacter(game);

  const exportChararacter = () => {
    const object = Object.entries(character)
      .filter(([key, _]: [string, any]) => !EXPORTABLE_COLUMNS.includes(key))
      .reduce((prev: any, [key, value]: [string, any]) => {
        prev[key] = value;
        return prev;
      }, {});

    file.export({ object, name: character.name });
  };

  const importCharacter = () => {
    document.getElementById("characterFileInput")?.click();
  };

  const importCharacterFromFile = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const result = await file.import(event);
    Object.entries(result)
      .filter(([key, _]: [string, any]) => !EXPORTABLE_COLUMNS.includes(key))
      .forEach(([key, value]: [string, any]) => {
        cuni.update(character.key, key, value);
      });
  };

  const exportTab = () => {
    const object = JSON.parse(JSON.stringify(tab)) as typeof tab;
    file.export({ object, name: tab.title || "empty" });
  };

  const importTab = () => {
    document.getElementById("tabFileInput")?.click();
  };

  const importTabFromFile = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const result = await file.import(event);
    const tabs = character.__tabs ?? [];
    tabs.push(result);
    cuni.update(character.key, "__tabs", tabs);
  };

  const addTab = () => {
    const tabs = character.__tabs ?? [];
    tabs.push({
      key: uuid(),
      title: "NEW_TAB",
      __panels: [],
      __type: "tab",
      visible: true,
    });
    cuni.update(character.key, "__tabs", tabs);
  };

  const removeTab = () => {
    const tabs = character.__tabs ?? [];
    tabs.splice(tabId - 1, 1);
    cuni.update(character.key, "__tabs", tabs);
  };
  const changeVisibility = () => {
    cuni.update(character.key, `__tabs.${tabId - 1}.visible`, !tab.visible);
  };

  const duplicateTab = () => {
    const tabs = character.__tabs ?? [];
    const deepCopy = JSON.parse(JSON.stringify(tab)) as typeof tab;
    deepCopy.title += "(COPY)";
    tabs.push(deepCopy);
    cuni.update(character.key, "__tabs", tabs);
  };

  return (
    <div className="paper paper-0 flex">
      <Conditional condition={!generic}>
        <Tooltip title="Remove character">
          <IconButton
            color="error"
            onClick={() => setModal("REMOVE_CHARACTER")}
          >
            <PersonRemoveIcon />
          </IconButton>
        </Tooltip>
      </Conditional>
      <Tooltip
        title={
          hasPermission("canExportImport")
            ? t("COMMON.EXPORT")
            : t("INFO.UPGRADE")
        }
      >
        <IconButton
          disabled={!hasPermission("canExportImport")}
          onClick={exportChararacter}
        >
          <FileDownloadIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={
          hasPermission("canExportImport")
            ? t("COMMON.REPLACE")
            : t("INFO.UPGRADE")
        }
      >
        <IconButton
          disabled={!hasPermission("canExportImport")}
          onClick={importCharacter}
        >
          <input
            type="file"
            id="characterFileInput"
            style={{ display: "none" }}
            accept="application/json"
            onChange={importCharacterFromFile}
          />
          <SyncAltIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={t("GAME.QUICK_EDIT")}>
        <IconButton
          onClick={() =>
            edit({
              path: "",
              value: "",
            })
          }
        >
          <AppRegistrationIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={t("INTERACTION.INTERACTIONS")}>
        <IconButton
          onClick={() =>
            view === "interactions"
              ? setView("character")
              : setView("interactions")
          }
          color={view === "interactions" ? "warning" : undefined}
        >
          <BoltIcon />
        </IconButton>
      </Tooltip>

      <Divider orientation="vertical" flexItem />

      <Tooltip title={t("GAME.ADD_TAB")}>
        <IconButton onClick={addTab}>
          <BookmarkAddIcon />
        </IconButton>
      </Tooltip>

      <Tooltip
        title={
          hasPermission("canExportImport")
            ? t("GAME.UPLOAD_TAB")
            : t("INFO.UPGRADE")
        }
      >
        <IconButton
          disabled={!hasPermission("canExportImport")}
          onClick={importTab}
        >
          <input
            type="file"
            id="tabFileInput"
            style={{ display: "none" }}
            accept="application/json"
            onChange={importTabFromFile}
          />
          <UploadIcon />
        </IconButton>
      </Tooltip>
      {/* <Conditional condition={!generic}>
        <Tooltip title="Edit as json">
          <IconButton onClick={() => setModal("JSON_EDIT")}>
            <CodeIcon />
          </IconButton>
        </Tooltip>
      </Conditional> */}
      <Conditional condition={tab.title !== "main"}>
        <>
          <Tooltip color="error" title={t("GAME.REMOVE_TAB")}>
            <IconButton onClick={() => setModal("REMOVE_TAB")}>
              <BookmarkRemoveIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("GAME.CHANGE_VISIBILITY")}>
            <IconButton onClick={changeVisibility}>
              <Conditional
                condition={tab.visible}
                success={() => <VisibilityIcon />}
                failure={() => <VisibilityOffIcon />}
              />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("GAME.DUPLICATE_TAB")}>
            <IconButton onClick={duplicateTab}>
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>
          <Tooltip
            title={
              hasPermission("canExportImport")
                ? t("GAME.EXPORT_TAB")
                : t("GAME.EXPORT_TAB")
            }
          >
            <IconButton
              onClick={exportTab}
              disabled={!hasPermission("canExportImport")}
            >
              <FileDownloadIcon />
            </IconButton>
          </Tooltip>
        </>
      </Conditional>

      <AreYouSureModal
        title={t("GAME.DELETE_CHARACTER")}
        content={t("GAME.DELETE_CHARACTER_QUESTION", {
          name: character.name,
        })}
        handleClose={() => setModal("")}
        confirmText={t("COMMON.DELETE")}
        confirmAction={() => {
          mutate(character.key, {
            onSuccess: async () => {
              rootStore.init();
            },
          });
        }}
        open={modal === "REMOVE_CHARACTER"}
      />

      <AreYouSureModal
        title={t("GAME.DELETE_TAB")}
        content={t("GAME.DELETE_TAB_QUESTION")}
        handleClose={() => setModal("")}
        confirmText={t("COMMON.DELETE")}
        confirmAction={removeTab}
        open={modal === "REMOVE_TAB"}
      />
    </div>
  );
};
