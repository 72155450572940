import { Button } from "@mui/material";
import React, { useState } from "react";
import { Game } from "../../../model/Game";
import { AreYouSureModal } from "../../../shared/components/modal/AreYouSureModal";
import { useNavigate } from "react-router-dom";
import { useDeleteGame } from "../hooks/useDeleteGame";
import { useTranslation } from "react-i18next";

interface Props {
  game: Game;
}
export const DeleteGameButton = (props: Props) => {
  const { game } = props;
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { mutate } = useDeleteGame();

  return (
    <React.Fragment>
      <Button variant="outlined" color="error" onClick={() => setOpen(true)}>
        {t("COMMON.DELETE")}
      </Button>
      <AreYouSureModal
        title={t('GAME.DELETE_GAME')}
        content={`Are you sure you want to delete game ${game.name} ?`}
        handleClose={() => setOpen(false)}
        confirmText={t('COMMON.DELETE')}
        confirmAction={() => {
          mutate(game.id, {
            onSuccess: async () => {
              navigate("/list");
            },
          });
        }}
        open={open}
      />
    </React.Fragment>
  );
};
