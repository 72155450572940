import { CharacterTabProvider } from "../character/components/generic/CharacterTabProvider";
import { GameEditContextState, GameEditProvider } from "./hooks/useGameEdit";
import {
  GameTabEditViewInternal,
  GameTabEditViewInternalProps,
} from "./GameTabEditView";
import { useGame } from "../character/hooks/useGame";
import { Character, makeSections } from "../../model/Game";

export const GameTabSectionEditView = (
  props: GameEditContextState & GameTabEditViewInternalProps,
) => {
  const { style, ...rest } = props;
  const { game } = useGame();

  if (!game) return null;
  return (
    <GameEditProvider main={false} editEnabled generic {...rest}>
      <CharacterTabProvider
        character={makeSections(game.root?.sections!) as Character}
      >
        <GameTabEditViewInternal style={style} />
      </CharacterTabProvider>
    </GameEditProvider>
  );
};
