import { Button, Checkbox, FormControlLabel, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { Controller, useForm } from "react-hook-form";
import { cuni } from "../../../shared/services/cuni/cuni";
import { ModalProps } from "../../../shared/components/modal/ModalProps";
import { useGame } from "../../character/hooks/useGame";
import { uuid } from "../../../shared/utils/uuid";
import { MapItem } from "../../../model/Map";
import { useTranslation } from "react-i18next";

export const DEFAULT_MAP = {
  width: 0,
  height: 0,
  isSquare: false,
  active: false,
  id: uuid(),
  tokens: [],
  dices: [],
  images: [],
  grid: [],
  deckPlaces: [],
  elements: [],
};

type ActionModalProps = ModalProps;

interface AddModalForm {
  width: number;
  height: number;
  isSquare: boolean;
}

const createGrid = (width: number, height: number) => {
  const grid: MapItem[][] = [];
  for (let i = 0; i < width; i++) {
    const row: MapItem[] = [];
    for (let j = 0; j < height; j++) {
      row.push({ id: uuid() });
    }
    grid.push(row);
  }

  return grid;
};

export const AddMapModal = (props: ActionModalProps) => {
  const { open, handleClose } = props;

  const { t } = useTranslation();

  const { game } = useGame();
  const { register, handleSubmit, reset, control } = useForm<AddModalForm>({
    defaultValues: {
      width: 3,
      height: 3,
      isSquare: false,
    },
    // TODO: add resolver
    // resolver : async (data, context, options) => {
    //   return {
    //   }
    // }
  });

  const onClose = () => {
    reset();
    handleClose();
  };

  const onSubmit = (form: AddModalForm) => {
    const maps = game.root?.maps || [];

    cuni.array.push("maps", maps, maps, {
      ...DEFAULT_MAP,
      ...form,
      grid: createGrid(form.width, form.height),
    });
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box className="modal">
          <Typography variant="h6" component="h2">
            {t("MAP.MAP")}
          </Typography>
          <Typography id="modal-avatar-description" sx={{ mt: 2 }}>
            {t("MAP.DESC")}
          </Typography>

          <Controller
            control={control}
            name="isSquare"
            render={({ field }) => {
              return (
                <FormControlLabel
                  control={
                    <Checkbox checked={field.value} onChange={field.onChange} />
                  }
                  label={t("MAP.SQUARE")}
                />
              );
            }}
          />

          <Box display="flex" paddingY="1rem" gap="1rem">
            <TextField
              label={t("COMMON.HEIGHT")}
              type="number"
              {...register("width")}
            />
            <TextField
              label={t("COMMON.WIDTH")}
              type="number"
              {...register("height")}
            />
          </Box>

          <Button type="submit" variant="contained">
            {t("COMMON.CREATE")}
          </Button>
          <Button onClick={onClose}>{t("COMMON.CLOSE")}</Button>
        </Box>
      </form>
    </Modal>
  );
};
