import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { useCallback, useMemo } from "react";
import { ModalProps } from "../../../../shared/components/modal/ModalProps";
import { Table, TableProps } from "../../../../shared/components/ui/Table";
import { Interaction } from "../../../../model/Game";
import { useCharacterTabs } from "../../../character/components/generic/CharacterTabProvider";
import { cuni } from "../../../../shared/services/cuni/cuni";
import { useTranslation } from "react-i18next";

type InteractionEditModalProps = ModalProps & {
  interaction: Interaction | null;
  interactionIndex: number;
};

export const InteractionEditModal = (props: InteractionEditModalProps) => {
  const { open, handleClose, interaction, interactionIndex } = props;
  const { character } = useCharacterTabs();
  const updatePath = `__interactions.${interactionIndex}.effects`;

  const { t } = useTranslation();

  const { rows, headers } = useMemo(() => {
    const effects = interaction?.effects ?? [];
    const headers: TableProps["headers"] = [
      {
        key: "node.path",
        title: t("INTERACTION.NODE"),
        type: "HINTS",
      },
      {
        key: "operand",
        title: t("INTERACTION.OPERAND"),
        type: "INTERACTION_OPERAND",
      },
      {
        key: "value",
        title: t("COMMON.VALUE"),
      },
      {
        key: "condition",
        title: t("INTERACTION.CONDITION"),
        type: "INTERACTION_CONDITION",
      },
    ];
    return { rows: effects, headers };
  }, [interaction?.effects, t]);

  const addEffect = useCallback(() => {
    const effects = interaction?.effects ?? [];
    effects.push({
      node: {
        path: "__EMPTY__",
      },
      operand: "increase",
      value: 0,
      condition: "none",
    });
    cuni.update(character.key, updatePath, effects);
  }, [character.key, updatePath, interaction?.effects]);

  if (!interaction) return null;
  return (
    <Modal open={open} onClose={handleClose}>
      <Box className="modal">
        <Typography variant="h6" component="h2">
          {t("INTERACTION.EFFECTS")}
        </Typography>
        <Typography id="modal-avatar-description" sx={{ mt: 2 }}>
          {t("INTERACTION.EFFECT_DESC")}
        </Typography>
        <Button variant="outlined" onClick={addEffect}>
          {t("INTERACTION.ADD_EFFECT")}
        </Button>
        <Table
          titleSufix={updatePath}
          directList
          headers={headers}
          rows={rows}
        />
        <Button onClick={handleClose}> {t("COMMON.CLOSE")}</Button>
      </Box>
    </Modal>
  );
};
