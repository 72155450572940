import { TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useGame } from "../../character/hooks/useGame";
import { cuni } from "../../../shared/services/cuni/cuni";
import { useTranslation } from "react-i18next";

export const CharacterNames = () => {
  const [text, setText] = useState("");
  const { t } = useTranslation();

  const { game } = useGame();
  
  useEffect(() => {
    if (!game.root?.charactersNames) return;
    const newText = game.root.charactersNames.join(",");
    setText(newText);
  }, [game.root?.charactersNames]);

  const save = () => {
    cuni.updateGame("charactersNames", text.split(","));
  };

  return (
    <TextField
      label={t("GAME.CHARACTER_NAMES")}
      onChange={(e) => setText(e.target.value)}
      value={text}
      onBlur={save}
    />
  );
};
