import React from "react";
import { CharacterTablePanel } from "../../../../../../model/Game";
import { Table } from "../../../../../../shared/components/ui/Table";
import { PanelMenu, PanelMenuProps } from "../panel-menu/PanelMenu";

interface TablePanelViewProps extends Pick<PanelMenuProps, "panelOnMap"> {
  panel: CharacterTablePanel;
  path: string;
  className?: string;
  isOnMap?: boolean;
  style?: React.CSSProperties;
}

export const TablePanelView = (props: TablePanelViewProps) => {
  const { panel, path, className, isOnMap, panelOnMap, style } = props;
  const headers = panel.__columns || [];
  const rows = panel.__data || [];
  return (
    <PanelMenu
      actions={
        isOnMap
          ? ["REMOVE_FROM_MAP", "SHOW_OTHERS", "ADD_ROW", "EXPORT"]
          : ["REMOVE_PANEL", "EXPORT", "ADD_ROW", "ADD_TO_MAP"]
      }
      path={path}
      panelOnMap={panelOnMap}
    >
      <Table
        headers={headers}
        rows={rows}
        title={panel.title}
        titleSufix={path}
        className={className}
        style={style}
        actions={["remove"]}
      />
    </PanelMenu>
  );
};
