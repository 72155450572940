import { Interaction } from "../../../../model/Game";
import { useCallback, useMemo, useState } from "react";
import { useCharacterTabs } from "../../../character/components/generic/CharacterTabProvider";
import { Table } from "../../../../shared/components/ui/Table";
import { InteractionEditModal } from "./InteractionEditModal";
import { Button } from "@mui/material";
import { uuid } from "../../../../shared/utils/uuid";
import { cuni } from "../../../../shared/services/cuni/cuni";
import { useTranslation } from "react-i18next";

export const InteractionView = () => {
  const { character } = useCharacterTabs();
  const [selected, setInteraction] = useState<string>("");
  const handleClose = useCallback(() => setInteraction(""), []);
  const { t } = useTranslation();

  const addInteraction = useCallback(() => {
    const interactions = character.__interactions ?? [];
    interactions.push({
      key: uuid(),
      desc: "NEW_DESC",
      cost: "NEW_COST",
      effects: [],
    });
    cuni.update(character.key, "__interactions", interactions);
  }, [character]);

  const { rows, headers } = useMemo(() => {
    const interactions = character.__interactions ?? [];
    const headers = [
      {
        key: "desc",
        title: t("COMMON.DESC"),
      },
      {
        key: "cost",
        title: t("INTERACTION.COST"),
      },
      {
        key: "action",
        title: t('COMMON.ACTION'),
        cell: (value: Interaction) => (
          <Button
            onClick={() => {
              setInteraction(value.key);
            }}
          >
            {t("COMMON.OPEN")}
          </Button>
        ),
      },
    ];
    const rows = interactions.map((x) => ({
      ...x,
      action: "",
    }));
    return { rows, headers };
  }, [character, t]);

  const all = character.__interactions ?? [];
  const index = all.findIndex((x) => x.key === selected);

  return (
    <>
      <div className="paper paper-sm">
        <Button variant="outlined" onClick={addInteraction}>
          {t("INTERACTION.ADD")}
        </Button>
      </div>
      <Table
        titleSufix={"__interactions"}
        directList
        headers={headers}
        rows={rows}
        root={character}
      />
      <InteractionEditModal
        interaction={all[index]}
        interactionIndex={index}
        open={Boolean(selected)}
        handleClose={handleClose}
      />
    </>
  );
};
