import { Button, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { useCallback, useEffect, useState } from "react";
import { ModalProps } from "../../../../shared/components/modal/ModalProps";
import { cuni } from "../../../../shared/services/cuni/cuni";
import { useGame } from "../../hooks/useGame";
import { useTranslation } from "react-i18next";
type NoteModalProps = ModalProps;

export const NoteModal = (props: NoteModalProps) => {
  const { open, handleClose } = props;
  const { t } = useTranslation();

  const [text, setText] = useState("");
  const { game } = useGame();

  useEffect(() => {
    const note = game?.me?.__note;
    if (note) {
      setText(note);
    }
  }, [game]);

  const saveNote = useCallback(() => {
    if (game?.me.key) cuni.update(game?.me.key, "__note", text);
    handleClose();
  }, [game, handleClose, text]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box className="modal">
        <Typography variant="h6" component="h2">
          {t("NOTE.NOTE")}
        </Typography>
        <Typography id="modal-avatar-description" sx={{ mt: 2 }}>
          {t("NOTE.DESC")}
        </Typography>
        <TextField
          multiline
          minRows={5}
          maxRows={12}
          onChange={(e) => setText(e.target.value)}
          value={text}
        />
        <Button variant="contained" onClick={saveNote}>
          {t("COMMON.SAVE")}
        </Button>
        <Button onClick={handleClose}> {t("COMMON.CLOSE")}</Button>
      </Box>
    </Modal>
  );
};
