import "./Avatar.css";
import placeholder from "../../../../assets/images/egg.png";

export const AvatarImage = ({
  imgUrl,
  online,
  round,
}: {
  imgUrl: string;
  online?: boolean;
  round?: boolean;
}) => {
  return (
    <img
      alt="avatar"
      className="avatar__image"
      src={imgUrl || placeholder}
      style={{
        borderColor: round ? "chartreuse" : "lightgray",
        filter: online ? undefined : "grayscale()",
      }}
    />
  );
};
