type ClassDefinition = undefined | string | Record<string, boolean | undefined>;
export const clsx = (...args: ClassDefinition[]) => {
  let array: string[] = [];
  args.forEach((className) => {
    if(!className) return;
    if (typeof className === "string") array.push(className);
    else {
      const newClasses = Object.entries(className)
        .filter((x) => x[1])
        .map((x) => x[0]);
      array = [...array, ...newClasses];
    }
  });

  return array.join(" ");
};
