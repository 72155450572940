import { Button, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { ModalProps } from "./ModalProps";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface Field {
  name: string;
}

type AreYouSureModalProps = ModalProps & {
  confirmText?: string;
  cancelText?: string;
  title: string;
  content?: string;
  confirmAction: (fields?: Record<string, string>) => void;
  fields?: Field[];
};

export const AreYouSureModal = (props: AreYouSureModalProps) => {
  const {
    open,
    handleClose,
    confirmAction,
    cancelText,
    confirmText,
    title,
    content,
    fields = [],
  } = props;

  const { t } = useTranslation();

  const [value, setValue] = useState<Record<string, string>>({});

  const compFields = fields.map((x) => {
    const propertyName = x.name[0]
      ? `${x.name[0].toUpperCase()}${x.name.slice(1, x.name.length)}`
      : x.name;
    return (
      <TextField
        key={propertyName}
        label={t(`COMMON.${propertyName}`)}
        onChange={(e) =>
          setValue((prev) => ({ ...prev, [x.name]: e.target.value }))
        }
        value={value[x.name]}
      />
    );
  });

  const submit = () => {
    confirmAction(value);
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <form onSubmit={submit}>
        <Box className="modal">
          <Typography variant="h6" component="h2">
            {title}
          </Typography>
          {content && <Typography component="h2">{content}</Typography>}
          {compFields}
          <Button type="submit" variant="contained" onClick={submit}>
            {confirmText ?? t("COMMON.CONFIRM")}
          </Button>
          <Button onClick={handleClose}>
            {cancelText ?? t("COMMON.CANCEL")}
          </Button>
        </Box>
      </form>
    </Modal>
  );
};
