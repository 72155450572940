import axios from "axios";
import { useCallback, useContext, useMemo } from "react";
import { config } from "../../config";
import {
  AuthContext,
  AuthData,
  IdentityResult,
  RegisterData,
  RegisterDataInput,
} from "./AuthContext";
import { authStorage } from "../../shared/services/storage";
import {
  CLAIMS,
  UserPermission,
  UserPermissions,
  apiClient,
  parseJwt,
} from "../../shared/services/apiClient";

export const useAuth = () => {
  const { data, setData } = useContext(AuthContext);

  const updateToken = useCallback(
    (data: AuthData) => {
      if (data.needCodeValidaton) {
        // here data is simplified
        authStorage.set(data);
      } else {
        const parsedToken = parseJwt(data.token);
        const roles = parsedToken[CLAIMS.role];
        authStorage.set({
          ...data,
          userId: parseInt(parsedToken[CLAIMS.id]),
          email: parsedToken[CLAIMS.email],
          userName: parsedToken[CLAIMS.name],
          licensePlan: parsedToken[CLAIMS.licensePlan],
          licenseExpiredDate: parsedToken[CLAIMS.licenseExpiredDate],
          roles: roles ? (Array.isArray(roles) ? roles : [roles]) : [],
        });
      }

      const auth = authStorage.get<AuthData>();
      if (!auth) throw new Error("Auth should be already saved");
      setData(auth);
    },
    [setData],
  );

  const login = useCallback(
    async (username: string, password: string) => {
      const result = await axios.post<AuthData>(
        `${config.baseUrl}/api/authenticate/login`,
        { username, password },
      );

      updateToken(result.data);
      return result.data;
    },
    [updateToken],
  );

  const logout = useCallback(() => {
    authStorage.clear();
    setData(undefined);
  }, [setData]);

  const confirmCode = useCallback(
    async (code: string, email: string) => {
      const result = await apiClient.post<AuthData>(
        `${config.baseUrl}/api/authenticate/confirm-code`,
        {
          code,
          email,
        },
      );
      updateToken(result.data);
    },
    [updateToken],
  );

  const generateCode = useCallback(async (email: string) => {
    await apiClient.post(`${config.baseUrl}/api/authenticate/generate-code`, {
      email,
    });
    // here data is simplified
    authStorage.set({ email });
  }, []);

  const updatePassword = useCallback(async (password: string) => {
    return await apiClient.post<IdentityResult>(`${config.baseUrl}/api/authenticate/update-password`, {
      password,
    });
  }, []);

  const register = useCallback(
    async (input: RegisterDataInput) => {
      const result = await axios.post<RegisterData>(
        `${config.baseUrl}/api/authenticate/register`,
        input,
      );
      updateToken(result.data);
      return result.data;
    },
    [updateToken],
  );

  const hasPermission = useCallback(
    (permission: UserPermission) => {
      const roles = data?.roles ?? [];
      return roles.includes(UserPermissions[permission]);
    },
    [data?.roles],
  );

  return useMemo(() => {
    return {
      logout,
      token: data?.token,
      expiration: data?.expiration,
      login,
      register,
      hasPermission,
      confirmCode,
      generateCode,
      updatePassword,
      // this could be outdated if it's loaded here
      data: authStorage.get<AuthData>(),
    };
  }, [
    data?.expiration,
    data?.token,
    login,
    logout,
    register,
    hasPermission,
    confirmCode,
    generateCode,
    updatePassword,
  ]);
};
