import React from "react";
import "./Toast.css";
import { Toast } from "./ToastProvider";
import { clsx } from "../../utils/clsx";

interface ToastProps {
  toast: Toast;
  onClose: (id: number) => void;
}

const ToastComponent: React.FC<ToastProps> = ({ toast, onClose }) => {
  React.useEffect(() => {
    const timer = setTimeout(() => onClose(toast.id), 500);
    return () => clearTimeout(timer);
  }, [toast, onClose]);

  return (
    <div className={clsx("toast", `toast-${toast.type.toLowerCase()}`)}>
      {toast.message}
      <button onClick={() => onClose(toast.id)}>X</button>
    </div>
  );
};

export default ToastComponent;
