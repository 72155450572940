import * as React from "react";
import { Conditional } from "../../shared/components/logic/Conditional";
import { GenericPanelView } from "../character/components/generic/GenericPanelView";
import { GenericTemplateView } from "../character/components/generic/GenericTemplateView";
import { CharacterTabPanel, Character } from "../../model/Game";

interface SwitchPanelViewProps {
  tab: CharacterTabPanel;
  tabId: number;
  character: Character;
}

export const SwitchPanelView = (props: SwitchPanelViewProps) => {
  const { tab, tabId, character } = props;
  return (
    <Conditional
      condition={tab.title === "main"}
      success={() => (
        <React.Fragment>
          <GenericTemplateView template={character} path="" />
          <Conditional
            condition={character?.__panels}
            success={(panels) => (
              <GenericPanelView panels={panels} path="__panels" />
            )}
          />
        </React.Fragment>
      )}
      failure={() => (
        <Conditional
          condition={tab.__panels}
          success={(panels) => (
            <GenericPanelView
              panels={panels}
              path={`__tabs.${tabId - 1}.__panels`}
            />
          )}
        />
      )}
    />
  );
};
