import { useEffect } from "react";
import { Game } from "../../model/Game";
import { gameStorage } from "../services/storage";
import image from "../../assets/images/cuni-dark.webp";

interface LocalStorageChangeEvent extends Event {
  key: string;
  value: string | null;
}

export const calculateBackground = (url: string | undefined | null) => {
  if (url?.trim()) {
    return `url(${url})`;
  } else {
    return `url("${image}")`;
  }
};

export const calculateGameBackground = () => {
  const game = gameStorage.get<Game>();
  return calculateBackground(game?.url?.trim());
};

export const updateBodyBackground = (url: string | undefined) => {
  document.body.style.backgroundImage = calculateBackground(url);
};

const updateGameBodyBackground = () => {
  const game = gameStorage.get<Game>();
  updateBodyBackground(game?.url?.trim());
};

export const useBackgroundImage = () => {
  useEffect(() => {
    updateGameBodyBackground();
    const listener = (evt: any) => {
      const event = evt as LocalStorageChangeEvent;
      if (event.key === "cuni__game") {
        updateGameBodyBackground();
      }
    };
    document.addEventListener("localStorageChange", listener);
    return () => {
      document.removeEventListener("localStorageChange", listener);
    };
  }, []);
};
