import {
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { Box, IconButton, Popover } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useMaps } from "./MapProvider";
import { Conditional } from "../../../shared/components/logic/Conditional";
import { ColorPicker } from "../../../shared/components/ui/color-picker/ColorPicker";
import { TokenPicker } from "../../../shared/components/ui/token-picker/TokenPicker";

// Navigation
interface HexActionState {
  open: (target: HTMLDivElement, id: string) => void;
}

const HexActionContext = createContext<HexActionState>({ open: () => {} });

export const HexActionProvider = (props: PropsWithChildren) => {
  const [selected, setSelected] = useState<string>();
  const anchorEl = useRef<HTMLDivElement>();

  const { current, updateHex } = useMaps();

  const value = useMemo(() => {
    return {
      open: (target: HTMLDivElement, id: string) => {
        anchorEl.current = target;
        setSelected(id);
      },
    };
  }, []);

  const currentHex = current?.grid
    .flatMap((x) => x)
    .find((x) => x.id === selected);

  return (
    <HexActionContext.Provider value={value}>
      <Popover
        anchorEl={anchorEl.current}
        open={Boolean(selected)}
        onClose={() => {
          setSelected("");
        }}
      >
        <Box className="paper" padding="0.25rem">
          <IconButton
            onClick={() => {
              if (!selected) throw new Error("There need to be hex selected");
              updateHex(selected, { exist: !currentHex?.exist });
            }}
          >
            <Conditional
              condition={currentHex?.exist}
              success={() => <VisibilityIcon />}
              failure={() => <VisibilityOffIcon />}
            />
          </IconButton>
          <ColorPicker
            anchorEl={anchorEl.current}
            onColorChange={(color: string | undefined) => {
              if (!selected) throw new Error("There need to be hex selected");
              updateHex(selected, { color });
            }}
          />
          <TokenPicker
            anchorEl={anchorEl.current}
            onTokenChange={(token: string) => {
              if (!selected) throw new Error("There need to be hex selected");
              updateHex(selected, {
                tokens: [...(currentHex?.tokens || []), token],
              });
            }}
          />
        </Box>
      </Popover>
      {props.children}
    </HexActionContext.Provider>
  );
};

export const useHexActions = () => useContext(HexActionContext);
