import { PropsWithChildren } from "react";
import "./RowMenu.css";
import { Tooltip, IconButton } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { cuni } from "../../../services/cuni/cuni";
import { useQuickEdit } from "../../../../features/quick-edit/hooks/useQuickEdit";
import { useGamePermission } from "../../../hooks/useGamePermission";
import { Conditional } from "../../logic/Conditional";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { clsx } from "../../../utils/clsx";

export type RowMenuAction = "remove" | "select";
export interface RowMenuProps {
  tablePath: string;
  rowId: number;
  style?: React.CSSProperties;
  onRowRemove?: () => void;
  selected?: boolean;
  onRowSelect?: () => void;
  actions?: RowMenuAction[];
}
export const RowMenu = (props: PropsWithChildren<RowMenuProps>) => {
  const {
    tablePath,
    rowId,
    children,
    style,
    onRowRemove,
    selected,
    onRowSelect,
    actions = ["remove", "select"],
  } = props;

  const { character } = useQuickEdit();
  const { hasGamePermission } = useGamePermission();

  const onClick = () => {
    if (onRowRemove) {
      onRowRemove();
      return;
    }
    const tableList = tablePath
      .split(".")
      .filter(Boolean)
      .reduce((prev, itemPath) => prev[itemPath], character as any);

    if (Array.isArray(tableList)) {
      tableList.splice(rowId, 1);
      cuni.update(
        character.key,
        // remove last .
        tablePath.split(".").filter(Boolean).join("."),
        tableList,
      );
    }
  };
  return (
    <div
      className={clsx("table__row", {
        "table__row-selected": selected,
      })}
      style={style}
    >
      <Conditional condition={hasGamePermission("canEditGame")}>
        <div className="table__row__menu">
          <Conditional condition={actions.includes("select")}>
            <Tooltip title="Select row">
              <IconButton
                size="small"
                onClick={onRowSelect}
                sx={{ padding: 0, maxHeight: 24 }}
              >
                {selected ? <CheckCircleIcon /> : <RadioButtonUncheckedIcon />}
              </IconButton>
            </Tooltip>
          </Conditional>
          <Conditional condition={actions.includes("remove")}>
            <Tooltip title="Remove row">
              <IconButton
                size="small"
                color="error"
                onClick={onClick}
                sx={{ padding: 0, maxHeight: 24 }}
              >
                <CancelIcon />
              </IconButton>
            </Tooltip>
          </Conditional>
        </div>
      </Conditional>

      {children}
    </div>
  );
};
