import DrawerPanel from "../../shared/components/ui/drawer/DrawerPanel";
import { useGame } from "../character/hooks/useGame";

export interface UserToolboxProps {
  renderers: (() => React.ReactNode)[];
}
export const UserToolbox = (props: UserToolboxProps) => {
  const { renderers } = props;
  const { game } = useGame();

  if (!game) return null;
  return (
    <>
      {renderers.map((render) => (
        <DrawerPanel anchor="left" width="800px">
          <div className="paper drawer">{render()}</div>
        </DrawerPanel>
      ))}
    </>
  );
};
