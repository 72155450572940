import { Button, TextField } from "@mui/material";
import "./SurveyModal.css";
import BoltIcon from "@mui/icons-material/Bolt";
import React from "react";
import "./ActionsController.css";
import { Action } from "../../../../model/Survey";
import { useTranslation } from "react-i18next";

interface ActionsControllerProps {
  value: Action[];
  onChange: (actions: Action[]) => void;
}

export const ActionsController = (props: ActionsControllerProps) => {
  const { value, onChange } = props;
  const { t } = useTranslation();

  const actions = [...value, { key: "new" }];
  return (
    <div className="actionsController">
      {/* {value.map((x, idx) => (
        <div key={idx}>A</div>
      ))} */}
      <div className="actionsController_container">
        {actions.map((action, idx) => (
          <React.Fragment key={idx}>
            {"key" in action ? (
              <div className="actionsController_action">
                <Button
                  onClick={() => {
                    onChange([...value, { name: "" }]);
                  }}
                  startIcon={<BoltIcon />}
                >
                  {t("SURVEY.ADD_ACTION")}
                </Button>
              </div>
            ) : (
              <TextField
                multiline
                minRows={5}
                maxRows={5}
                value={action.name}
                onChange={(e) => {
                  // value[idx] =
                  action.name = e.target.value;
                  onChange(value);
                }}
                className="actionsController_textField"
                label={t("SURVEY.ACTION")}
              />
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};
