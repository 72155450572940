import React from "react";
import { useMaps } from "./MapProvider";

export const MapImagesView = () => {
  const { current } = useMaps();

  const imagesViews = current?.images?.map((image) => {
    const style: React.CSSProperties = {
      position: "absolute",
      left: `${image.position?.x}px`,
      top: `${image.position?.y}px`,
      pointerEvents: "none",
      backgroundImage: `url(${image.imageUrl})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
      ...image.position,
    };
    return <div className="map-image" style={style} />;
  });
  return <>{imagesViews}</>;
};
