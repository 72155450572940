import { PropsWithChildren } from "react";
import { Button } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { useUser } from "../../../../features/auth/useUser";
import { Conditional } from "../../logic/Conditional";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface GameInfoProps {
  full?: boolean;
}

export const GameInfo = (props: PropsWithChildren<GameInfoProps>) => {
  const { children, full = true } = props;
  const { t } = useTranslation();
  const user = useUser();
  const navigate = useNavigate();
  return (
    <div
      className="paper game-info"
      style={{ flexBasis: full ? "100%" : undefined }}
    >
      <div className="game-info-inner">
        <InfoIcon />
        {t("INFO.FREE_LICENSE", { plan: user?.licensePlan })}
        <br />
        {children}
      </div>
      <Conditional condition={user?.licensePlan === "Free"}>
        <Button
          variant="contained"
          // LinkComponent={Link}
          // href="https://buycoffee.to/hubertnosek"
          onClick={() => navigate("/payments")}
        >
          {t("COMMON.UPGRADE")}
        </Button>
      </Conditional>
    </div>
  );
};
