import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGame } from "../../character/hooks/useGame";
import { ModalProps } from "../../../shared/components/modal/ModalProps";
import SelectField from "../../../shared/components/logic/SelectField";
import {
  Character,
  SETTINGS_ADMIN_KEY,
  CharacterTabPanel,
} from "../../../model/Game";
import { Conditional } from "../../../shared/components/logic/Conditional";
import { cuni } from "../../../shared/services/cuni/cuni";
import { isUUID, uuid } from "../../../shared/utils/uuid";
import { useGameDbPanels } from "../hooks/useGameDbPanels";
import { useGameDb } from "../hooks/useGameDb";

type TokenConnectModalProps = ModalProps & {
  tokenId: string;
};

export const TokenConnectModal = (props: TokenConnectModalProps) => {
  const { open, handleClose, tokenId } = props;
  const { t } = useTranslation();

  const { game } = useGame();
  const { list: databases } = useGameDb();
  const [database, setDatabase] = useState<Character>();
  const [panel, setPanel] = useState<CharacterTabPanel>();

  const saveTokenConnect = useCallback(() => {
    const tokens = game.root.settings?.tokens ?? [];
    const token = tokens.find((x) => x.key === tokenId);
    if (token && database && panel) {
      if (panel.__path === undefined) return;
      // this function will HEAL panel to add KEY
      // save key of panel if not added on other stage
      let panelKey = panel.key;
      if (!isUUID(panelKey) && typeof panelKey !== "number") {
        panelKey = uuid();
        panel.key = panelKey;
        cuni.update(database.key, panel.__path, panel);
      }
      token.connections.push({
        dbKey: database.key,
        panelKey: panelKey,
      });
      cuni.update(SETTINGS_ADMIN_KEY, "tokens", tokens);
    }
    handleClose();
  }, [handleClose, database, panel, tokenId, game.root.settings?.tokens]);

  // make hook from it
  const paths = useGameDbPanels(database);
  return (
    <Modal open={open} onClose={handleClose}>
      <Box className="modal">
        {/* <Typography variant="h6" component="h2">
          {t("NOTE.NOTE")}
        </Typography> */}

        {/* <Typography id="modal-avatar-description" sx={{ mt: 2 }}>
          {t("NOTE.DESC")}
        </Typography> */}
        <SelectField<Character>
          label={t("COMMON.DATABASE")}
          value={database}
          onChange={(e) => {
            setPanel(undefined);
            setDatabase(e);
          }}
          options={databases}
        />
        <Conditional condition={database}>
          <SelectField<CharacterTabPanel>
            label={t("COMMON.PANEL")}
            value={panel}
            onChange={setPanel}
            options={paths}
          />
        </Conditional>
        <Button variant="contained" onClick={saveTokenConnect}>
          {t("COMMON.SAVE")}
        </Button>
        <Button onClick={handleClose}> {t("COMMON.CLOSE")}</Button>
      </Box>
    </Modal>
  );
};
