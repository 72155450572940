import { Box, Button, Divider, Typography } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { config } from "../../config";
import { InvitationState } from "../../model/Invitation";
import { Invitation } from "../../model/Invitation";
import { apiClient } from "../../shared/services/apiClient";
import { useCallback } from "react";
import { useUser } from "../auth/useUser";
import { Trans, useTranslation } from "react-i18next";

export const InvitationList = () => {
  const user = useUser();
  const { data, isLoading } = useGetInvitation();
  const { mutate } = usePutInvitation();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const handleUpdate = useCallback(
    (invitation: Invitation, change: InvitationState) => {
      invitation.state = invitation.state | change;
      mutate(invitation, {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: ["invitation"],
            exact: true,
          });
          queryClient.invalidateQueries({
            queryKey: ["friend"],
            exact: true,
          });
        },
        onError: () => {
          queryClient.invalidateQueries({
            queryKey: ["invitation"],
            exact: true,
          });
          queryClient.invalidateQueries({
            queryKey: ["friend"],
            exact: true,
          });
        },
      });
    },
    [mutate, queryClient],
  );

  if (isLoading || !data || !data.length) return null;

  const sentInvitations = data
    .filter((x) => x.creatorId === user?.id)
    .map((invitation) => (
      <Typography key={invitation.id}>{invitation.email}</Typography>
    ));

  const invitations = data
    .filter((x) => x.receiverId === user?.id || x.email === user?.email)
    .map((invitation) => (
      <Box key={invitation.id} display="flex" gap="1rem" padding="0.25rem">
        <Typography>
          <Trans
            values={{
              userName: invitation.creator.userName,
            }}
          >
            {t("COMMON.USER")} <u>{invitation.creator.userName}</u>
            {t("PROFILE.INVITES_YOU")}
          </Trans>
        </Typography>
        <Button
          variant="outlined"
          onClick={() => handleUpdate(invitation, InvitationState.Accepted)}
        >
          {t("COMMON.ACCEPT")}
        </Button>
        <Button
          variant="outlined"
          color="error"
          onClick={() => handleUpdate(invitation, InvitationState.Rejected)}
        >
          {t("COMMON.REJECT")}
        </Button>
      </Box>
    ));
  return (
    <Box className="paper" display="flex" flexDirection="column" gap="0.25rem">
      <Typography variant="h6">{t("PROFILE.INVITATIONS")}</Typography>
      <Divider />
      {sentInvitations}
      {invitations}
    </Box>
  );
};

export const useGetInvitation = () => {
  return useQuery({
    queryKey: ["invitation"],
    queryFn: () =>
      apiClient
        .get<Invitation[]>(`${config.baseUrl}/api/invitation?expand=Creator`)
        .then((res) => res.data),
  });
};

const usePutInvitation = () => {
  return useMutation({
    mutationFn: (data: Partial<Invitation>) =>
      apiClient.put<Invitation>(`${config.baseUrl}/api/invitation`, data),
  });
};
