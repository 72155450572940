import { PropsWithChildren, createContext, useContext } from "react";

export interface GameEditContextState {
  editEnabled?: boolean;
  generic?: boolean;
  main?: boolean;
}

const GameEditContext = createContext<GameEditContextState>({
  editEnabled: false,
  generic: false,
  main: true,
});

interface GameEditProviderProps extends GameEditContextState {}

export const GameEditProvider = ({
  children,
  editEnabled,
  generic,
  main = true,
}: PropsWithChildren<GameEditProviderProps>) => {
  return (
    <GameEditContext.Provider value={{ editEnabled, generic, main }}>
      {children}
    </GameEditContext.Provider>
  );
};

export const useGameEdit = () => useContext(GameEditContext);
