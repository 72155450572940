import "./TokenPicker.css";
import { Popover, IconButton, Box } from "@mui/material";
import { useState } from "react";
import StarsIcon from "@mui/icons-material/Stars";
import { tokens } from "./tokens";
import { clsx } from "../../../utils/clsx";

interface TokenPickerProps {
  anchorEl?: HTMLDivElement;
  onTokenChange: (token: string) => void;
}

export const TokenPicker = (props: TokenPickerProps) => {
  const { anchorEl, onTokenChange } = props;
  const [open, setOpen] = useState(false);

  const onTokenClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    token: string,
  ) => {
    e.stopPropagation();
    onTokenChange(token);
    setOpen(false);
  };

  return (
    <IconButton
      onClick={() => {
        setOpen(true);
      }}
    >
      <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        classes={{
          root: "shared__token-root",
        }}
      >
        <Box className="paper shared__token-picker" padding="0.5rem">
          {tokens.map((cc) => (
            <IconButton
              className="shared__token-button"
              onClick={(e) => onTokenClick(e, cc)}
            >
              <div className={clsx("ra", cc)} />
            </IconButton>
          ))}
        </Box>
      </Popover>
      <StarsIcon />
    </IconButton>
  );
};
