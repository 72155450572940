import "./UserAreaElementView.css";
import { Button, IconButton, Tooltip, Typography } from "@mui/material";
import { UserAreaElementViewProps } from "./ElementViewContainer";
import { useGame } from "../../character/hooks/useGame";
import { t } from "i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import { cuni } from "../../../shared/services/cuni/cuni";
import { useMaps } from "./MapProvider";
import SouthEastIcon from "@mui/icons-material/SouthEast";
import { useMapDrag } from "../../dices/use-map-drag";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import { Popover } from "../../../shared/components/ui/popover/Popover";
import { GameCard } from "../../../model/Game";
import { ColorPicker } from "../../../shared/components/ui/color-picker/ColorPicker";
import { useCharacter } from "../../chat/hooks/useCards";

export const UserAreaElementView = (props: UserAreaElementViewProps) => {
  const { userArea } = props;
  const positionArea = userArea.position || {
    x: 0,
    y: 0,
    width: 200,
    height: 200,
  };

  const { game } = useGame();

  const { all } = useMaps();

  const currentCharacter = useCharacter({ userId: userArea.ownerId });

  const name = Object.values(game.characters).find(
    (x) => x.__userId === userArea.ownerId,
  )?.name;

  const handlers = useMapDrag({
    draggableDb: "onUpdate",
    key: userArea.key,
    position: {
      x: positionArea.x + (positionArea?.width || 200),
      y: positionArea.y + (positionArea?.height || 200),
    },
    prevent: true,
    onUpdate: (position) => {
      const width = Math.max(200, position.x - positionArea.x);
      const height = Math.max(200, position.y - positionArea.y);
      userArea.position ??= { x: 0, y: 0 };
      userArea.position.height = height;
      userArea.position.width = width;

      cuni.object.update("maps", all, userArea);
    },
  });

  const { style, position, ...restHandlers } = handlers;

  const width = Math.max(200, position.x - positionArea.x);
  const height = Math.max(200, position.y - positionArea.y);

  return (
    <div
      className="user_area__container"
      style={{
        width,
        height,
        borderRadius: userArea.uiType === "ROUND" ? "50%" : 8,
        borderColor:
          game.root.currentPlayerId === userArea.ownerId
            ? "chartreuse"
            : userArea.color
            ? userArea.color
            : currentCharacter?.__color || "transparent",
      }}
    >
      <div className="paper block-map disable-map user_area__action">
        <Popover
          renderButton={(onClick) => (
            <Tooltip title={t("CARD_TYPES.TYPE")}>
              <IconButton size="small" onClick={onClick}>
                <GroupWorkIcon />
              </IconButton>
            </Tooltip>
          )}
        >
          {["SQUARE", "ROUND"]
            .map((x) => ({
              key: x,
              name: t(`CARD_TYPES.${x}`),
            }))
            ?.map((deck) => (
              <Button
                key={deck.key}
                size="small"
                onClick={() => {
                  userArea.uiType = deck.key as GameCard["uiType"];
                  cuni.object.update("maps", all, userArea);
                }}
              >
                {deck.name}
              </Button>
            ))}
        </Popover>
        <ColorPicker
          selected={userArea.color}
          onColorChange={(color) => {
            userArea.color = color;
            cuni.object.update("maps", all, userArea);
          }}
        />

        <Tooltip title={t("COMMON.DELETE")}>
          <IconButton
            color="error"
            size="small"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              cuni.object.remove("maps", all, userArea);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </div>

      <div className="user_area__resize disable-map" {...restHandlers}>
        <SouthEastIcon className="disable-map" />
      </div>

      <Typography
        style={{
          color:
            game.root.currentPlayerId === userArea.ownerId
              ? "chartreuse"
              : userArea.color
              ? userArea.color
              : currentCharacter?.__color || "transparent",
        }}
        className="user_area__label"
      >
        {name}
      </Typography>
    </div>
  );
};
