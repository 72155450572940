import { useTranslation } from "react-i18next";
import {
  Tooltip,
  IconButton,
  Box,
  Button,
  Modal,
  Typography,
  CircularProgress,
} from "@mui/material";
import { ModalProps } from "../../../shared/components/modal/ModalProps";
import { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { CharacterCardPanel, GameGrantPermission } from "../../../model/Game";
import { uuid } from "../../../shared/utils/uuid";
import { useUser } from "../../auth/useUser";
import { useCards, useDecks } from "../../chat/hooks/useCards";
import { CardView } from "../CardView";
import { ReactComponent as CardIcon } from "../../../assets/icons/card_hand.svg";
import { useGranPermission } from "./useGranPermission";
import { updatePanel } from "../../tokens/hooks/useGameDbPanels";
import SelectField, {
  LoseOption,
} from "../../../shared/components/logic/SelectField";
import { useGame } from "../../character/hooks/useGame";
import { Conditional } from "../../../shared/components/logic/Conditional";
import { useMaps } from "../../map-hex/components/MapProvider";
import { cuni } from "../../../shared/services/cuni/cuni";

export const CardTakeFromDeck = (props: { deckId: string }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const close = useCallback(() => setOpen(false), []);
  return (
    <>
      <CardTakeModal {...props} open={open} handleClose={close} />
      <Tooltip title={t("CARD.TAKE_DECK")}>
        <IconButton onClick={() => setOpen(true)}>
          <CardIcon style={{ width: 24, height: 24 }} />
        </IconButton>
      </Tooltip>
    </>
  );
};

type CardTakeModalProps = ModalProps & {
  deckId: string;
};

interface FormProps {
  action: "PREVIEW" | "TAKE" | "RANDOM";
}

const CardTakeModal = (props: CardTakeModalProps) => {
  const { open, handleClose: close } = props;

  const { current, all } = useMaps();
  const { t } = useTranslation();
  const { game } = useGame();

  const { watch, handleSubmit, reset, getValues, setValue } =
    useForm<FormProps>({
      defaultValues: {
        action: "PREVIEW",
      },
    });

  useEffect(() => {
    reset({
      action: "PREVIEW",
    });
  }, [reset]);

  const user = useUser();
  const { accepted, askForPermission, handleClose, loading } =
    useGranPermission({ close });

  const decks = useDecks();
  const onSubmit = (form: FormProps) => {
    if (!user) return;

    let message = "";
    let deckPlace = current?.deckPlaces.find((x) => x.key === props.deckId);
    if (!deckPlace) return;
    if (deckPlace.subType === "discardPile") {
      const originalDeckPlace = current?.deckPlaces.find(
        (x) => x.key === deckPlace!.subTypeDeckInstanceKey,
      );
      message = t("CARD.DISCARDED");
      deckPlace = originalDeckPlace;
    }
    const deck = decks.find((x) => x.key === deckPlace?.deckKey);
    message = `${deck?.name}${message ? ` (${message})` : ""}`;

    const gamePermission: GameGrantPermission = {
      // TODO: remember key of submit!
      key: uuid(),
      creatorId: user.id,
      question: t("CARD.SPEC_TAKE_QUESTION_" + getValues("action"), {
        user: user.userName,
        deck: message,
      }),
      answers: [],
    };
    askForPermission(gamePermission);
  };

  const cards = useCards();

  // const { current, all } = useMaps();
  // const currentPlace = current?.deckPlaces.find((x) => x.key === deckId);
  const takeCard = useCallback(
    (cardKey: string) => {
      const deckPlace = current?.deckPlaces.find((x) => x.key === props.deckId);

      const ownDeck = Object.entries(game.characters)
        .map((x) => x[1])
        .filter((x) => x.__userId === user?.id)
        .map((x) => x.__panels?.find((x) => x.__type === "card"))?.[0];
      if (!deckPlace || !ownDeck) return;

      // remove
      deckPlace.cards = deckPlace.cards.filter((x) => x !== cardKey);
      cuni.object.update("maps", all, deckPlace.cards);

      // for now we need to delay update panel to be in sync
      setTimeout(() => {
        updatePanel(ownDeck.key, (state: CharacterCardPanel) => {
          state.cards.push({
            key: uuid(),
            cardKey,
            deckFlyweightKey: deckPlace.key,
          });
          return state;
        });
        handleClose();
      }, 100);
    },
    [all, current, game.characters, handleClose, props.deckId, user?.id],
  );

  const resetRef = useRef(false);

  useEffect(() => {
    const deckPlace = current?.deckPlaces.find((x) => x.key === props.deckId);
    if (!deckPlace) return;
    if (resetRef.current) return;
    if (!accepted) return;
    if (watch("action") === "RANDOM") {
      // avoid duplicate execution
      resetRef.current = true;
      setTimeout(() => (resetRef.current = false), 1000);

      const cards = deckPlace.cards;
      const index = getRandomInt(cards.length);
      takeCard(cards[index]);
      handleClose();
    }
  }, [accepted, current, handleClose, props.deckId, takeCard, watch]);

  const deckPlace = current?.deckPlaces.find((x) => x.key === props.deckId);

  const component = loading ? (
    <Box display="flex" justifyContent="center" padding="1rem">
      <CircularProgress />
    </Box>
  ) : accepted && deckPlace?.cards && watch("action") !== "RANDOM" ? (
    <>
      <Conditional condition={watch("action") === "TAKE"}>
        <Typography id="modal-avatar-description" sx={{ mt: 2 }}>
          {t("CARD.TAKE_DESC_ACCEPTED")}
        </Typography>
      </Conditional>

      <Box display="flex" gap="1rem" flexWrap="wrap">
        {deckPlace.cards.map((cardKey: string) => {
          const data = cards.find((card) => card.key === cardKey);
          if (!data) return <></>;
          return (
            <CardView
              {...data}
              key={cardKey}
              onClick={
                watch("action") === "TAKE" ? () => takeCard(cardKey) : undefined
              }
            />
          );
        })}
      </Box>
    </>
  ) : (
    <>
      <Typography id="modal-avatar-description" sx={{ mt: 2 }}>
        {t("CARD.TAKE_DESC")}
      </Typography>
      <SelectField<LoseOption>
        label={t("CARD_TYPES.TYPE")}
        value={{ key: watch("action"), name: watch("action") }}
        onChange={(e: LoseOption) => {
          setValue("action", e.key);
        }}
        options={[
          { key: "PREVIEW", name: t("CARD.OPTION_PREVIEW") },
          { key: "TAKE", name: t("CARD.OPTION_TAKE") },
          { key: "RANDOM", name: t("CARD.OPTION_RANDOM") },
        ]}
      />
    </>
  );

  return (
    <Modal open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box className="modal">
          <Typography variant="h6" component="h2">
            {t("CARD.TAKE")}
          </Typography>
          {component}

          <Conditional condition={!accepted && !loading}>
            <Button type="submit" variant="contained">
              {t("COMMON.SEND")}
            </Button>
          </Conditional>

          <Button onClick={handleClose}>{t("COMMON.CLOSE")}</Button>
        </Box>
      </form>
    </Modal>
  );
};

function getRandomInt(max: number) {
  return Math.floor(Math.random() * max);
}
