import "./DeckPlace.css";
import { MapCardPlace } from "../../../model/Map";
import { clsx } from "../../../shared/utils/clsx";
import { useGame } from "../../character/hooks/useGame";
import { useMapDrag } from "../../dices/use-map-drag";
import { useTranslation } from "react-i18next";
import { useDrop } from "react-dnd";
import { MapHexDrop } from "../../map-hex/MapHexDrop";
import { useMaps } from "../../map-hex/components/MapProvider";
import { cuni } from "../../../shared/services/cuni/cuni";
import { updatePanel } from "../../tokens/hooks/useGameDbPanels";
import { CharacterCardPanel } from "../../../model/Game";
import { useHover } from "../../../shared/hooks/useHover";
import { useMapDragHandler } from "../../dices/MapDragProvider";
import i18next from "i18next";

export interface DeckPlaceProps {
  fixed?: boolean;
  deck: MapCardPlace;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  actions?: JSX.Element;
}
export const DeckPlace = (props: DeckPlaceProps) => {
  const { deck, onClick, fixed = false, actions } = props;
  const { t } = useTranslation();
  const canDrop = !deck.deckKey && deck.type === "deck";

  const { isHovered, ...hoverHandlers } = useHover();
  const { current: currentDragHandler } = useMapDragHandler();
  const { all, current } = useMaps();

  // @ts-ignore
  const onMouseUp = (e) => {
    if (
      current &&
      currentDragHandler.current &&
      (isHovered || (e.changedTouches && e.changedTouches.length > 0)) &&
      currentDragHandler.current !== deck.key &&
      deck.type === "deck"
    ) {
      const currentCard = current.deckPlaces.find(
        (x) => x.key === currentDragHandler.current,
      );

      if (currentCard?.type !== "card" || !currentCard.card?.key) return;
      const bottomDeckPlace = current.deckPlaces.find(
        (x) => x.key === deck.key,
      );
      if (!bottomDeckPlace) return;

      cuni.array.push(
        "maps",
        all,
        bottomDeckPlace.cards,
        currentCard.card?.key,
      );
      const currentDeckPlace = current.deckPlaces.find(
        (x) => x.key === currentCard.key,
      );
      e.stopPropagation();
      e.preventDefault();
      cuni.object.remove("maps", all, currentDeckPlace!);

      cuni.log(i18next.t("LOG.ON_DECK"), {
        type: "OnHand",
        cardKeyList: [currentCard.card?.key],
        deckInstanceKey: bottomDeckPlace.key,
      });
    }
  };

  const [{ isOver }, drop] = useDrop(
    {
      accept: "TOKEN-CARD",
      drop: (item: MapHexDrop) => {
        const canDrop = !deck.deckKey && deck.type === "deck";
        if (!canDrop) return;

        let cardKey = "";
        if (item.type === "FROM_TABLE") {
          if (item.deck.type === "deck") return;
          cardKey = item.deck.card!.key;
        }
        if (item.type === "FROM_HAND") {
          cardKey = item.card.key;
        }
        if (!cardKey) {
          console.error("no card key");
          return;
        }

        if (!current) return;
        const dbItem = current.deckPlaces.find((x) => x.key === deck.key);
        if (!dbItem) return;

        cuni.array.push("maps", all, dbItem.cards, cardKey);

        if (item.type === "FROM_TABLE") {
          const toRemove = current.deckPlaces.find(
            (x) => x.key === item.deck.key,
          );
          cuni.object.remove("maps", all, toRemove);
        }

        if (item.type === "FROM_HAND") {
          const { instance, panel } = item;
          updatePanel(panel.key, (state: CharacterCardPanel) => {
            state.cards = state.cards.filter((x) => x.key !== instance.key);
            return state;
          });
        }
        cuni.log(i18next.t("LOG.ON_DECK"), {
          type: "OnHand",
          cardKeyList: [cardKey],
          deckInstanceKey: dbItem.key,
        });
      },
      collect: (monitor) => {
        return {
          isOver: canDrop && !!monitor.isOver(),
        };
      },
    },
    [current, all, canDrop],
  );

  const handlers = useMapDrag({
    draggableDb: "deckPlaces",
    key: deck.key,
    position: deck.position,
    fixed,
  });

  const {
    avatarUrl,
    backgroundColor,
    color,
    height,
    originalDeck,
    uiType,
    width,
  } = useDeckProperties(deck);

  const allHandlers = deck.fixed
    ? {}
    : {
        ...handlers,
        ...hoverHandlers,
        onMouseUp,
        onTouchEnd: onMouseUp,
      };

  const deckView =
    deck.type === "deck" &&
    (uiType === "CARD" || uiType === "SQUARE" || uiType === undefined) ? (
      <>
        {[0, 2, 4, 6].map((x) => (
          <>
            <div
              className="deck-place-multi-v"
              style={{ right: x - 12, top: 12 - x }}
            />
            <div
              className="deck-place-multi-h"
              style={{ bottom: x - 12, left: 11 - x }}
            />
          </>
        ))}
      </>
    ) : (
      <></>
    );

  return (
    <div
      ref={drop}
      {...allHandlers}
      style={{
        filter: deck.subType === "discardPile" ? "grayscale()" : undefined,
        height,
        width,
        backgroundColor: backgroundColor || "black",
        color: color || "white",
        backgroundImage: `url(${avatarUrl})`,
        backgroundSize: "cover",
        rotate: `${deck.rotate || 0}deg`,
        border: canDrop && isOver ? "red" : undefined,
        ...handlers.style,
      }}
      className={clsx("deck-place", "disable-map", "no-drag", {
        [`uiType-big-${uiType}`]: !!uiType,
      })}
      onClick={onClick}
    >
      {actions}
      {deck.card
        ? avatarUrl
          ? ""
          : deck.card.name
        : avatarUrl
        ? ""
        : `${originalDeck?.name || t("COMMON.EMPTY")} [${deck?.cards.length}]`}
      <span className="deck-place-hover">
        {deck.card
          ? deck.card.name
          : `${originalDeck?.name || t("COMMON.EMPTY")} [${
              deck?.cards.length
            }]`}
      </span>
      {deckView}
    </div>
  );
};

export const useDeckProperties = (deck: MapCardPlace) => {
  const { current } = useMaps();
  const { game } = useGame();
  let originalDeck = game.root.settings?.decks?.find(
    (x) => x.key === deck.deckKey || x.key === deck.card?.deckKey,
  );

  if (!originalDeck && deck.subType === "discardPile") {
    const rootDeckPlace = current?.deckPlaces.find(
      (x) => x.key === deck.subTypeDeckInstanceKey,
    );
    originalDeck = game.root.settings?.decks?.find(
      (x) => x.key === rootDeckPlace?.deckKey,
    );
  }

  const avatarUrl =
    deck.type === "card"
      ? deck.flipped
        ? originalDeck?.cardReverseAvatarUrl
        : deck.card?.avatarUrl
      : originalDeck?.reverseAvatarUrl;

  const uiType =
    deck.type === "card" ? deck.card?.uiType : originalDeck?.uiType;
  const color =
    deck.type === "card"
      ? deck.card?.textColor || originalDeck?.cardTextColor
      : originalDeck?.textColor;
  const backgroundColor =
    deck.type === "card"
      ? deck.card?.color || originalDeck?.cardColor
      : originalDeck?.color;
  const size = deck.card?.size ?? originalDeck?.size ?? undefined;
  const width = size || 160;
  const height =
    deck.card?.sizeY ??
    originalDeck?.sizeY ??
    (["CARD", undefined].includes(uiType) ? width * 1.5 : width);



  return {
    originalDeck,
    avatarUrl,
    backgroundColor,
    color,
    height,
    uiType,
    width,
  };
};
