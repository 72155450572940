import React from "react";

import { CharacterTabPanel } from "../../../../model/Game";
import { GenericTemplateView, Tree } from "./GenericTemplateView";
import { Parser } from "html-to-react";
import { TablePanelView } from "./components/table-panel-view/TablePanelView";
import { PanelMenu, PanelMenuProps } from "./components/panel-menu/PanelMenu";
import { clsx } from "../../../../shared/utils/clsx";
import { CardPanelView } from "./components/CardPanelView";
import { TemplatePanelView } from "./components/TemplatePanelView";

export interface GenericPanelViewProps {
  path: string;
  panels: CharacterTabPanel[];
}

export const GenericPanelView = (props: GenericPanelViewProps) => {
  const { panels, path } = props;

  const components = panels.map((temp, idx) => {
    return <GenericPanel panel={temp} path={`${path}.${idx}`} />;
  });
  return <React.Fragment>{components}</React.Fragment>;
};

interface GenericPanelProps extends Pick<PanelMenuProps, "panelOnMap"> {
  panel: CharacterTabPanel;
  path: string;
  className?: string;
  isOnMap?: boolean;
  style?: React.CSSProperties;
}

export const GenericPanel = (props: GenericPanelProps) => {
  const { panel: temp, path, className, isOnMap, panelOnMap, style } = props;
  switch (temp.__type) {
    case "image":
      return (
        <PanelMenu
          key={temp.title}
          panelOnMap={panelOnMap}
          actions={
            isOnMap
              ? ["REMOVE_FROM_MAP", "SHOW_OTHERS", "EXPORT", "EDIT_CONTENT"]
              : ["REMOVE_PANEL", "EXPORT", "EDIT_CONTENT", "ADD_TO_MAP"]
          }
          path={path}
        >
          <div className={clsx("paper", className)} style={style}>
            <img
              alt="custom"
              style={{ maxWidth: 200, maxHeight: 200 }}
              src={temp.richText}
            />
          </div>
        </PanelMenu>
      );
    case "text":
      return (
        <PanelMenu
          key={temp.title}
          actions={
            isOnMap
              ? ["REMOVE_FROM_MAP", "SHOW_OTHERS", "EXPORT", "EDIT_CONTENT"]
              : ["REMOVE_PANEL", "EXPORT", "EDIT_CONTENT", "ADD_TO_MAP"]
          }
          path={path}
          panelOnMap={panelOnMap}
        >
          <div className={clsx("paper", className)} style={style}>
            <Tree justValue obj={{ title: temp.title }} sufix={path} />
            {Parser().parse(temp.richText)}
          </div>
        </PanelMenu>
      );
    case "card":
      return (
        <PanelMenu
          key={temp.title}
          actions={
            isOnMap
              ? ["REMOVE_FROM_MAP", "SHOW_OTHERS", "EXPORT"]
              : ["REMOVE_PANEL", "EXPORT", "ADD_TO_MAP"]
          }
          path={path}
          panelOnMap={panelOnMap}
        >
          <div className={clsx("paper", className)} style={style}>
            <Tree justValue obj={{ title: temp.title }} sufix={path} />
            <CardPanelView panel={temp} path={path} panelOnMap={panelOnMap} />
          </div>
        </PanelMenu>
      );
    case "template":
      return (
        <PanelMenu
          key={temp.title}
          actions={
            isOnMap
              ? ["REMOVE_FROM_MAP", "SHOW_OTHERS", "EXPORT"]
              : ["REMOVE_PANEL", "EXPORT", "ADD_TO_MAP"]
          }
          path={path}
          panelOnMap={panelOnMap}
        >
          <div className={clsx("paper", "disable-map", className)} style={style}>
            <Tree justValue obj={{ title: temp.title }} sufix={path} />
            <TemplatePanelView />
            {/* <CardPanelView panel={temp} path={path} panelOnMap={panelOnMap} /> */}
          </div>
        </PanelMenu>
      );
    case "table":
      return (
        <TablePanelView
          key={temp.title}
          panel={temp}
          path={path}
          className={className}
          style={style}
          isOnMap={isOnMap}
          panelOnMap={panelOnMap}
        />
      );
    case "generic":
    default:
      return (
        <GenericTemplateView
          key={temp.title}
          template={temp}
          path={path}
          className={className}
          style={style}
          isOnMap={isOnMap}
          panelOnMap={panelOnMap}
        />
      );
  }
};
