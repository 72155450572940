import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { Conditional } from "./Conditional";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

enum PasswordValidation {
  SPECIAL_CHARACTER = "SPECIAL_CHARACTER",
  MIN_LENGTH = "MIN_LENGTH",
  UPPERCASE = "UPPERCASE",
  LOWERCASE = "LOWERCASE",
  NUMBER = "NUMBER",
}

interface ValidationResult {
  key: PasswordValidation;
  valid: boolean;
}

export const validatePassword = (password: string): ValidationResult[] => {
  // Regular expressions for different checks
  const hasSpecialCharacter = /[!@#$%^&*(),.?":{}|<>]/.test(password);
  const hasUppercase = /[A-Z]/.test(password);
  const hasLowercase = /[a-z]/.test(password);
  const hasNumber = /\d/.test(password);
  const hasMinLength = password.length >= 8;

  // Returning results in an array
  return [
    { key: PasswordValidation.SPECIAL_CHARACTER, valid: hasSpecialCharacter },
    { key: PasswordValidation.UPPERCASE, valid: hasUppercase },
    { key: PasswordValidation.LOWERCASE, valid: hasLowercase },
    { key: PasswordValidation.NUMBER, valid: hasNumber },
    { key: PasswordValidation.MIN_LENGTH, valid: hasMinLength },
  ];
};

interface PasswordAnalyzerProps {
  password?: string;
}

export const PasswordAnalyzer = (props: PasswordAnalyzerProps) => {
  const { password = "" } = props;
  const { t } = useTranslation();

  const result = validatePassword(password);
  return (
    <div>
      {result.map((x) => (
        <Box key={x.key} display="flex" justifyContent="left" alignItems="center" gap="1rem">
          <Conditional
            condition={x.valid}
            success={() => <CheckCircleIcon />}
            failure={() => <RadioButtonUncheckedIcon />}
          />
          <Typography variant="caption">
            {t(`ERROR.PASSWORD_VALIDATION.${x.key}.description`)}
          </Typography>
        </Box>
      ))}
    </div>
  );
};
