import { RefObject, useEffect } from "react";

export interface UseInfiniteScrollProps {
  scrollRef: RefObject<HTMLElement>;
  fetchMore?: () => void;
}
export const useInfiniteScroll = (props: UseInfiniteScrollProps) => {
  const { scrollRef, fetchMore } = props;
  useEffect(() => {
    const scrollElement = scrollRef.current;
    if (!scrollElement) return;

    const handleScroll = () => {
      const diff =
        scrollElement.scrollHeight -
        (scrollElement.scrollTop + scrollElement.clientHeight);
      if (diff < 50) {
        fetchMore?.();
      }
    };
    scrollElement.addEventListener("scrollend", handleScroll);
    return () => scrollElement?.removeEventListener("scrollend", handleScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchMore, scrollRef.current]);
};
