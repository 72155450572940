import { FormControlLabel, Checkbox } from "@mui/material";
import React from "react";
import { useCuni } from "../../../../../../shared/services/cuni/useCuni";

interface InputProps<T> {
  value: T;
  label: string;
  onChange: (value: T) => void;
}

export const CheckboxGenericInput = (props: InputProps<boolean>) => {
  const { value, onChange, label } = props;
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={value}
          value={value}
          onChange={() => {
            onChange(!value);
          }}
        />
      }
      label={label}
    />
  );
};

interface InputCuniProps<T> {
  charId: number;
  label: string;
  path: string;
  value: T;
}

function asCuniInput<T>(Component: React.ComponentType<InputProps<T>>) {
  return (props: InputCuniProps<T>) => {
    const { path, charId, ...others } = props;
    const { cuni } = useCuni();
    return (
      <Component
        onChange={(value) => {
          cuni.update(charId, path, value);
        }}
        {...others}
      />
    );
  };
}

export const CuniCheckbox = asCuniInput(CheckboxGenericInput);
