import { OwnedEntity } from "./BaseEntity";

export interface Invitation extends OwnedEntity {
  id: number;
  receiverId?: number;
  email: string;
  state: InvitationState;
}

export enum InvitationState {
  Pending = 1,
  Send = 2,
  Accepted = 4,
  Rejected = 8,
}
