import { PropsWithChildren, useState } from "react";
import { AuthContext, AuthData } from "./AuthContext";
import { authStorage } from "../../shared/services/storage";

const initAuth = authStorage.get<AuthData>();
export const AuthProvider = (props: PropsWithChildren) => {
  const [data, setData] = useState<AuthData | undefined>(
    initAuth ? initAuth : undefined,
  );

  return (
    <AuthContext.Provider value={{ setData, data }}>
      {props.children}
    </AuthContext.Provider>
  );
};
