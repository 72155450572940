import { useState, useRef, useCallback, useEffect } from "react";
import { GameGrantPermission } from "../../../model/Game";
import { tokenProviderRef } from "../../../shared/providers/toast/ToastProvider";
import { cuni } from "../../../shared/services/cuni/cuni";
import { useUser } from "../../auth/useUser";
import { useGame } from "../../character/hooks/useGame";
import { UseGrantPermissionProps } from "./CardLookThrough";
import { useTranslation } from "react-i18next";

export const useGranPermission = (props: UseGrantPermissionProps) => {
  const { close } = props;
  const { game } = useGame();
  const { t } = useTranslation();
  const user = useUser();
  const [loading, setLoading] = useState(false);
  const timeoutRef = useRef<any>();

  const handleClose = useCallback(() => {
    cuni.updateGame("permission", null);
    close();
    clearTimeout(timeoutRef.current);
    setLoading(false);
  }, [close]);

  const accepted = game.root.permission?.answers.some(
    (x) => x.userId !== user?.id && x.accept,
  );
  useEffect(() => {
    if (!accepted) return;
    clearTimeout(timeoutRef.current);
    setLoading(false);
  }, [accepted]);

  // listen for changes if there is approval answer then continue if not discard and remove question
  useEffect(() => {
    if (!game.root.permission || !user?.id) return;
    const answer = game.root.permission?.answers.find(
      (x) => x.userId !== user?.id,
    );
    if (answer && !answer.accept) handleClose();
  }, [game, handleClose, user]);

  const askForPermission = useCallback(
    (gamePermission: GameGrantPermission) => {
      cuni.updateGame("permission", gamePermission);
      setLoading(true);

      timeoutRef.current = setTimeout(() => {
        handleClose();
        setLoading(false);
        tokenProviderRef?.addToast(t("COMMON.TIMEOUT"), "ERROR");
      }, 10000);
    },
    [handleClose, t],
  );

  return { loading, accepted, askForPermission, handleClose };
};
