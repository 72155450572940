import { useTranslation } from "react-i18next";
import { CharacterCardPanel } from "../../../../../model/Game";
import { AreYouSureModal } from "../../../../../shared/components/modal/AreYouSureModal";
import { cuni } from "../../../../../shared/services/cuni/cuni";
import { cardUtils } from "../../../../../shared/utils/cardUtils";
import { uuid } from "../../../../../shared/utils/uuid";
import { updatePanel } from "../../../../tokens/hooks/useGameDbPanels";
import { useGame } from "../../../hooks/useGame";
import { MapCardPlace } from "../../../../../model/Map";
import i18next from "i18next";

export interface MultiplePickDialogProps {
  panelKey: string;
  mapPlace: MapCardPlace | null;
  close: () => void;
}

export const MultiplePickDialog = (props: MultiplePickDialogProps) => {
  const { panelKey, mapPlace, close } = props;

  const { game } = useGame();
  const { t } = useTranslation();
  return (
    <AreYouSureModal
      title={t("GAME.ADD_ROW")}
      content={t("GAME.ADD_ROW_DESC")}
      confirmAction={(fields) => {
        if (fields && parseInt(fields.value) && mapPlace?.key) {
          const value = parseInt(fields.value);
          const cards: string[] = [];
          for (let i = 0; i < value; i++) {
            const card = cardUtils.pickFromDeck(game, mapPlace.key);
            // TODO: if no card we should shuffle
            if (card) cards.push(card);
          }
          updatePanel(panelKey, (state: CharacterCardPanel) => {
            cards.forEach((x) => {
              state.cards.push({
                key: uuid(),
                deckFlyweightKey: mapPlace.key,
                cardKey: x,
              });
            });

            return state;
          });
          cuni.log(i18next.t("LOG.ON_HAND"), {
            type: "OnHand",
            cardKeyList: cards,
          });
        }
      }}
      open={!!mapPlace}
      handleClose={close}
      fields={[
        {
          name: "value",
        },
      ]}
    />
  );
};
