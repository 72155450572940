import CasinoIcon from "@mui/icons-material/Casino";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import { Box, IconButton } from "@mui/material";
import { useCallback, useState } from "react";
import { AvatarModal } from "../avatar/modal/AvatarModal";
import { DiceRollModal } from "../dice-roll/DiceRollModal";
import { NoteModal } from "../note/NoteModal";
import { InteractionModal } from "../interaction/InteractionModal";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
// import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import EditNoteIcon from "@mui/icons-material/EditNote";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import { StoryModal } from "../story/StoryModal";

// TODO: optimize all modals
export const CharacterActions = () => {
  const [modal, setModal] = useState("");
  const handleClose = useCallback(() => setModal(""), []);

  return (
    <Box display="flex" padding="0 0.5rem" className="paper paper__tab paper__tab-0">
      <IconButton onClick={() => setModal("avatar")}>
        <InsertEmoticonIcon />
      </IconButton>
      <IconButton onClick={() => setModal("diceroll")}>
        <CasinoIcon />
      </IconButton>
      <IconButton onClick={() => setModal("note")}>
        <EditNoteIcon />
      </IconButton>
      <IconButton onClick={() => setModal("interaction")}>
        <ElectricBoltIcon />
      </IconButton>
      <IconButton onClick={() => setModal("story")}>
        <AutoStoriesIcon />
      </IconButton>
      <AvatarModal open={modal === "avatar"} handleClose={handleClose} />
      <DiceRollModal open={modal === "diceroll"} handleClose={handleClose} />
      <NoteModal open={modal === "note"} handleClose={handleClose} />
      <StoryModal open={modal === "story"} handleClose={handleClose} />
      <InteractionModal
        open={modal === "interaction"}
        handleClose={handleClose}
      />
    </Box>
  );
};
