import React, { useState } from "react";
import { BasicView } from "../shared/components/ui/BasicView";
import { useAuth } from "../features/auth/useAuth";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Alert, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { Conditional } from "../shared/components/logic/Conditional";
import { LoadingButton } from "../shared/components/ui/LoadingButton";
import { useTranslation } from "react-i18next";

interface CodeForm {
  code: string;
}

export const CodeConfirmPage: React.FC = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  const { t } = useTranslation();
  const [search] = useSearchParams();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<CodeForm>();
  const [apiError, setApiError] = useState<string>();
  const [loading, setLoading] = useState(false);

  const submit = async ({ code }: CodeForm) => {
    try {
      setLoading(true);
      if (!auth.data?.email) throw new Error("There should be email of auth");
      await auth.confirmCode(code, auth.data.email);
      const uri = search.get("redirect_uri");
      if (uri) navigate(`/${uri}`);
      else navigate("/list");
    } catch (error) {
      setApiError(
        // @ts-ignore
        error.response.status === 401
          ? t("ERROR.WRONG_CODE")
          : t("ERROR.TRY_AGAIN_LATER"),
      );
    } finally {
      setLoading(false);
    }
  };

  const [loadingSendAgain, setLoadingSendAgain] = useState(false);

  const sendAgain = async () => {
    try {
      setLoadingSendAgain(true);
      if (!auth.data?.email) throw new Error("There should be email of auth");
      await auth.generateCode(auth.data.email);
    } catch (error) {
      setApiError(
        // @ts-ignore
        error.response.status === 401
          ? t("ERROR.WRONG_CREDENTIALS")
          : t("ERROR.TRY_AGAIN_LATER"),
      );
    } finally {
      setLoadingSendAgain(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <BasicView>
        <label>{t('LOGIN.VERIFICATION')}</label>
        <TextField
          label={t('LOGIN.CODE')}
          {...register("code", { required: true, minLength: 6, maxLength: 6 })}
          error={Boolean(errors.code)}
          helperText={t('LOGIN.CODE_CHECK')}
        />
        <Conditional condition={apiError}>
          <Alert severity="error">{apiError}</Alert>
        </Conditional>
        <LoadingButton
          loading={loading}
          variant="contained"
          type="submit"
          disabled={!isValid}
        >
          {t('LOGIN.VERIFY')}
        </LoadingButton>
        <LoadingButton loading={loadingSendAgain} onClick={sendAgain}>
          {t('LOGIN.SEND_AGAIN')}
        </LoadingButton>
      </BasicView>
    </form>
  );
};
