import { ModalProps } from "../../../../../../../shared/components/modal/ModalProps";
import { useCharacterTabs } from "../../../CharacterTabProvider";
import { useCuni } from "../../../../../../../shared/services/cuni/useCuni";
import { AreYouSureModal } from "../../../../../../../shared/components/modal/AreYouSureModal";
import {
  getTabOrPanelIdentifier,
  parseInputValue,
} from "../../../../../../../model/utils/charaterUtils";
import { useTranslation } from "react-i18next";

type NewRowModalProps = ModalProps & {
  path: string;
};

export const NewRowModal = (props: NewRowModalProps) => {
  const { open, handleClose, path } = props;
  const { character, tab } = useCharacterTabs();
  const { t } = useTranslation();

  const { cuni } = useCuni();

  // TODO: add validation for same properties
  const addPropertySubmit = (values?: Record<string, string>) => {
    if (!values) return;
    const value = values["value"];
    const panels =
      (tab.title === "main" ? character.__panels : tab.__panels) ?? [];
    const index = getTabOrPanelIdentifier(path);

    const data = panels[index].__data;
    const columns = panels[index].__columns;
    if (!Array.isArray(data) || !columns || !Array.isArray(columns))
      throw new Error("__data of table panel should be array");

    const newDataValues = value
      .split(",")
      .map((x) => x.trim())
      .map((x) => parseInputValue(x));
    const newDataRow: Record<string, any> = {};
    columns.forEach((element, idx) => {
      newDataRow[element.key] = newDataValues[idx];
    });
    data.push(newDataRow);
    cuni.update(character.key, `${path}.__data`, data);
  };
  return (
    <AreYouSureModal
      title={t("GAME.ADD_ROW")}
      content={t("GAME.ADD_ROW_DESC")}
      confirmAction={addPropertySubmit}
      open={open}
      handleClose={handleClose}
      fields={[
        {
          name: "value",
        },
      ]}
    />
  );
};
