import { useMutation } from "@tanstack/react-query";
import { config } from "../../../config";
import { Game } from "../../../model/Game";
import { apiClient } from "../../../shared/services/apiClient";

type InputDto = Partial<Game>;

export const usePatchGame = () => {
  return useMutation({
    mutationFn: (data: InputDto) =>
      apiClient.patch<InputDto>(`${config.baseUrl}/game/${data.id}/`, data),
  });
};
