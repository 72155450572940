import { Theme, useMediaQuery } from "@mui/material";
import React from "react";

export interface IBasicViewProps {
  children?: React.ReactElement[];
  style?: React.CSSProperties;
}

export const BasicView: React.FC<IBasicViewProps> = (
  props: IBasicViewProps,
) => {
  // TODO: move to some single file
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));
  return (
    <div
      style={{
        ...props.style,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100vw",
        height: "calc(100vh - 4rem)",
      }}
    >
      <div
        style={{
          padding: 40,
          gap: 8,
          display: "flex",
          flexDirection: "column",
          minWidth: isMobile ? "400px" : undefined,
        }}
        className="paper"
      >
        {props.children}
      </div>
    </div>
  );
};
