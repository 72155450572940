import { useRef } from "react";

function useDeepMemo<T>(factory: () => T, dependencies: any[]): T {
  const prevDepsRef = useRef<string>();
  const prevResultRef = useRef<T>();

  // Convert dependencies to JSON strings for deep comparison
  const depsString = JSON.stringify(dependencies);

  // Compare the JSON string of the current dependencies with the previous ones
  if (prevDepsRef.current !== depsString) {
    // If dependencies changed, re-compute the value
    prevResultRef.current = factory();
    prevDepsRef.current = depsString; // Update reference with new dependencies string
  }

  return prevResultRef.current as T;
}

export default useDeepMemo;
