import {
  useRef,
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import { clsx } from "../../../shared/utils/clsx";
import { useAws } from "../../../shared/hooks/use-aws";
import { uuid } from "../../../shared/utils/uuid";
import { useMaps } from "./MapProvider";
import { cuni } from "../../../shared/services/cuni/cuni";
import { mapUtils } from "../mapUtils";
import { GamePosition } from "../../../model/GamePosition";
import { useQueryClient } from "@tanstack/react-query";

interface MapDrawingLayerProps {
  color: string | undefined;
}
export interface MapDrawingLayerType {
  clear: () => void;
}

export const MapDrawingLayer = forwardRef<
  MapDrawingLayerType,
  MapDrawingLayerProps
>((props: MapDrawingLayerProps, ref) => {
  const { color } = props;
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [drawing, setDrawing] = useState(false);
  const { current, all } = useMaps();

  const queryClient = useQueryClient();
  const { remove } = useAws();

  useImperativeHandle(
    ref,
    () => {
      return {
        clear: async () => {
          if (!current) return;
          await remove(current.images.map((x) => x.imageKey));
          current.images = [];
          cuni.object.update("maps", all, current.images);
        },
      };
    },
    [current, all, remove],
  );

  useEffect(() => {
    const canvas = canvasRef.current!;
    const resizeCanvas = () => {
      canvas.width = canvas.offsetWidth;
      canvas.height = canvas.offsetHeight;
    };

    window.addEventListener("resize", resizeCanvas);
    resizeCanvas();

    return () => window.removeEventListener("resize", resizeCanvas);
  }, []);

  const startDrawing = () => setDrawing(true);

  const { upload } = useAws();
  const stopDrawing = () => {
    setDrawing(false);
    const canvas = canvasRef.current!;
    const ctx = canvas.getContext("2d")!;
    ctx.beginPath(); // Begin a new path to avoid lines being connected
    setTimeout(() => {
      const fileName = `temporary/name.png`; // Name will be replaced

      if (!current) return;
      // Convert canvas to Blob
      canvas.toBlob((blob) => {
        if (!blob) {
          console.error("Canvas is empty");
          return;
        }

        // Create a File object from the Blob
        const file = new File([blob], fileName, { type: "image/png" });

        upload([file]).then((data) => {
          const { key, location } = data![0];
          const transformData = mapUtils.getTransformDataFromMapContainer();
          const { x, y } = mapUtils.calculateNewCoordinates(
            0,
            0,
            transformData,
          );
          const position: GamePosition = {
            x,
            y,
            zIndex: 4999,
            height: transformData.height / transformData.scale,
            width: transformData.width / transformData.scale,
          };
          current.images ??= [];
          cuni.array.push("maps", all, current.images, {
            key: uuid(),
            imageKey: key,
            imageUrl: location,
            position,
          });
          const canvas = canvasRef.current!;
          const ctx = canvas.getContext("2d")!;
          ctx.clearRect(0, 0, canvas.width, canvas.height);

          queryClient.invalidateQueries({
            queryKey: ["resource-all"],
            exact: true,
          });
        });
      }, "image/png");
    }, 2000);
  };

  const draw = (e: React.MouseEvent) => {
    if (!drawing) return;
    const canvas = canvasRef.current!;
    const ctx = canvas.getContext("2d")!;
    const rect = canvas.getBoundingClientRect();

    ctx.lineWidth = 3;
    ctx.lineCap = "round";
    //@ts-ignore
    ctx.strokeStyle = color;

    ctx.lineTo(e.clientX - rect.left, e.clientY - rect.top);
    ctx.stroke();
    ctx.beginPath();
    ctx.moveTo(e.clientX - rect.left, e.clientY - rect.top);
  };

  return (
    <canvas
      ref={canvasRef}
      style={{
        pointerEvents: color ? "all" : "none",
      }}
      className={clsx("map__drawing-layer", {
        "disable-map": !!color,
      })}
      onMouseDown={startDrawing}
      onMouseUp={stopDrawing}
      onMouseMove={draw}
    />
  );
});
