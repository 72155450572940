import { MapCardPlace } from "../../../model/Map";
import { Popover } from "../../../shared/components/ui/popover/Popover";
import { DeckPlace, useDeckProperties } from "./DeckPlace";
import { useDrag } from "react-dnd";
import { ReactComponent as CardIcon } from "../../../assets/icons/card.svg";
import { ReactComponent as CardOnTableHidden } from "../../../assets/icons/card_on_table_hidden.svg";
import { ReactComponent as CardsIcon } from "../../../assets/icons/cards.svg";
import { ReactComponent as CardOnTable } from "../../../assets/icons/card_on_table.svg";
import { DeckPicker } from "./DeckPicker";
import { IconButton, Tooltip } from "@mui/material";
import { useMaps } from "../../map-hex/components/MapProvider";
import { cuni } from "../../../shared/services/cuni/cuni";
import {
  CharacterCardPanel,
  CharacterTabPanel,
  GameDeck,
} from "../../../model/Game";
import CancelIcon from "@mui/icons-material/Cancel";
import { cardUtils } from "../../../shared/utils/cardUtils";
import { useCards, useDecks } from "../../chat/hooks/useCards";
import FlipIcon from "@mui/icons-material/Flip";
import ShuffleIcon from "@mui/icons-material/Shuffle";
import { MapHexDrop } from "../../map-hex/MapHexDrop";
import ShuffleOnIcon from "@mui/icons-material/ShuffleOn";
import { useGame } from "../../character/hooks/useGame";
import { getGameDbList } from "../../tokens/hooks/useGameDb";
import {
  mapGameDbAsPanel,
  updatePanel,
  useGamePanels,
} from "../../tokens/hooks/useGameDbPanels";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import { Conditional } from "../../../shared/components/logic/Conditional";
import { useTranslation } from "react-i18next";
import { uuid } from "../../../shared/utils/uuid";
import { CardLookThrough } from "./CardLookThrough";
import { CardInsert } from "./CardInsert";
import { useUser } from "../../auth/useUser";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import GpsNotFixedIcon from "@mui/icons-material/GpsNotFixed";
import { getBottomZIndex } from "../../dices/use-map-drag";
import { usePutOnTable } from "../../board/use-put-on-table";
import { MultiplePickDialog } from "../../character/components/generic/components/MultiplePickDialog";
import { useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { ReactComponent as CardAddIcon } from "../../../assets/icons/card_add.svg";
import { ReactComponent as CardRemoveIcon } from "../../../assets/icons/card_remove.svg";
import { CardTakeFromDeck } from "./CardTakeFromDeck";
import i18next from "i18next";

export interface DeckPlaceContainerProps {
  deck: MapCardPlace;
}
export const DeckPlaceContainer = (props: DeckPlaceContainerProps) => {
  const { deck: deckPlace } = props;

  const decks = useDecks();
  const { t } = useTranslation();

  const [{ isDragging: isDraggingSingle }, dragRef] = useDrag(
    () => ({
      type: "TOKEN-CARD",
      item: (() => {
        const drag: MapHexDrop = {
          deck: deckPlace,
          type: "FROM_TABLE",
        };
        return drag;
      })(),
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0 : 1,
        isDragging: monitor.isDragging(),
      }),
    }),
    [deckPlace],
  );

  // you finished here...
  const [{ isDragging: isDraggingSingleReverse }, reverseDragRef] = useDrag(
    () => ({
      type: "TOKEN-CARD",
      item: (() => {
        const drag: MapHexDrop = {
          deck: deckPlace,
          type: "FROM_TABLE",
          flipped: true,
        };
        return drag;
      })(),
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0 : 1,
        isDragging: monitor.isDragging(),
      }),
    }),
    [deckPlace],
  );

  const [{ isDragging: isDraggingMultiple }, multipleDragRef] = useDrag(
    () => ({
      type: "TOKEN-CARD",
      item: (() => {
        const drag: MapHexDrop = {
          deck: deckPlace,
          type: "FROM_TABLE",
          multiple: true,
        };
        return drag;
      })(),
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0 : 1,
        isDragging: monitor.isDragging(),
      }),
    }),
    [deckPlace],
  );

  const isDragging =
    isDraggingMultiple || isDraggingSingle || isDraggingSingleReverse;
  const { all, current, addDiscardPile } = useMaps();
  const cards = useCards();

  const onDeckChange = (deck: GameDeck) => {
    if (!current) return;
    const dbItem = current.deckPlaces.find((x) => x.key === deckPlace.key);
    if (!dbItem) return;
    dbItem.deckKey = deck.key;
    // TODO: it should clean UP like shuffle ALL
    const newDeck = decks.find((x) => x.key === deckPlace.deckKey);
    dbItem.cards = newDeck?.subset
      ? cardUtils.shuffle(newDeck.subset)
      : cardUtils.generate(cards.filter((x) => x.deckKey === deck.key));
    cuni.object.update("maps", all, dbItem);
    cuni.log(i18next.t("LOG.DECK_CHANGE"), {
      type: "OnHand",
      deckInstanceKey: dbItem.key,
    });
  };

  const deleteDeckPlace = () => {
    if (!current) return;
    const toRemove = current.deckPlaces.find((x) => x.key === deckPlace.key);
    cuni.object.remove("maps", all, toRemove);
  };

  const takeFromTable = () => {
    if (!current) throw new Error("NO current map");
    const discardPile = current.deckPlaces.find(
      (x) => x.subTypeDeckInstanceKey === deckPlace.key,
    );
    const cardIntances: { key: string; cardKey: string }[] = current.deckPlaces
      .filter(
        (x) =>
          (x.deckFlyweightKey === deckPlace.key ||
            x.deckFlyweightKey === discardPile?.key) &&
          x.type === "card",
      )
      .map((x) => ({
        key: x.key,
        cardKey: x.card?.key as string,
      }))
      .filter((x) => x.cardKey);
    const deckCardInstanceKeys: string[] = cardIntances.map((x) => x.key);
    current.deckPlaces = current.deckPlaces.filter(
      (x) => !deckCardInstanceKeys.includes(x.key || ""),
    );
    return cardIntances;
  };

  const flipCard = (e: any) => {
    e.stopPropagation();
    if (!current) return;
    const currentPlace = current.deckPlaces.find(
      (x) => x.key === deckPlace.key,
    );
    if (!currentPlace) return;
    // TODO: think if boolean can be update by it own
    currentPlace.flipped = !currentPlace.flipped;
    cuni.object.update("maps", all, currentPlace);
    cuni.log(i18next.t("LOG.CARD_FLIPPED"), {
      type: "OnHand",
      cardKeyList: [currentPlace.card!.key!],
    });
  };

  const deckShuffle = () => {
    if (!current) return;

    const currentPlace = current.deckPlaces.find(
      (x) => x.key === deckPlace.key,
    );
    if (!currentPlace) return;

    const discardPile = current.deckPlaces.find(
      (x) => x.subTypeDeckInstanceKey === deckPlace.key,
    );
    if (deckPlace.deckKey || discardPile) {
      // if discard pile exist shuffle just discard pile
      if (discardPile) {
        currentPlace.cards = cardUtils.shuffle([
          ...currentPlace.cards,
          ...discardPile.cards,
        ]);
        discardPile.cards = [];
      } else {
        // Take from table should be separate action
        const cardIntances = takeFromTable();
        currentPlace.cards = cardUtils.shuffle([
          ...currentPlace.cards,
          ...cardIntances.map((x) => x.cardKey),
        ]);
      }
    } else {
      currentPlace.cards = cardUtils.shuffle(currentPlace.cards);
    }
    cuni.object.update("maps", all, current.deckPlaces);
    cuni.log(i18next.t("LOG.BOARD_SHUFFLED"), {
      type: "OnHand",
      deckInstanceKey: deckPlace.key,
    });
  };

  const { game } = useGame();
  const deckShuffleAll = () => {
    if (!current) return;
    const currentPlace = current.deckPlaces.find(
      (x) => x.key === deckPlace.key,
    );
    if (!currentPlace) return;

    const discardPile = current.deckPlaces.find(
      (x) => x.subTypeDeckInstanceKey === deckPlace.key,
    );
    // clean discard pile
    if (discardPile) {
      discardPile.cards = [];
    }
    // 1. Find all cards in all hands
    // 2. Remove cards from hands
    const { list: dbList } = getGameDbList(game);
    dbList
      .map(mapGameDbAsPanel)
      .flatMap((x) => x)
      .forEach((panel) => {
        if (panel.__type !== "card") return;

        updatePanel(panel.key, (state: CharacterCardPanel) => {
          state.cards = state.cards.filter(
            (x) =>
              x.deckFlyweightKey !== deckPlace.key &&
              x.deckFlyweightKey !== discardPile?.key,
          );
          return state;
        });
      });

    // 3. create clean deck and shuffle
    takeFromTable();

    const deck = decks.find((x) => x.key === deckPlace.deckKey);

    currentPlace.cards = deck?.subset
      ? cardUtils.shuffle(deck.subset)
      : cardUtils.generate(
          cards.filter((x) => x.deckKey === currentPlace?.deckKey),
        );
    cuni.object.update("maps", all, current.deckPlaces);
    cuni.log(i18next.t("LOG.DECK_ALL_SHUFFLED"), {
      type: "OnHand",
      deckInstanceKey: deckPlace.key,
    });
  };

  const rotate = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (!current) return;
    const currentPlace = current.deckPlaces.find(
      (x) => x.key === deckPlace.key,
    );
    if (!currentPlace) return;
    currentPlace.rotate = (currentPlace.rotate || 0) + (90 % 360);
    cuni.object.update("maps", all, currentPlace);
    cuni.log(i18next.t("LOG.OBJECT_ROTATED"), {
      type: "OnHand",
      deckInstanceKey: deckPlace.key,
    });
  };

  const fixed = () => {
    if (!current) return;
    const currentPlace = current.deckPlaces.find(
      (x) => x.key === deckPlace.key,
    );
    if (!currentPlace) return;
    currentPlace.fixed = !currentPlace.fixed;
    cuni.object.update("maps", all, currentPlace);
    cuni.log(i18next.t("LOG.OBJECT_FIXED"), {
      type: "OnHand",
      deckInstanceKey: deckPlace.key,
    });
  };

  const bottom = () => {
    if (!current) return;
    const currentPlace = current.deckPlaces.find(
      (x) => x.key === deckPlace.key,
    );
    if (!currentPlace) return;
    currentPlace.position ??= { x: 0, y: 0, zIndex: 200 };
    currentPlace.position.zIndex = getBottomZIndex(current) - 1;
    // TODO: add translations
    // TODO: add game ROUND indicator
    cuni.object.update("maps", all, currentPlace.position);
    cuni.log(i18next.t("LOG.OBJECT_BOTTOM"), {
      type: "OnHand",
      deckInstanceKey: deckPlace.key,
    });
  };

  const { dropOnTable } = usePutOnTable();

  const onTable = (e: any, flipped?: boolean) => {
    e.preventDefault();
    e.stopPropagation();
    const drop: MapHexDrop = {
      deck: deckPlace,
      type: "FROM_TABLE",
      flipped,
    };
    dropOnTable(drop);
  };

  const user = useUser();
  const panels = useGamePanels(user?.id);

  const pickClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();

    const cardPanel = panels.find((x) => x.panel.__type === "card");
    if (!cardPanel) return;
    const card = cardUtils.pickFromDeck(game, deckPlace.key);
    updatePanel(cardPanel.panel.key, (state: CharacterCardPanel) => {
      state.cards.push({
        key: uuid(),
        cardKey: card!,
        deckFlyweightKey: deckPlace.deckFlyweightKey ?? deckPlace.key,
      });
      return state;
    });

    cuni.log(i18next.t("LOG.ON_HAND"), {
      type: "OnHand",
      cardKeyList: [card!],
      deckInstanceKey: deckPlace.deckFlyweightKey ?? deckPlace.key,
    });
  };
  const [multipleOpen, setMultipleOpen] = useState<MapCardPlace | null>(null);
  const [panel, setPanel] = useState<CharacterTabPanel | null>(null);

  const pickMultipleClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    e.stopPropagation();
    const cardPanel = panels.find((x) => x.panel.__type === "card");
    if (!cardPanel) return;
    setMultipleOpen(deckPlace);
    setPanel(cardPanel.panel);
  };

  const currentPlace = current?.deckPlaces.find((x) => x.key === deckPlace.key);

  const handleAddDiscardPile = () => {
    if (!currentPlace) return;

    addDiscardPile(currentPlace.key);

    cuni.log(i18next.t("LOG.DISCARD_PILE_ADDED"), {
      type: "OnHand",
      deckInstanceKey: currentPlace.key,
    });
  };

  const moveToDiscardPile = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    e.stopPropagation();

    let discardPile = current?.deckPlaces.find(
      // find discard Pile of original
      (x) => x.subTypeDeckInstanceKey === deckPlace.deckFlyweightKey,
    );

    // TODO: droping card from discard card should put deckFlyweightKey of subTypeDeckInstanceKey not of himself ?
    if (!discardPile) {
      discardPile = current?.deckPlaces.find(
        (x) =>
          // or find discard Pile as it's my deck now
          x.key === deckPlace.deckFlyweightKey,
      );
    }

    if (!discardPile) {
      console.error("Cannot find discarpile");
      return;
    }
    const card = cardUtils.pickFromDeck(game, deckPlace.key);

    discardPile.cards ??= [];
    cuni.array.push("maps", all, discardPile.cards, card);

    cuni.log(i18next.t("LOG.ON_DISCARD"), {
      type: "OnHand",
      cardKeyList: [card!],
      deckInstanceKey: discardPile.key,
    });
  };

  const { originalDeck } = useDeckProperties(deckPlace);

  return (
    <>
      <Conditional
        condition={panel}
        success={(p) => (
          <MultiplePickDialog
            panelKey={p.key}
            mapPlace={multipleOpen}
            close={() => setMultipleOpen(null)}
          />
        )}
      />

      <Popover
        forceClose={isDragging}
        renderButton={(onClick) => (
          <DeckPlace
            deck={deckPlace}
            onClick={onClick}
            actions={
              !originalDeck?.actionHidden ? (
                <div className="paper block-map disable-map deck-place-in-action">
                  <Tooltip title={t("CARD.PICK")}>
                    <IconButton
                      size="small"
                      ref={dragRef}
                      onClick={pickClick}
                      className="block-map disable-map no-events"
                    >
                      <CardIcon style={{ width: 24, height: 24 }} />
                    </IconButton>
                  </Tooltip>
                  <Conditional condition={deckPlace.type === "deck"}>
                    <Tooltip title={t("CARD.ON_TABLE")}>
                      <IconButton
                        size="small"
                        className="block-map disable-map no-events"
                        onClick={onTable}
                      >
                        <CardOnTable />
                      </IconButton>
                    </Tooltip>
                  </Conditional>
                  <Conditional condition={deckPlace.type === "card"}>
                    <>
                      <Tooltip title={t("CARD.TO_DISCARD_PILE")}>
                        <IconButton
                          className="block-map disable-map no-events"
                          size="small"
                          onClick={moveToDiscardPile}
                        >
                          <CardRemoveIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                  </Conditional>
                  <Tooltip title={t("CARD.ROTATE")}>
                    <IconButton
                      className="block-map disable-map no-events"
                      size="small"
                      onClick={rotate}
                    >
                      <RotateLeftIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t("CARD.MORE")}>
                    <IconButton
                      className="block-map disable-map no-events"
                      size="small"
                      onClick={onClick}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              ) : undefined
            }
          />
        )}
      >
        <div className="deck-place-container">
          <Tooltip title={t("CARD.PICK")}>
            <IconButton ref={dragRef} onClick={pickClick}>
              <CardIcon style={{ width: 24, height: 24 }} />
            </IconButton>
          </Tooltip>

          <Conditional condition={deckPlace.type === "deck"}>
            <>
              <Tooltip title={t("CARD.PICK_REVERSE")}>
                <IconButton
                  ref={reverseDragRef}
                  onClick={(e) => onTable(e, true)}
                >
                  <CardOnTableHidden style={{ width: 24, height: 24 }} />
                </IconButton>
              </Tooltip>
              <Tooltip title={t("CARD.PICK_MULTI")}>
                <IconButton ref={multipleDragRef} onClick={pickMultipleClick}>
                  <CardsIcon style={{ width: 24, height: 24 }} />
                </IconButton>
              </Tooltip>
              <CardTakeFromDeck deckId={deckPlace.key} />
              <CardLookThrough deckId={deckPlace.key} />
              <CardInsert deckId={deckPlace.key} />
              <DeckPicker onChange={onDeckChange} />
              <Tooltip title={t("CARD.SHUFFLE_CURRENT")}>
                <IconButton onClick={deckShuffle}>
                  <ShuffleIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={t("CARD.SHUFFLE_ALL")}>
                <IconButton onClick={deckShuffleAll}>
                  <ShuffleOnIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={t("CARD.REMOVED_DECK")}>
                <IconButton onClick={handleAddDiscardPile}>
                  <CardAddIcon />
                </IconButton>
              </Tooltip>
            </>
          </Conditional>
          <Conditional condition={deckPlace.type === "card"}>
            <>
              <Tooltip title={t("CARD.TO_DISCARD_PILE")}>
                <IconButton size="small" onClick={moveToDiscardPile}>
                  <CardRemoveIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={t("CARD.FLIP")}>
                <IconButton onClick={flipCard}>
                  <FlipIcon />
                </IconButton>
              </Tooltip>
            </>
          </Conditional>
          <Tooltip title={t("CARD.ROTATE")}>
            <IconButton onClick={rotate}>
              <RotateLeftIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("CARD.FIXED")}>
            <IconButton onClick={fixed}>
              <Conditional
                condition={currentPlace?.fixed}
                success={() => <GpsFixedIcon />}
                failure={() => <GpsNotFixedIcon />}
              />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("CARD.BOTTOM")}>
            <IconButton onClick={bottom}>
              <VerticalAlignBottomIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title={t("CARD.DELETE")}>
            <IconButton onClick={deleteDeckPlace}>
              <CancelIcon color="error" />
            </IconButton>
          </Tooltip>
        </div>
      </Popover>
    </>
  );
};
