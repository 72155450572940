import React, { PropsWithChildren } from "react";
import { Conditional } from "../../shared/components/logic/Conditional";
import { useAuth } from "./useAuth";
import { Navigate } from "react-router-dom";

export const ProtectedRoute: React.FC<PropsWithChildren> = (
  props: PropsWithChildren,
) => {
  const auth = useAuth();
  return (
    <Conditional
      condition={auth.token}
      success={() => <>{props.children}</>}
      failure={() => <Navigate replace to="login" />}
    />
  );
};
