import { GameCard } from "../../model/Game";
import HoverZoom from "../../shared/components/logic/HoverZoom";
import { clsx } from "../../shared/utils/clsx";
import { useDecks } from "../chat/hooks/useCards";

interface CardViewProps {
  name?: string;
  avatarUrl?: string;
  isDragging?: boolean;
  dragRef?: any;
  uiType?: GameCard["uiType"];
  color?: string;
  textColor?: string;
  onDoubleClick?: () => void;
  onClick?: () => void;
  deckKey?: string;
  size?: number;
  sizeY?: number;
  actions?: JSX.Element;
}

export const CardView = (props: CardViewProps) => {
  const {
    isDragging,
    name,
    avatarUrl,
    dragRef,
    uiType,
    color,
    textColor,
    onDoubleClick,
    onClick,
    actions,
  } = props;

  const decks = useDecks();
  const deck = decks.find((x) => x.key === props.deckKey);

  const size = props.size ?? deck?.size ?? undefined;
  const width = size || 150;
  const height =
    props.sizeY ??
    deck?.sizeY ??
    (["CARD", undefined].includes(uiType) ? width * 1.5 : width);
  return (
    // <HoverZoom zoomScale={4} delay={1000}>
    <div
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      ref={dragRef}
      className={clsx("block-map", "disable-map", "card-view", {
        [`uiType-${uiType}`]: !!uiType,
      })}
      style={{
        backgroundColor: color,
        color: textColor,
        borderColor: isDragging ? "gray" : "white",
        backgroundImage: `url(${avatarUrl})`,
        height,
        width,
      }}
    >
      {actions}
      {avatarUrl ? "" : name}
    </div>
    // </HoverZoom>
  );
};
