import { useQuery } from "@tanstack/react-query";
import { apiClient } from "../../../../shared/services/apiClient";
import { UserTextMessage } from "../../../../model/WebHookMessage";
import { config } from "../../../../config";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Tabs, Tab } from "@mui/material";
import { useGamePermission } from "../../../../shared/hooks/useGamePermission";
import { useGame } from "../../../character/hooks/useGame";
import { useUser } from "../../../auth/useUser";
import { orderByCreated, webHookStore } from "../../../../store/WebHookStore";

interface ChatSessionsProps {
  scrollRef: React.RefObject<HTMLDivElement>;
  loaderRef: React.RefObject<HTMLDivElement>;
}

const perPage = 100;

export const ChatSessions = (props: ChatSessionsProps) => {
  const { scrollRef, loaderRef } = props;
  const { data } = useMessageQuery();
  const [value, setValue] = useState(0);
  const { hasGamePermission } = useGamePermission();
  const { game } = useGame();
  const user = useUser();
  const page = useRef(1);
  const shouldFetch = useRef(true);
  const sorted = useMemo(() => data?.sort(orderByCreated), [data]);
  const updateMessages = useCallback(
    (start?: string, end?: string) => {
      // skip query if no data
      if (!shouldFetch.current) return;
      if (!user) return;
      if (loaderRef.current) loaderRef.current.style.display = "block";
      apiClient
        .get<UserTextMessage[]>(
          `${config.baseUrl}/api/message?Where=Type == "USER_TEXT"
        &Where=GameId == ${game.id}
        &Where= ReceiverId == ${user.id} or SenderId == ${
            user.id
          } or ReceiverId == null
        ${
          start
            ? `&Where= Created >= DateTimeOffset.Parse("${encodeURIComponent(
                start,
              )}")`
            : ""
        }
        ${
          end
            ? `&Where= Created ${encodeURIComponent(
                "<",
              )} DateTimeOffset.Parse("${encodeURIComponent(end)}")`
            : ""
        }
        &Take=${perPage}
        &Skip=${page.current * perPage}
        `,
        )
        .then((res) => res.data)
        .then((data) => {
          shouldFetch.current = data?.length === perPage;
          if (page.current === 0)
            webHookStore.setInitial(data.sort(orderByCreated));
          else {
            webHookStore.pushMore(data);
          }
          page.current++;
          const scrollHeightBefore = scrollRef.current?.scrollHeight || 0;
          setTimeout(() => {
            if (scrollRef.current) {
              const scrollHeightAfter = scrollRef.current?.scrollHeight;
              scrollRef.current.scrollTop =
                scrollHeightAfter - scrollHeightBefore;
            }
            if (loaderRef.current) loaderRef.current.style.display = "none";
          }, 0);
        });
    },
    [game.id, loaderRef, scrollRef, user],
  );

  const pageNumber = page.current;
  useEffect(() => {
    if (scrollRef && scrollRef.current && pageNumber <= 1) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [pageNumber, scrollRef]);

  useEffect(() => {
    const scrollElement = scrollRef.current;
    if (!scrollElement) return;

    const handleScroll = () => {
      const top = scrollElement.scrollTop;
      if (top < 50) {
        const startSession = value === 0 ? undefined : sorted?.[value - 1];
        const endSession = value === 0 ? undefined : sorted?.[value];
        updateMessages(startSession?.created, endSession?.created);
      }
    };
    scrollElement.addEventListener("scrollend", handleScroll);
    return () => scrollElement?.removeEventListener("scrollend", handleScroll);
  }, [value, sorted, updateMessages, scrollRef]);

  if (!hasGamePermission("canStartSession") || !game.root.showChapters)
    return null;
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);

    shouldFetch.current = true;
    page.current = newValue === 0 ? 1 : 0;

    const startSession = sorted?.[newValue - 1];
    const endSession = sorted?.[newValue];
    updateMessages(startSession?.created, endSession?.created);
  };

  return (
    <div className="paper paper__tab chat__panel">
      <Tabs
        value={value}
        onChange={handleChange}
        scrollButtons="auto"
        variant="scrollable"
      >
        <Tab key={-1} label={"All"} />
        {sorted?.map((x) => (
          <Tab key={x.id} label={x.message} />
        ))}
      </Tabs>
    </div>
  );
};

export const useMessageQuery = () => {
  return useQuery({
    queryKey: ["message-all"],
    queryFn: () =>
      apiClient
        .get<UserTextMessage[]>(
          `${config.baseUrl}/api/message?Where=SubType == "NEW_SESSION"`,
        )
        .then((res) => res.data),
  });
};
