export function flattenDict(dictToFlatten) {
  function flatten(dict, parent) {
    var keys = [];
    var values = [];

    for (var key in dict) {
      if (typeof dict[key] === "object") {
        var result = flatten(dict[key], parent ? parent + "." + key : key);
        keys = keys.concat(result.keys);
        values = values.concat(result.values);
      } else {
        keys.push(parent ? parent + "." + key : key);
        values.push(dict[key]);
      }
    }

    return {
      keys: keys,
      values: values,
    };
  }

  var result = flatten(dictToFlatten);
  var flatDict = {};

  for (var i = 0, end = result.keys.length; i < end; i++) {
    flatDict[result.keys[i]] = result.values[i];
  }

  return flatDict;
}

export function getPropertyByPath(obj, path) {
  // Split the path into an array using '.' as the delimiter
  const keys = path.split(".");

  // Iterate through the keys to get to the desired property
  return keys.reduce((current, key) => {
    // Check if the current value is an object and contains the key
    if (current && current.hasOwnProperty(key)) {
      return current[key];
    } else {
      return undefined; // If the key doesn't exist, return undefined
    }
  }, obj); // Start with the original object
}


export function cutLastNParts(path, n) {
  // Split the path into an array using '.' as the delimiter
  const parts = path.split('.');

  // Ensure N doesn't exceed the length of the array
  if (n > parts.length) {
    return ''; // If N is greater than the number of parts, return an empty string
  }

  // Slice the array to remove the last N parts
  const newParts = parts.slice(0, -n);

  // Join the remaining parts back into a string
  return newParts.join('.');
}

export function getLastPart(path) {
  // Split the path into an array using '.' as the delimiter
  const parts = path.split('.');

  // Return the last element in the array
  return parts[parts.length - 1];
}