import { Button } from "@mui/material";
import { useGame } from "../../../character/hooks/useGame";
import React from "react";
import { useFormContext } from "react-hook-form";
import { ActionFormValues } from "../ActionForm";
const truncate = (str: string) =>
  str && str.length > 10 ? str.slice(0, 10) + "..." : str;

export const CharacterNamesSelect = () => {
  const { game } = useGame();
  const form = useFormContext<ActionFormValues>();
  const currentName = form.watch("currentName");

  return (
    <React.Fragment>
      {game.root.charactersNames?.map((name) => (
        <Button
          key={name}
          variant={currentName === name ? "outlined" : "text"}
          onClick={() => {
            if (currentName === name) form.setValue("currentName", undefined);
            else form.setValue("currentName", name);
          }}
        >
          {truncate(name)}
        </Button>
      ))}
    </React.Fragment>
  );
};
