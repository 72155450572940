import { IconButton, Popover } from "@mui/material";
import { clsx } from "../../../shared/utils/clsx";
import "./Hex.css";
import { useHexActions } from "./HexActionProvider";
import React, { useRef, useState } from "react";
import { useMaps } from "./MapProvider";
import CancelIcon from "@mui/icons-material/Cancel";
import { useGamePermission } from "../../../shared/hooks/useGamePermission";
import { Conditional } from "../../../shared/components/logic/Conditional";
import { HexRowProps } from "./types";
import { HexProps } from "./types";
import { IconToken } from "./IconToken";

export const Square = (props: HexProps) => {
  const { item, hasBackground } = props;
  const anchorEl = useRef<HTMLButtonElement>();
  const [currentToken, setToken] = useState("");

  const { updateHex } = useMaps();
  const { hasGamePermission } = useGamePermission();
  const { open } = useHexActions();
  const color = item.exist ? undefined : item.color;
  return (
    <div
      className={clsx("square", {
        exist: item.exist,
        empty: !item.exist,
      })}
      onClick={(e) => {
        if (!hasGamePermission("canEditMap")) return;
        open(e.currentTarget, item.id);
      }}
    >
      <div
        style={{
          background: color,
          opacity: hasBackground ? 0.45 : 1,
        }}
        className="square-middle"
      >
        <Popover
          open={Boolean(currentToken)}
          onClose={() => setToken("")}
          anchorEl={anchorEl?.current}
        >
          <IconButton
            color="error"
            onClick={(e) => {
              e.stopPropagation();
              updateHex(item.id, {
                tokens: item.tokens?.filter((x) => x !== currentToken),
              });
              setToken("");
            }}
          >
            <CancelIcon />
          </IconButton>
        </Popover>
        <Conditional condition={!item.exist}>
          <React.Fragment>
            {item.tokens?.map((token) => (
              <IconToken
                key={token}
                token={token}
                item={item}
                onClick={(e) => {
                  e.stopPropagation();
                  anchorEl.current = e.currentTarget;
                  setToken(token);
                }}
              />
            ))}
          </React.Fragment>
        </Conditional>
      </div>
    </div>
  );
};

export const SquareRow = (props: HexRowProps) => {
  return (
    <div className="square-row">
      {props.row.map((item, idx) => (
        <Square even={idx % 2 === 0} item={item} {...props} />
      ))}
    </div>
  );
};
