import { useQuery } from "@tanstack/react-query";
import { apiClient } from "../shared/services/apiClient";
import { config } from "../config";
import { LicensePlanData, LicensePlanType } from "../features/auth/AuthContext";
import { Box, Button, Typography } from "@mui/material";
import { Conditional } from "../shared/components/logic/Conditional";
import { useTranslation } from "react-i18next";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

// todo:
// ilość przyjaciół moze byc zwiększona
// transfer GM

function groupBy<T extends Record<string, any>>(
  array: T[],
  key: keyof T,
): Record<string, T[]> {
  return array.reduce((result, item) => {
    const groupKey = String(item[key]); // Convert to string to use as an object key
    if (!result[groupKey]) {
      result[groupKey] = [];
    }
    result[groupKey].push(item);
    return result;
  }, {} as Record<string, T[]>);
}

export const PaymentsPage = () => {
  const { t } = useTranslation();
  const { data } = usePaymentTypes();

  const payFor = (plan: LicensePlanData) => {
    window.location.replace("https://buycoffee.to/cuni");
  };
  if (!data) return <></>;
  const groups = groupBy(data, "name");
  const possibleProducts: LicensePlanType[] = ["Basic", "Standard", "Premium"];
  return (
    <div className="page ">
      <div
        className="paper"
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem",
          gap: "2rem",
        }}
      >
        <Box>
          <Typography variant="h4"> {t("welcomeMessage")}</Typography>
          <Typography className="payments-text">
            {t("supportMessage")}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" width="300px">
          <Button
            variant="contained"
            href="https://buycoffee.to/cuni"
            target="_blank"
          >
            {t("buyCoffee")}
          </Button>
        </Box>
      </div>

      <div className="payments">
        {Object.entries(groups)
          ?.filter((x) => possibleProducts.includes(x[0] as LicensePlanType))
          .map(([name, value]) => (
            <div key={name} className="paper">
              <div className="payments-item">
                <Typography variant="h4">{name}</Typography>
                <Typography className="payments-text">
                  <CheckCircleIcon />
                  {t("PAYMENTS.SIZE", { count: value[0].size / (1024 * 1024) })}
                </Typography>
                <Typography className="payments-text">
                  <CheckCircleIcon />
                  {t("PAYMENTS.GAMES", { count: value[0].gameCount })}
                </Typography>
                <Typography className="payments-text">
                  <CheckCircleIcon />
                  {t("PAYMENTS.PLAYERS", { count: value[0].playersCount })}
                </Typography>
                <Conditional
                  condition={name === "Standard" || name === "Premium"}
                >
                  <Typography className="payments-text">
                    <CheckCircleIcon />
                    {t("PAYMENTS.TEMPLATE")}
                  </Typography>
                </Conditional>
                <Conditional condition={name === "Premium"}>
                  <>
                    <Typography className="payments-text">
                      <CheckCircleIcon />
                      {t("PAYMENTS.LAYOUT")}
                    </Typography>
                    <Typography className="payments-text">
                      <CheckCircleIcon />
                      {t("PAYMENTS.MUSIC")}
                    </Typography>
                    <Typography className="payments-text">
                      <CheckCircleIcon />
                      {t("PAYMENTS.AVATARS")}
                    </Typography>
                    <Typography className="payments-text">
                      <CheckCircleIcon />
                      {t("PAYMENTS.RPG")}
                    </Typography>
                    <Typography className="payments-text">
                      <CheckCircleIcon />
                      {t("PAYMENTS.EXPORT")}
                    </Typography>
                  </>
                </Conditional>
              </div>

              <div className="payments-item">
                <Conditional condition={value[0]?.price}>
                  <Button
                    disabled
                    variant="outlined"
                    onClick={() => payFor(value[0])}
                  >
                    {t("PAYMENTS.MONTHLY")} {value[0]?.price} PLN
                  </Button>
                </Conditional>
                <Conditional condition={value[1]?.price}>
                  <Button
                    disabled
                    variant="contained"
                    onClick={() => payFor(value[1])}
                  >
                    {t("PAYMENTS.ANNUALY")} {value[1]?.price} PLN
                  </Button>
                </Conditional>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export const usePaymentTypes = () => {
  return useQuery({
    queryKey: ["payments-data"],
    queryFn: () =>
      apiClient
        .get<LicensePlanData[]>(`${config.baseUrl}/payments/license/`)
        .then((res) => res.data),
  });
};
