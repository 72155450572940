import { Button, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { ModalProps } from "../../../../shared/components/modal/ModalProps";
import { useForm, useFormContext } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { StoryNote } from "../../../../model/StoryNote";
import { config } from "../../../../config";
import { apiClient } from "../../../../shared/services/apiClient";
import { cuni } from "../../../../shared/services/cuni/cuni";
import { WebHookType, WebHookSubType } from "../../../../model/WebHookMessage";
import { ActionFormValues } from "../ActionForm";
import { useGame } from "../../../character/hooks/useGame";
import { useTranslation } from "react-i18next";

type StoryNoteModalProps = ModalProps;

interface StoryNoteForm {
  note: string;
}

export const StoryNoteModal = (props: StoryNoteModalProps) => {
  const { open, handleClose } = props;
  const { t } = useTranslation();

  const { watch } = useFormContext<ActionFormValues>();

  const { register, handleSubmit, reset } = useForm<StoryNoteForm>({
    defaultValues: {
      note: "",
    },
  });

  const { game } = useGame();
  const onClose = () => {
    reset();
    handleClose();
  };

  const { mutate } = useStoryNotePost();

  const onSubmit = (form: StoryNoteForm) => {
    const dto: StoryNote = {
      ...form,
      receiverId: watch("receiverId"),
      gameId: game.id,
    };

    mutate(dto, {
      onSuccess(data, variables, context) {
        cuni.pushMessage((game) => {
          return {
            type: WebHookType.UserText,
            color: "gray",
            subType: "STORY" as WebHookSubType,
            user: `Story`,
            receiverId: watch("receiverId"),
            message: form.note,
            contextObjectId: data.data.id,
          };
        });
      },
      // TODO: on Error
    });
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box className="modal">
          <Typography variant="h6" component="h2">
            {t("STORY.NOTE")}
          </Typography>
          <Typography id="modal-avatar-description" sx={{ mt: 2 }}>
            {t("STORY.DESC")}
          </Typography>
          <TextField multiline label="Note" {...register("note")} />
          <Button type="submit" variant="contained">
            {t("COMMON.SEND")}
          </Button>
          <Button onClick={onClose}> {t("COMMON.CLOSE")}</Button>
        </Box>
      </form>
    </Modal>
  );
};

export const useStoryNotePost = () => {
  return useMutation({
    mutationFn: (data: StoryNote) =>
      apiClient.post<StoryNote>(`${config.baseUrl}/api/storynote`, data),
  });
};
