import { action, makeObservable, observable, runInAction } from "mobx";
import { config } from "../config";
import { Game } from "../model/Game";
import { adapt } from "../shared/services/adapt";
import { apiClient } from "../shared/services/apiClient";
import { gameStorage } from "../shared/services/storage";
import { webHookStore } from "./WebHookStore";

export class RootStore {
  game: Game | null = null;

  constructor() {
    makeObservable(this, { game: observable, init: action, update: action });
  }

  async init() {
    // TODO: use id of the game
    // TODO: init admin vs init user
    const game = gameStorage.get<Game>();
    if (!game) throw new Error("there is no game saved");
    const result = await apiClient.get<Game>(
      `${config.baseUrl}/game/${game.id}`,
    );
    const localGame = adapt(result.data);
    gameStorage.set(localGame);
    webHookStore.setInitial(result.data.messages);
    runInAction(() => {
      this.game = localGame;
    });
  }

  update(game: Game) {
    runInAction(() => {
      this.game = {
        ...adapt(game),
        url: this.game?.url ?? null,
        sections: this.game?.sections ?? [],
      };
      gameStorage.set(this.game);
    });
  }

  setOnline(userOnlineIds: number[]) {
    runInAction(() => {
      this.game = { ...adapt(this.game!), userOnlineIds };
    });
  }
}

export const rootStore = new RootStore();
