import React from "react";

export type LicensePlanType =
  | "Free"
  | "Basic"
  | "Standard"
  | "Premium"
  | "Ultimate"
  | "Trial";

export interface AuthData {
  expiration: string;
  token: string;
  userId: number;
  email: string;
  userName: string;
  roles: string[];
  needCodeValidaton: boolean;
  licensePlan: LicensePlanType;
  licenseExpiredDate: string;
}

export interface RegisterDataInput {
  username: string;
  email: string;
  password: string;
}

export interface RegisterData extends AuthData {}

export interface IdentityError {
  code: string;
  description: string;
}

export interface IdentityResult {
  succeeded: boolean;
  errors: IdentityError[];
}

export interface AuthModel {
  data?: AuthData;
  setData: (data?: AuthData) => void;
}

export const AuthContext = React.createContext<AuthModel>({
  setData: () => {},
});

export interface LicensePlanData {
  name: LicensePlanType;
  size: number;
  gameCount: number;
  period: number;
  plan: number;
  price: number;
  playersCount: number;
}

export const PLAN_DATA_MAP: Record<LicensePlanType, LicensePlanData> = {
  Trial: { size: 1024 * 1024 * 2 } as LicensePlanData,
  Free: { size: 1024 * 1024 * 1 } as LicensePlanData,
  Basic: { size: 1024 * 1024 * 10 } as LicensePlanData,
  Standard: { size: 1024 * 1024 * 100 } as LicensePlanData,
  Premium: { size: 1024 * 1024 * 500 } as LicensePlanData,
  Ultimate: { size: 1024 * 1024 * 1000 } as LicensePlanData,
};
