import React from "react";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiClient } from "../../../shared/services/apiClient";
import { Project } from "../../../model/Project";
import { config } from "../../../config";

export const ProjectListActions = () => {
  const { t } = useTranslation();
  const { mutate } = useAddProject();
  const queryClient = useQueryClient();

  return (
    <React.Fragment>
      <Button
        sx={{
          height: "100%",
        }}
        variant="outlined"
        onClick={() =>
          mutate(
            {},
            {
              onSuccess: () => {
                queryClient.invalidateQueries({
                  queryKey: ["project-all"],
                  exact: true,
                });
              },
            },
          )
        }
        startIcon={<AddIcon />}
        size="large"
      >
        {t("PROJECT.CREATE")}
      </Button>
    </React.Fragment>
  );
};

export const useAddProject = () => {
  return useMutation({
    mutationFn: (data: Partial<Project>) =>
      apiClient.post<Project>(`${config.baseUrl}/api/project`, data),
  });
};
