export const config =
  process.env.NODE_ENV === "development"
    ? { baseUrl: "http://localhost:5288" }
    : {
        baseUrl: "https://cuni-api.hydrasoftware.pl",
      };

// {
// wsUrl: "https://uno-api.hydrasoftware.pl/ws/",
// baseUrl: "https://uno-api.hydrasoftware.pl/",
// };

export const isPreview = process.env.NODE_ENV !== "development";
