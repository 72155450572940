interface TransformData {
  x: number;
  y: number;
  scale: number;

  /**
   * Current Height of map.
   */
  height: number;
  /**
   * Current Width of map.
   */
  width: number;
}
const getTransformDataFromMapContainer = (): TransformData => {
  const mapContainer = document.querySelector(".map__container")?.parentElement;

  if (mapContainer) {
    const transform = mapContainer.style.transform;
    const translateMatch = transform.match(
      /translate\(([^,]+)px,\s*([^)]+)px\)/,
    );
    const scaleMatch = transform.match(/scale\(([^)]+)\)/);

    const x =
      translateMatch && translateMatch[1] ? parseFloat(translateMatch[1]) : 0;
    const y =
      translateMatch && translateMatch[2] ? parseFloat(translateMatch[2]) : 0;
    const scale = scaleMatch && scaleMatch[1] ? parseFloat(scaleMatch[1]) : 1;

    const { height, width } =
      mapContainer.parentElement!.getBoundingClientRect();
    return { x, y, scale, height, width };
  }

  return { x: 0, y: 0, scale: 1, width: 0, height: 0 }; // Default values if transform is not found
};

const calculateNewCoordinates = (
  markerX: number,
  markerY: number,
  transformData: TransformData,
): { x: number; y: number } => {
  const { x, y, scale } = transformData;

  // Apply the inverse of the scale and then the translation
  const newX = (markerX - x) / scale;
  const newY = (markerY - y) / scale;

  return { x: newX, y: newY };
};

export const mapUtils = {
  calculateNewCoordinates,
  getTransformDataFromMapContainer,
};
