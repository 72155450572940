import { AuthData } from "../../features/auth/AuthContext";
import { Game } from "../../model/Game";
import { authStorage } from "./storage";

export const adapt = (game: Game): Game => {
  if (game) {
    const auth = authStorage.get<AuthData>();
    const character = Object.entries(game.characters)
      .map((x) => x[1])
      .find((x) => x.__userId === auth?.userId);
    if (character) game.me = character;
  }
  return game ?? {};
};
