import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
  useState,
} from "react";
import { Character, CharacterTabPanel } from "../../../../model/Game";
import { mapCharacterToTab } from "../../../../model/utils/charaterUtils";

export const DEFAULT_PANEL: CharacterTabPanel = {
  title: "main",
  __type: null,
  __panels: null,
} as CharacterTabPanel;

const INITIAL_STATE = {
  tabId: 0,
  tab: DEFAULT_PANEL,
};

interface CharacterTab {
  tabId: number;
  tab: CharacterTabPanel;
  initialTab: CharacterTabPanel;
  setTab: React.Dispatch<
    React.SetStateAction<{
      tabId: number;
      tab: CharacterTabPanel;
    }>
  >;
  character: Character;
  skipTab?: boolean;
}

const CharacterTabContext = createContext<CharacterTab | null>(null);

interface CharacterTabProviderProps {
  character: Character;
  skipTab?: boolean;
}

export const CharacterTabProvider = (
  props: PropsWithChildren<CharacterTabProviderProps>,
) => {
  const { character, skipTab = false } = props;

  const initialState = useMemo(() => {
    return {
      ...INITIAL_STATE,
      tab: mapCharacterToTab(character),
    };
  }, [character]);

  const [{ tab, tabId }, setTab] = useState(initialState);
  return (
    <CharacterTabContext.Provider
      value={{
        tabId,
        tab,
        setTab,
        initialTab: initialState.tab,
        character,
        skipTab,
      }}
    >
      {props.children}
    </CharacterTabContext.Provider>
  );
};

export const useCharacterTabs = () => {
  const context = useContext(CharacterTabContext);
  if (!context) throw new Error("You need to use it in CharacterTabProvider");
  return context;
};
