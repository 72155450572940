export function useMergedRefs<T>(...refs: React.Ref<T>[]): React.RefCallback<T> {
  return (element: T) => {
    refs.forEach(ref => {
      if (typeof ref === 'function') {
        ref(element);
      } else if (ref != null) {
        // Assign the element to the ref's current property
        (ref as React.MutableRefObject<T | null>).current = element;
      }
    });
  };
}