import { useMutation } from "@tanstack/react-query";
import { config } from "../../../config";
import { Game } from "../../../model/Game";
import { apiClient } from "../../../shared/services/apiClient";

export const useDeleteCharacter = (game: Game) => {
  return useMutation({
    mutationFn: (data: number) =>
      apiClient.delete(`${config.baseUrl}/game/${game.id}/character/${data}`),
  });
};
