import { Button, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { ModalProps } from "../../../../../shared/components/modal/ModalProps";
import { useState } from "react";
import { CharacterTabPanel } from "../../../../../model/Game";
import { uuid } from "../../../../../shared/utils/uuid";

type NewTableModalProps = ModalProps & {
  addPanel: (panel: CharacterTabPanel) => void;
};

export const NewTableModal = (props: NewTableModalProps) => {
  const { open, handleClose, addPanel } = props;

  const [text, setText] = useState("");

  const submit = () => {
    const columns = text
      .split(",")
      .map((x) => x.trim())
      .map((x) => (x[0] ? `${x[0].toUpperCase()}${x.slice(1, x.length)}` : x))
      .map((x) => ({
        key: x.toLowerCase() || "title",
        title: x,
      }));

    addPanel({
      key: uuid(),
      __type: "table",
      title: "NEW_TABLE_PANEL",
      __panels: null,
      __columns: columns,
      __data: [],
    });
    handleClose();
  };
  // TODO: add validation for same properties
  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <Box className="modal">
        <Typography variant="h6" component="h2">
          Add Table
        </Typography>
        <Typography id="modal-avatar-description" sx={{ mt: 2 }}>
          Put columns you like to have in the table and split them by comma [,]
        </Typography>

        <TextField
          label="Columns"
          onChange={(e) => setText(e.target.value)}
          value={text}
        />

        <Button variant="contained" onClick={submit}>
          Confirm
        </Button>
        <Button onClick={handleClose}>Cancel</Button>
      </Box>
    </Modal>
  );
};
