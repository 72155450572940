import React from "react";

interface BaseConditionalProps<T> {
  condition?: T | null;
  failure?: () => React.ReactElement;
}

interface IConditionalProps<T> extends BaseConditionalProps<T> {
  success?: (condition: T) => React.ReactElement;
  children?: never;
}

interface IChildrenConditionalProps<T> extends BaseConditionalProps<T> {
  children: React.ReactElement;
  success?: never;
}
type ConditionalProps<T> = IConditionalProps<T> | IChildrenConditionalProps<T>;

export function Conditional<T = any>(props: ConditionalProps<T>) {
  const { condition, failure, success, children } = props;

  if (condition) {
    if (children) return children;
    if (success) return success(condition);
    throw new Error("This should never happened");
  } else if (failure) return failure();

  return <></>;
}
