import { Character, CharacterTabPanel } from "../Game";

export const mapCharacterToTab = (char: Character): CharacterTabPanel => {
  return {
    key: char.key.toString(),
    title: "main",
    __type: "generic",
    __panels: char.__panels,
    visible: true,
  };
};

export const getTabOrPanelIdentifier = (path: string): number => {
  const parts = path.split(".");
  const last = parts[parts.length - 1];
  const lastEl = parseInt(last);
  if (Number.isNaN(lastEl)) throw new Error("This should be number");

  return lastEl;
};

export const parseInputValue = (value: string): string | number => {
  const parsedValue = parseInt(value);
  return Number.isNaN(parsedValue) ? value : parsedValue;
};
