export const rollDice = (formula: string) => {
  const regex = /[0-9]?(k|K)([0-9]+)/gm;
  let copyFormula = `${formula}`;
  let m;

  let atLeastOnce = false;
  while ((m = regex.exec(formula)) !== null) {
    atLeastOnce = true;
    if (m.index === regex.lastIndex) {
      regex.lastIndex++;
    }

    const [multiplier, dice] = m[0].toLocaleLowerCase().split("k");
    const result = Math.floor(Math.random() * parseInt(dice) + 1);
    copyFormula = copyFormula.replace(
      m[0],
      `${parseInt(multiplier) || 1} * ${result}`,
    );
  }

  if (!atLeastOnce) throw new Error("Formula is wrong");
  // eslint-disable-next-line no-new-func
  return Function(`return ${copyFormula}`)();
};

export const canRoll = (input: string | number | null | undefined) => {
  if (!input) return false;
  let canRoll = false;
  try {
    rollDice(input.toString());
    canRoll = true;
  } catch {}

  return canRoll;
};
