import { ModalProps } from "../../../../../../../shared/components/modal/ModalProps";
import { useCharacterTabs } from "../../../CharacterTabProvider";
import { useCuni } from "../../../../../../../shared/services/cuni/useCuni";
import { AreYouSureModal } from "../../../../../../../shared/components/modal/AreYouSureModal";
import { parseInputValue } from "../../../../../../../model/utils/charaterUtils";

type NewPropertyModalProps = ModalProps & {
  path: string;
};

export const NewPropertyModal = (props: NewPropertyModalProps) => {
  const { open, handleClose, path } = props;
  const { character } = useCharacterTabs();

  const { cuni } = useCuni();

  // TODO: add validation for same properties
  const addPropertySubmit = (values?: Record<string, string>) => {
    if (!values) return;
    const name = values["name"];
    const value = values["value"];
    cuni.update(
      character.key,
      path ? `${path}.${name}` : name,
      parseInputValue(value)
    );
  };
  return (
    <AreYouSureModal
      title="Add Property"
      content={`There is no validation - watch out to not remove already exist one by
    putting same name.`}
      confirmAction={addPropertySubmit}
      open={open}
      handleClose={handleClose}
      fields={[
        {
          name: "name",
        },
        {
          name: "value",
        },
      ]}
    />
  );
};
