// TODO: fix typing
export const storage = (key: string) => {
  const get = <T>() => {
    const json = localStorage.getItem(key);
    if (json) return JSON.parse(json) as T;
    return null;
  };

  const set = (object: any) => {
    return localStorage.setItem(key, JSON.stringify(object));
  };

  const clear = () => {
    return localStorage.removeItem(key);
  };

  return { get, set, clear };
};

export const authStorage = storage("cuni__auth");
export const gameStorage = storage("cuni__game");
export const layoutStorage = storage("cuni__layout");
export const languageStorage = storage("cuni__lang");
export const musicStorage = storage("cuni__music");
