import { useGame } from "../../character/hooks/useGame";

export const useCards = () => {
  const { game } = useGame();
  return game.root.settings?.cards ?? [];
};

export const useDecks = () => {
  const { game } = useGame();
  return game.root.settings?.decks ?? [];
};

export const useCharacter = (input: { userId?: number }) => {
  const { game } = useGame();

  return Object.values(game.characters).find(
    (x) => x.__userId === input.userId,
  );
};
