import { IconButton } from "@mui/material";
import { clsx } from "../../../shared/utils/clsx";
import React from "react";
import { useGamePermission } from "../../../shared/hooks/useGamePermission";
import { useDrag } from "react-dnd";
import { MapItem } from "../../../model/Map";

export interface IconTokenProps {
  token: string;
  item: MapItem;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const IconToken = (props: IconTokenProps) => {
  const { token, item, onClick } = props;
  const { hasGamePermission } = useGamePermission();
  const [{ opacity }, dragRef] = useDrag(
    () => ({
      type: "TOKEN",
      item: { item, token },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.5 : 1,
      }),
    }),
    [],
  );

  return (
    <IconButton
      ref={dragRef}
      disabled={!hasGamePermission("canEditMap")}
      key={token}
      onClick={onClick}
      size="small"
      className="hex-token-button"
      style={{ opacity }}
    >
      <div className={clsx("disable-map", "ra", "hex-token", token)} />
    </IconButton>
  );
};
