import { useQuery } from "@tanstack/react-query";
import { config } from "../config";
import { User } from "../model/User";
import { apiClient } from "../shared/services/apiClient";
import { Box } from "@mui/material";
import { InvitationSender } from "../features/invitation/InvitationSender";
import { InvitationList } from "../features/invitation/InvitationList";
import { FriendsList } from "../features/invitation/FriendList";
import { Conditional } from "../shared/components/logic/Conditional";
import { GameInfo } from "../shared/components/ui/info/GameInfo";
import { useTranslation } from "react-i18next";
import SelectField from "../shared/components/logic/SelectField";
import i18next from "i18next";
import { languageStorage } from "../shared/services/storage";

const LanguageArray = [
  {
    key: "en",
    name: "English",
  },
  {
    key: "pl",
    name: "Polski",
  },
];
export const ProfilePage = () => {
  const { data } = useUserQuery();
  const { t } = useTranslation();

  // TODO fake quick edit modal provider
  // TODO: add invite friend
  // TODO: add friends list (MOVE TO FEATUER)
  return (
    <Box
      className="page"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Box minWidth="min(400px, 90vw)" className="column">
        <GameInfo full={false}>{t("PROFILE.TIP")}</GameInfo>
        <Conditional condition={data}>
          <Box className="paper" display="grid" gridTemplateColumns="1fr 1fr">
            <Box>{t("LOGIN.EMAIL")}:</Box>
            <Box>{data?.email}</Box>
            <Box>{t("LOGIN.USERNAME")}:</Box>
            <Box>{data?.userName}</Box>
            <Box paddingTop={"0.5rem"}>
              <SelectField
                label={t("PROFILE.LANG")}
                value={LanguageArray.find((x) => x.key === i18next.language)}
                onChange={(input) => {
                  i18next.changeLanguage(input.key);
                  languageStorage.set(input.key);
                }}
                options={LanguageArray}
              />
            </Box>
          </Box>
        </Conditional>

        <InvitationSender />
        <InvitationList />
        <FriendsList />
      </Box>
    </Box>
  );
};

export const useUserQuery = () => {
  return useQuery({
    queryKey: ["user"],
    queryFn: () =>
      apiClient
        .get<User>(`${config.baseUrl}/user/profile`)
        .then((res) => res.data),
  });
};
