import { useCallback } from "react";
import { useAuth } from "../../features/auth/useAuth";
import { LicensePlanType } from "../../features/auth/AuthContext";

export type PlanPermissions =
  | "canUseTemplates"
  | "canUseConfigureMusic"
  | "canUseConfigureLayout"
  | "canUseConfigureAvatars"
  | "canExportPdf"
  | "canExportImport";

export const All_Permissions: PlanPermissions[] = [
  "canUseTemplates",
  "canExportPdf",
  "canExportImport",
  "canUseConfigureAvatars",
  "canUseConfigureLayout",
  "canUseConfigureMusic",
];

const PERMISSIONS: Record<LicensePlanType, PlanPermissions[]> = {
  Free: [],
  Basic: [],
  Standard: ["canUseTemplates"],
  Premium: All_Permissions,
  Ultimate: All_Permissions,
  Trial: All_Permissions,
};
export const usePlanPermissions = () => {
  const { data } = useAuth();

  const hasPermission = useCallback(
    (permission: PlanPermissions) => {
      if (!data?.licensePlan) return false;
      const list = PERMISSIONS[data?.licensePlan];
      return list.includes(permission);
    },
    [data?.licensePlan],
  );

  return { hasPermission };
};
