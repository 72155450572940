import "./TokenLink.css";
import { clsx } from "../../../shared/utils/clsx";
import { GameToken } from "../../../model/Game";
import { useMapDrag } from "../../dices/use-map-drag";

export interface IconTokenProps {
  fixed?: boolean;
  token: GameToken;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

export const TokenLink = (props: IconTokenProps) => {
  const { token, fixed = false, onClick } = props;

  const handlers = useMapDrag({
    draggableDb: "tokens",
    key: token.key,
    position: token.position,
    fixed,
  });

  return (
    <div
      {...handlers}
      style={{
        backgroundImage: token.avatarUrl ? `url(${token.avatarUrl})` : "black",
        backgroundColor: "black",
        ...handlers.style,
      }}
      className={clsx("token-link", "disable-map")}
      onClick={onClick}
    >
      <div className={clsx("hex-token")}>
        {token.avatarUrl ? "" : token.name?.[0]}
      </div>
    </div>
  );
};
