import React from "react";
import {
  Primitive,
  PrimitiveObject,
  isPrimitive,
} from "../../../../model/Game";
import "./GenericCharacter.css";
import { useQuickEdit } from "../../../quick-edit/hooks/useQuickEdit";
import { PanelMenu, PanelMenuProps } from "./components/panel-menu/PanelMenu";
import { useGamePermission } from "../../../../shared/hooks/useGamePermission";
import { useGameEdit } from "../../../schema/hooks/useGameEdit";
import { clsx } from "../../../../shared/utils/clsx";

interface GenericTemplateViewProps extends Pick<PanelMenuProps, "panelOnMap"> {
  template: PrimitiveObject;
  path: string;
  className?: string;
  isOnMap?: boolean;
  style?: React.CSSProperties;
}

export const GenericTemplateView = (props: GenericTemplateViewProps) => {
  const { template, path, className, isOnMap, panelOnMap, style } = props;
  const fieldList = template ? <Tree obj={template} sufix={path} /> : undefined;

  return (
    <PanelMenu
      panelOnMap={panelOnMap}
      actions={
        isOnMap
          ? [
              "REMOVE_FROM_MAP",
              "SHOW_OTHERS",
              "ADD_PROPERTY",
              "EXPORT",
              "EDIT_CONTENT",
            ]
          : [
              "REMOVE_PANEL",
              "EXPORT",
              "ADD_PROPERTY",
              "CONTEXT_NAME",
              "ADD_TO_MAP",
            ]
      }
      path={path}
    >
      <div
        className={clsx("paper", className)}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "0.5rem",
          ...style,
        }}
      >
        {fieldList}
      </div>
    </PanelMenu>
  );
};

interface TreeProps {
  obj: Record<string, any>;
  sufix?: string;
  justValue?: boolean;
}

export function Tree({ obj, sufix, justValue }: TreeProps): JSX.Element {
  if (!obj) return <></>;
  return (
    <React.Fragment>
      {Object.entries(obj)
        //NOT_EDITABLE_CHARACTER_KEYS
        .filter((x) => x[0] !== "key" && !x[0].startsWith("__"))
        .map((x) => {
          const path = sufix ? `${sufix}.${x[0]}` : x[0];
          const value = x[1];
          let propsToRender: Primitive | JSX.Element;
          if (isPrimitive(value)) propsToRender = value;
          else propsToRender = <Tree obj={value} sufix={path} />;

          // kind of hack
          const justValueIfTitle =
            Object.keys(obj)[1] === "title" && x[0] === "title";
          return (
            <Row
              justValue={justValue || justValueIfTitle}
              key={path}
              root={x[0]}
              path={path}
              value={value}
              content={propsToRender}
            />
          );
        })}
    </React.Fragment>
  );
}

interface RowProps extends CellProps {
  root: string;
  justValue?: boolean;
}

export const Row = (props: RowProps) => {
  const { root, path, value, content, justValue } = props;

  return (
    <div
      key={path}
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        gap: "1rem",
      }}
    >
      <div style={{ fontWeight: "bold" }}>
        {justValue ? "" : root.toUpperCase()}
      </div>
      <Cell path={path} value={value} content={content} />
    </div>
  );
};

interface CellProps {
  path: string;
  value: any;
  content: Primitive | JSX.Element;
}

export const Cell = (props: CellProps) => {
  const { path, value, content } = props;
  const { hasGamePermission } = useGamePermission();
  const { getProps } = useQuickEdit();
  const { generic } = useGameEdit();

  const editable = generic
    ? hasGamePermission("canEditGame")
    : hasGamePermission("canEditCharacter");
  const { actionComponent, ...moreProps } = editable
    ? getProps({ path, value })
    : { actionComponent: null };

  return (
    <div
      {...moreProps}
      style={{
        // width: "100%",
        textAlign: "end",
      }}
    >
      {editable && actionComponent}
      {content}
    </div>
  );
};
