import "./GrantPermissionView.css";
import { useGame } from "../character/hooks/useGame";
import { IconButton, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { useUser } from "../auth/useUser";
import { cuni } from "../../shared/services/cuni/cuni";

export const GrantPermissionView = () => {
  const { game } = useGame();
  const user = useUser();

  if (
    !game.root.permission ||
    !user?.id ||
    user.id === game.root.permission.creatorId ||
    game.root.permission.answers.some((x) => x.userId === user.id)
  )
    return null;

  const answer = (accept: boolean) => {
    const answers = game.root.permission?.answers || [];
    answers.push({ userId: user.id, accept });
    cuni.updateGame("permission", { ...game.root.permission, answers });
  };
  return (
    <div className="grant-permission paper">
      <Typography className="grant-permission-text">
        {game.root.permission.question}
      </Typography>
      <div className="grant-permission-buttons">
        <IconButton onClick={() => answer(false)}>
          <CloseIcon />
        </IconButton>
        <IconButton onClick={() => answer(true)}>
          <CheckIcon />
        </IconButton>
      </div>
    </div>
  );
};
