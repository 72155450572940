import { Tree } from "../../../features/character/components/generic/GenericTemplateView";
import {
  QuickEditModalProvider,
  QuickEditState,
  QuickEditType,
  useQuickEdit,
} from "../../../features/quick-edit/hooks/useQuickEdit";
import { useGamePermission } from "../../hooks/useGamePermission";
import "./Table.css";
import { useGameEdit } from "../../../features/schema/hooks/useGameEdit";
import { flattenDict } from "../../../features/quick-edit/flattenDict";
import { RowMenu, RowMenuProps } from "./row-menu/RowMenu";
import { GenericPanelMenu } from "../../../features/character/components/generic/components/panel-menu/GenericPanelMenu";
import { clsx } from "../../utils/clsx";
import { Character, Game } from "../../../model/Game";
import { GameProvider } from "../../../features/character/hooks/useGame";
import { PropsWithChildren, useEffect, useRef, useState } from "react";
import { PRESSED_KEYS } from "../router/components/Layout";
import { useUser } from "../../../features/auth/useUser";
import { useInfiniteScroll } from "../../hooks/use-infinite-scroll";

export interface TableCellInputProps {
  variant: "row" | "card";
}
export interface TableProps extends Pick<RowMenuProps, "actions"> {
  headers: {
    key: string;
    title: string;
    cell?: (
      item: any,
      index: number,
      props: TableCellInputProps,
    ) => JSX.Element | string | number;
    type?: QuickEditType;
  }[];
  rows: Record<string, any>[];
  title?: string | null;
  titleSufix?: string;
  disableGenericMenu?: boolean;
  directList?: boolean;
  root?: any;
  className?: string;
  onRowRemove?: (value: any) => void;
  onEditRow?: (value: any[]) => void;
  standalone?: boolean;
  variant?: "row" | "card";
  fetchMore?: () => void;
  selectedRows?: any[];
  onRowSelected?: (value: any[]) => void;
  style?: React.CSSProperties;
}

export const Table = (props: TableProps) => {
  const {
    headers,
    rows,
    title,
    titleSufix,
    directList,
    root,
    className,
    onRowRemove,
    onEditRow,
    disableGenericMenu = false,
    standalone,
    variant: initialVariant = "row",
    fetchMore,
    onRowSelected,
    style,
  } = props;

  const [variant, setVariant] = useState(initialVariant);
  const [selectedRows, setRowSelected] = useState<any[]>(
    props.selectedRows || [],
  );
  const [lastSelected, setLastSelected] = useState<any>(null);

  useEffect(() => onRowSelected?.(selectedRows), [onRowSelected, selectedRows]);
  useEffect(() => {
    if (selectedRows.length === 0 && props.selectedRows) {
      setRowSelected?.(props.selectedRows);
    }
  }, [props.selectedRows, selectedRows.length]);

  const containerRef = useRef<HTMLDivElement>(null);
  useInfiniteScroll({ scrollRef: containerRef, fetchMore });

  const dataTable = directList ? "" : "__data.";

  const styles =
    variant === "row"
      ? {
          gridTemplateColumns: headers.reduce((p, c) => `${p} 1fr`, ""),
        }
      : {
          gridTemplateRows: "100px 20px 20px",
          padding: 4,
          border: "1px solid white",
          borderRadius: 8,
          alignItems: "center",
          justifyItems: "center",
          gap: "0.25rem",
        };

  // width: 60px;
  // height: 90px;
  // display: grid;
  // flex-direction: column;
  // grid-template-rows: 1fr 20px 20px;
  const component = (
    <div ref={containerRef} className={clsx("paper", className)} style={style}>
      {title && titleSufix && (
        <Tree justValue obj={{ title }} sufix={titleSufix} />
      )}
      <div
        className={clsx({
          table__container: variant === "row",
          "table__container-card": variant === "card",
        })}
        style={
          variant === "row"
            ? {
                gridTemplateColumns: headers.reduce((p, c) => `${p} 1fr`, ""),
              }
            : undefined
        }
      >
        {variant === "row" &&
          headers.map((x) => (
            <div key={`key_${x.key}`} className="table__headerCell">
              {x.title}
            </div>
          ))}

        {rows.map((item, idx) => (
          <RowMenu
            key={`row_${idx}`}
            style={styles}
            tablePath={`${titleSufix}.${dataTable}`}
            rowId={idx}
            selected={selectedRows.some((x) => x.key === item.key)}
            onRowSelect={() => {
              setLastSelected(item);
              setRowSelected((prev) => {
                const inside = prev.includes(item);
                if (PRESSED_KEYS["ShiftLeft"]) {
                  let lastIndex = rows.findIndex(
                    (el) => el.key === lastSelected?.key,
                  );
                  if (lastIndex === -1) lastIndex = 0;

                  const newAdd =
                    idx > lastIndex
                      ? rows.slice(lastIndex, idx + 1)
                      : rows.slice(idx, lastIndex + 1);

                  if (inside) {
                    const keys = newAdd.map((x) => x.key);
                    return prev.filter((x) => !keys.includes(x.key));
                  }

                  return [...new Set([...prev, ...newAdd])];
                }
                if (inside) return prev.filter((x) => x.key !== item.key);
                return [...prev, item];
              });
            }}
            onRowRemove={onRowRemove ? () => onRowRemove(item) : undefined}
            actions={props.actions}
          >
            {Object.entries(item)
              .filter((y) => {
                const col = headers.find((x) => x.key === y[0]);
                // extend by col
                if (typeof y[1] === "object") {
                  const flatten = flattenDict(y[1]);
                  return Object.keys(flatten).some((key) =>
                    headers.some((header) => header.key === `${y[0]}.${key}`),
                  );
                }
                return col;
              })
              .map((y) => {
                let col = headers.find((x) => x.key === y[0]);
                let value = y[1];
                if (typeof y[1] === "object") {
                  const flatten = flattenDict(y[1]) as Record<string, any>;
                  col = headers.find((header) =>
                    Object.keys(flatten).some(
                      (key) => header.key === `${y[0]}.${key}`,
                    ),
                  );
                  const key = col?.key.replace(`${y[0]}.`, "");
                  if (key) value = flatten[key];
                }

                if (col?.cell) return col.cell(item, idx, { variant });

                const path = `${titleSufix}.${dataTable}${idx}.${col?.key}`;
                return (
                  <TableCell
                    label={col?.title}
                    key={path}
                    path={path}
                    value={value}
                    {...col}
                  />
                );
              })}
          </RowMenu>
        ))}
      </div>
    </div>
  );

  const transformComponent = (() => {
    if (root && titleSufix && !disableGenericMenu)
      return (
        <GenericPanelMenu
          root={root}
          path={titleSufix}
          variant={variant}
          setVariant={setVariant}
          onEditRow={
            onEditRow && selectedRows.length
              ? () => onEditRow(selectedRows)
              : undefined
          }
        >
          {component}
        </GenericPanelMenu>
      );

    return component;
  })();

  if (standalone) {
    return <Standalone>{transformComponent}</Standalone>;
  }
  return transformComponent;
};

const Standalone = ({ children }: PropsWithChildren) => {
  const user = useUser();
  return (
    <GameProvider game={{ gameMasterId: user.id } as Game}>
      <QuickEditModalProvider character={{} as Character}>
        {children}
      </QuickEditModalProvider>
    </GameProvider>
  );
};

interface TableCellProps extends QuickEditState {
  disabled?: boolean;
}

const TableCell = (props: TableCellProps) => {
  const { value, disabled } = props;
  const { hasGamePermission } = useGamePermission();
  const { generic } = useGameEdit();
  const { getProps } = useQuickEdit();
  const { actionComponent, ...moreProps } =
    (generic
      ? hasGamePermission("canEditGame")
      : hasGamePermission("canEditCharacter")) && !disabled
      ? getProps(props)
      : { actionComponent: null, className: "" };

  return (
    <div {...moreProps} className={"table__cell " + moreProps.className}>
      {actionComponent}
      {value}
    </div>
  );
};
