import { IconButton, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useMaps } from "./MapProvider";
import { cuni } from "../../../shared/services/cuni/cuni";
import PersonPinCircleIcon from "@mui/icons-material/PersonPinCircle";
import { uuid } from "../../../shared/utils/uuid";
import { useUser } from "../../auth/useUser";

export const AddUserArea = () => {
  const { t } = useTranslation();

  const { all, current } = useMaps();
  const user = useUser();
  const addUserArea = () => {
    if (!current) return;
    current.deckPlaces = current.deckPlaces.filter((x) => x.type !== "card");
    cuni.array.push("maps", all, current.elements, {
      type: "USER_AREA",
      key: uuid(),
      ownerId: user.id,
      uiType: "SQUARE"
    });
  };

  return (
    <Tooltip title={t("MAP.ADD_USER_AREA")}>
      <IconButton>
        <PersonPinCircleIcon onClick={addUserArea} />
      </IconButton>
    </Tooltip>
  );
};
