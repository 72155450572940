import { observer } from "mobx-react";
import CharacterView from "../features/character/CharacterView";
import { GameProvider, useGame } from "../features/character/hooks/useGame";
import Chat from "../features/chat/Chat";
import { rootStore } from "../store/RootStore";
import { GameTabSectionEditView } from "../features/schema/GameTabSectionEditView";
import { MobileToolbar } from "./mobile-navigation/MobileToolbar";
import { useMediaQuery } from "@mui/material";
import { Conditional } from "../shared/components/logic/Conditional";
import { useState } from "react";
import { useBackgroundImage } from "../shared/hooks/useBackgoundImage";
import { GmToolbox } from "../features/gm-toolbox/GmToolbox";
import { GameLayout } from "../model/Game";
import { MapProvider } from "../features/map-hex/components/MapProvider";
import { MapHexDisplay } from "../features/map-hex/MapHex";
import { DEFAULT_LAYOUT } from "../features/game-general/components/LayoutView";
import { cuni } from "../shared/services/cuni/cuni";
import {
  UserToolbox,
  UserToolboxProps,
} from "../features/user-toolbox/UserToolbox";
import { MapDragProvider } from "../features/dices/MapDragProvider";
import { GrantPermissionView } from "../features/grant-permission/GrantPermissionView";
import { AvatarView } from "../features/character/components/avatar/AvatarView";
import { NavigateBack } from "../shared/components/router/NavigateBack";

const GamePageInternal = () => {
  const isMobile = useMediaQuery("(max-width: 1024px)");

  useBackgroundImage();

  const { game } = useGame();
  const layout = game.root?.layout || DEFAULT_LAYOUT;
  const tabs = Object.entries(layout)
    .filter((x) => !x[1].hidden && !x[1].collapsed && x[0] !== "drawer")
    .flatMap((x) => x[1].panels);


  const [mobileTab, setMobileTab] = useState(tabs[0]);

  const renderLayout = (panelKey: keyof GameLayout, onDrawer?: boolean) => {
    const layout = game.root?.layout ?? DEFAULT_LAYOUT;
    const panel = layout?.[panelKey];

    const renderedPanels = panel?.panels?.map((type, idx) => {
      switch (type) {
        case "character":
          return !isMobile ||
            onDrawer ||
            (isMobile && mobileTab === "character") ? (
            <div
              className="app__block"
              style={idx === 0 ? { flexGrow: panel.sizeY } : undefined}
            >
              <CharacterView
                style={{ paddingRight: "1rem" }}
                minimized={panel?.collapsed}
                setMinimized={(input) => {
                  game.root.layout ??= DEFAULT_LAYOUT;
                  game.root.layout[panelKey].collapsed =
                    !game.root.layout[panelKey].collapsed;
                  cuni.updateGame("layout", game.root.layout);
                }}
              />
            </div>
          ) : null;
        case "chat":
          return !isMobile || onDrawer || (isMobile && mobileTab === "chat") ? (
            <Chat />
          ) : null;
        case "table":
          return !isMobile ||
            onDrawer ||
            (isMobile && mobileTab === "table") ? (
            <MapProvider>
              <MapDragProvider>
                <div
                  className="mapHex"
                  style={idx === 0 ? { flexGrow: panel.sizeY } : undefined}
                >
                  <MapHexDisplay />
                </div>
              </MapDragProvider>
            </MapProvider>
          ) : null;
        case "story":
          return !isMobile || onDrawer || (isMobile && mobileTab === "story") ? (
            <GameTabSectionEditView
              editEnabled={false}
              style={idx === 0 ? { flexGrow: panel.sizeY } : undefined}
              // style={isMobile ? undefined : { paddingLeft: "1rem" }}
            />
          ) : null;
        default:
          return null;
      }
    });

    if (!renderedPanels?.filter(Boolean).length) return <></>;
    return (
      (
        <div
          className="app-columns"
          style={{
            gridColumn: `span ${panel.size || DEFAULT_LAYOUT[panelKey].size}`,
          }}
        >
          {renderedPanels}
        </div>
      ) || <></>
    );
  };

  const left =
    !game.root?.layout?.left?.hidden && !game.root?.layout?.left?.collapsed;
  const center =
    !game.root?.layout?.middle?.hidden && !game.root?.layout?.middle?.collapsed;
  const right =
    !game.root?.layout?.right?.hidden && !game.root?.layout?.right?.collapsed;

  const renderers: UserToolboxProps["renderers"] = [];
  if (!game.root?.layout?.left?.hidden && game.root?.layout?.left?.collapsed)
    renderers.push(() => renderLayout("left", true));
  if (
    !game.root?.layout?.middle?.hidden &&
    game.root?.layout?.middle?.collapsed
  )
    renderers.push(() => renderLayout("middle", true));
  if (!game.root?.layout?.right?.hidden && game.root?.layout?.right?.collapsed)
    renderers.push(() => renderLayout("right", true));

  return (
    <>
      <div className="App">
        <Conditional condition={left}>{renderLayout("left")}</Conditional>
        <Conditional condition={center}>{renderLayout("middle")}</Conditional>
        <Conditional condition={right}>{renderLayout("right")}</Conditional>
        <Conditional condition={isMobile && tabs.length > 1}>
          <MobileToolbar current={mobileTab} tabs={tabs} set={setMobileTab} />
        </Conditional>
      </div>
      <UserToolbox renderers={renderers} />
      <GmToolbox />
      <GrantPermissionView />
      <AvatarView />

      <NavigateBack />
    </>
  );
};

// TODO: validate query parameter
export const GamePage = observer(() => (
  <GameProvider game={rootStore.game}>
    <GamePageInternal />
  </GameProvider>
));
