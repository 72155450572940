import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  FC,
} from "react";
import ToastContainer from "./ToastContainer";

export interface Toast {
  id: number;
  message: string;
  type: "ERROR" | "INFO";
}

interface ToastContextType {
  toasts: Toast[];
  addToast: (message: string, type?: Toast["type"]) => void;
  removeToast: (id: number) => void;
}

const ToastContext = createContext<ToastContextType | undefined>(undefined);

export const useToast = (): ToastContextType => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error("useToast must be used within a ToastProvider");
  }
  return context;
};

interface ToastProviderProps {
  children: ReactNode;
}

let toastId = 0;
export let tokenProviderRef: ToastContextType | null = null;

export const ToastProvider: FC<ToastProviderProps> = ({ children }) => {
  const [toasts, setToasts] = useState<Toast[]>([]);

  const addToast = (message: string, type: Toast["type"] = "INFO") => {
    setToasts([...toasts, { id: toastId++, message, type }]);
  };

  const removeToast = (id: number) => {
    setToasts(toasts.filter((toast) => toast.id !== id));
  };

  const value = { toasts, addToast, removeToast };
  tokenProviderRef = value;
  return (
    <ToastContext.Provider value={value}>
      {children} <ToastContainer />
    </ToastContext.Provider>
  );
};
