import { Game } from "../../model/Game";
import { config } from "../../config";
import "./GameList.css";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { apiClient } from "../../shared/services/apiClient";
import { gameStorage } from "../../shared/services/storage";
import { useQuery } from "@tanstack/react-query";
import { GameListActions } from "./GameListActions";
import { useUser } from "../auth/useUser";
import { Conditional } from "../../shared/components/logic/Conditional";
import EditIcon from "@mui/icons-material/Edit";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { GameInfo } from "../../shared/components/ui/info/GameInfo";
import { calculateBackground } from "../../shared/hooks/useBackgoundImage";
import { useTranslation } from "react-i18next";

export const GameList = () => {
  const { data: games } = useGameAll();
  const navigate = useNavigate();
  const user = useUser();
  const { t } = useTranslation();

  const items = games?.map((game) => (
    <div key={game.id} className="paper game-item-wrapper">
      <div className="game-item">
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            backgroundImage: calculateBackground(game.url),
          }}
          className="game-wallpaper"
        />
        <div className="game-item-buttons">
          <Typography style={{ zIndex: 10 }} variant="h5">
            {game.name}
          </Typography>

          <Conditional condition={game.gameMasterId === user?.id}>
            <Button
              variant="contained"
              onClick={() => {
                gameStorage.set(game);
                navigate(`/game/${game.id}/modify`);
              }}
              startIcon={<EditIcon />}
            >
              {t("COMMON.EDIT")}
            </Button>
          </Conditional>
          <Button
            variant="contained"
            onClick={() => {
              gameStorage.set(game);
              navigate("/");
            }}
            startIcon={<PlayArrowIcon />}
          >
            {t("COMMON.JOIN")}
          </Button>
        </div>
      </div>
    </div>
  ));
  return (
    <div className="game-list">
      <GameInfo>{t("INFO.GAME_LIST_TIP")}</GameInfo>
      {items}

      <div className="paper  game-item-wrapper">
        <div className="game-item">
          <GameListActions games={games} />
        </div>
      </div>
    </div>
  );
};

export const useGameAll = () => {
  return useQuery({
    queryKey: ["game-all"],
    queryFn: () =>
      apiClient.get<Game[]>(`${config.baseUrl}/game`).then((res) => res.data),
  });
};
