import { PropsWithChildren } from "react";
import { AppThemeProvider } from "./AppThemeProvider";
import { AuthProvider } from "../../features/auth/AuthProvider";
import { ToastProvider } from "./toast/ToastProvider";
import { AppQueryProvider } from "./AppQueryProvider";
import { AppTranslationProvider } from "./AppTranslationProvider";
import { AppMusicProvider } from "../../features/auth/AppMusicProvider";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

export const AppProvider = (props: PropsWithChildren) => {
  return (
    <DndProvider backend={HTML5Backend}>
      <AppTranslationProvider>
        <ToastProvider>
          <AppQueryProvider>
            <AppThemeProvider>
              <AppMusicProvider>
                <AuthProvider>{props.children}</AuthProvider>
              </AppMusicProvider>
            </AppThemeProvider>
          </AppQueryProvider>
        </ToastProvider>
      </AppTranslationProvider>
    </DndProvider>
  );
};
