import React from "react";
import { Game } from "../../model/Game";
import {
  UserTextMessage,
  WebHookSubType,
  WebHookType,
} from "../../model/WebHookMessage";
import { cuni } from "../../shared/services/cuni/cuni";
import { authStorage, gameStorage } from "../../shared/services/storage";
import { AuthData } from "../auth/AuthContext";
import "./Chat.css";
import ChatWindow from "./ChatWindow";
import { ActionFormValues } from "./components/ActionForm";

interface IChat {}

const Chat: React.FC<IChat> = (props: IChat) => {
  // TODO move to another file
  const sendMessage = async (
    message: string,
    subType: WebHookSubType,
    values: ActionFormValues,
  ) => {
    const game = gameStorage.get<Game>();
    if (!game)
      throw new Error("You should be in game context when send message.");
    if (subType === "ACTION_ROLL") {
      cuni.userRoll(message, values.currentName);
    } else {
      const auth = authStorage.get<AuthData>();
      const userName =
        subType === "USER_CHAT"
          ? auth?.userName
          : values.currentName || game.me.name;
      const color = subType === "USER_CHAT" ? "lightsalmon" : values.color;
      const chatMessage: UserTextMessage = {
        type: WebHookType.UserText,
        user: userName ?? "Noname",
        message: message,
        receiverId: values.receiverId,
        subType,
        color,
      };
      cuni.pushMessage(() => chatMessage);
    }
  };

  return (
    <div className="app__chat">
      <div className="chat__wrapper">
        <ChatWindow sendMessage={sendMessage} />
      </div>
    </div>
  );
};

export default Chat;
