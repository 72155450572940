import { Resource } from "../../model/Resource";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { apiClient } from "../../shared/services/apiClient";
import { config } from "../../config";
import { ApiQuery } from "./use-data-provider";

export const useResources = () => {
  return useInfiniteQuery<Resource[]>({
    queryKey: ["resource-all"],
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages) => {
      return pages.flatMap((x) => x).length;
    },
    queryFn: async ({ pageParam }) => {
      const query = new ApiQuery(pageParam as number);
      const res = await apiClient.get<Resource[]>(
        `${config.baseUrl}/api/resource/${query ? query.serialize() : ""}`,
      );
      return res.data;
    },
  });
};

export const useResourceCount = () => {
  return useQuery({
    queryKey: ["resource-count"],
    queryFn: () =>
      apiClient
        .post<{ size: number }>(
          `${config.baseUrl}/api/resource/custom/Count`,
          {},
        )
        .then((res) => res.data),
  });
};
