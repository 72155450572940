import { useEffect, useRef } from "react";

export function useMousePosition() {
  const mousePosition = useRef({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (event: any) => {
      mousePosition.current = {
        x: event.clientX,
        y: event.clientY,
      };
    };

    // Add event listener for mousemove
    window.addEventListener("mousemove", handleMouseMove);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return mousePosition;
}
