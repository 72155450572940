import { Typography, Link, LinearProgress } from "@mui/material";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Table, TableCellInputProps } from "../../shared/components/ui/Table";
import { SwitchView } from "../../shared/components/ui/switch-view/SwitchView";
import { Resource } from "../../model/Resource";
import {
  useResourceCount,
  useResources,
} from "../../shared/hooks/use-resources";
import { useAws } from "../../shared/hooks/use-aws";
import { useUser } from "../auth/useUser";

export const UIDatabaseView = () => {
  const { t } = useTranslation();

  const [view, setView] = useState<string | null>(null);

  const { data: count } = useResourceCount();
  const currentValue = count?.size || 0;

  const { data: infiniteData, fetchNextPage: fetchMore } = useResources();
  const data = useMemo(
    () => infiniteData?.pages.flatMap((x) => x),
    [infiniteData],
  );

  const { rows, headers } = useMemo(() => {
    const items = data || [];
    const headers = [
      {
        key: "key",
        title: t("COMMON.NAME"),
        cell: (
          value: Resource,
          idx: number,
          { variant }: TableCellInputProps,
        ) =>
          variant === "card" ? (
            <img alt="" style={{ maxHeight: 100 }} src={value.url} />
          ) : (
            value.key
          ),
      },
      {
        key: "size",
        title: t("COMMON.SIZE"),
      },
      {
        key: "action",
        title: t("COMMON.ACTION"),
        cell: (value: Resource, index: number) => (
          <Link color="primary" href={value.url}>
            {t("COMMON.DOWNLOAD")}
          </Link>
        ),
      },
    ];
    const rows = items.map((x) => ({
      ...x,
      action: "",
    }));
    return { rows, headers };
  }, [data, t]);

  const { remove } = useAws();
  const user = useUser();

  return (
    <SwitchView
      setView={setView}
      view={view}
      viewComponents={{
        detail: <div></div>,
      }}
    >
      <div className="column" style={{ paddingRight: "1rem" }}>
        <Typography>
          {t("DATABASE.OVERRIDE_INFO")} -{" "}
          {t("COMMON.MAX_OF", {
            current: (currentValue / (1024 * 1024 * 1)).toFixed(2),
            max: user.licensePlanData.size / (1024 * 1024 * 1),
          })}
        </Typography>
        <LinearProgress
          title={t("COMMON.MAX_OF", {
            current: currentValue / user.licensePlanData.size,
          })}
          variant="determinate"
          value={(currentValue / user.licensePlanData.size) * 100}
        />
        <Table
          directList
          headers={headers}
          className="paper-outline"
          rows={rows}
          onRowRemove={(resource: Resource) => {
            if (resource?.id) remove(resource?.id);
          }}
          standalone
          variant="card"
          fetchMore={fetchMore}
        />
      </div>
    </SwitchView>
  );
};
