import React, { useState } from "react";
import { BasicView } from "../shared/components/ui/BasicView";
import { Alert, Button, Divider, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { FormValidation } from "../shared/services/validator/FormValidation";
import { useAuth } from "../features/auth/useAuth";
import { RegisterDataInput } from "../features/auth/AuthContext";
import { Conditional } from "../shared/components/logic/Conditional";
import { LoadingButton } from "../shared/components/ui/LoadingButton";
import { useTranslation } from "react-i18next";
import { PasswordAnalyzer } from "../shared/components/logic/PasswordAnalyzer";
import { IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";

export interface RegisterPageProps {
  children?: React.ReactElement;
}

type RegisterPageForm = RegisterDataInput;

export const RegisterPage: React.FC<RegisterPageProps> = (
  props: RegisterPageProps,
) => {
  const auth = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<RegisterPageForm>();
  const [loading, setLoading] = useState(false);

  const [apiError, setApiError] = useState<string>();
  const submit = async (data: RegisterPageForm) => {
    try {
      setLoading(true);
      const { needCodeValidaton } = await auth.register(data);
      if (needCodeValidaton) navigate("/confirm");
      else navigate("/list");
    } catch (error) {
      // @ts-ignore
      console.error(error?.response?.data?.message);
      setApiError(`${t("ERROR.PASSWORD")}\n${t("ERROR.USERNAME_VALIDATION")}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <BasicView>
        <label>{t("LOGIN.SIGN_UP")}</label>
        <TextField
          label={t("LOGIN.USERNAME")}
          {...register("username", { required: true })}
          error={Boolean(errors.username)}
          helperText={t("ERROR.USERNAME_VALIDATION")}
        />
        <TextField
          label={t("LOGIN.EMAIL")}
          type="email"
          {...register("email", {
            pattern: FormValidation.email,
            required: true,
          })}
          error={Boolean(errors.email)}
        />

        <TextField
          label={t("LOGIN.PASSWORD")}
          type={showPassword ? "text" : "password"} // Toggle between "text" and "password"
          {...register("password", { minLength: 8, required: true })}
          error={Boolean(errors.password)}
          // helperText={t("LOGIN.CHAR_LENGTH_REQUIREMENTS")}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleClickShowPassword}
                  edge="end"
                  aria-label="toggle password visibility"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <PasswordAnalyzer password={watch("password")} />

        <Divider />
        <Conditional condition={apiError}>
          <Alert severity="error" style={{ whiteSpace: "break-spaces" }}>
            {apiError}
          </Alert>
        </Conditional>

        <TermsAndPrivacyCheckboxes>
          {({ disabled }) => (
            <LoadingButton
              loading={loading}
              disabled={!isValid || disabled}
              variant="contained"
              type="submit"
            >
              {t("LOGIN.REGISTER")}
            </LoadingButton>
          )}
        </TermsAndPrivacyCheckboxes>

        <Button onClick={() => navigate("/login")}>
          {t("LOGIN.BACK_TO_LOGIN")}
        </Button>
      </BasicView>
    </form>
  );
};

interface TermsAndPrivacyCheckboxesProps {
  children: (input: { disabled: boolean }) => JSX.Element;
}
const TermsAndPrivacyCheckboxes = (props: TermsAndPrivacyCheckboxesProps) => {
  const { t } = useTranslation();
  const [isChecked, setIsChecked] = useState(false);
  const [isTermsChecked, setIsTermsChecked] = useState(false);

  const handlePrivacyChange = (event: any) => {
    setIsChecked(event.target.checked);
  };

  const handleTermsChange = (event: any) => {
    setIsTermsChecked(event.target.checked);
  };

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox checked={isChecked} onChange={handlePrivacyChange} />
        }
        label={
          <span>
            {t("acceptPrivacyPolicy")}{" "}
            <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
              {t("privacyPolicy")}
            </a>
          </span>
        }
      />
      <FormControlLabel
        control={
          <Checkbox checked={isTermsChecked} onChange={handleTermsChange} />
        }
        label={
          <span>
            {t("acceptTermsOfService")}{" "}
            <a
              href="/terms-of-use"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("termsOfService")}
            </a>
          </span>
        }
      />
      {props.children({ disabled: !isChecked || !isTermsChecked })}
    </FormGroup>
  );
};
