import { Game } from "./Game";

export enum WebHookType {
  UserText = "USER_TEXT",
  UserInteraction = "USER_INTERACTION",
  UserTextRefresh = "USER_TEXT_REFERSH",
  GameUpdate = "GAME_UPDATE",
  GameDidUpdate = "GAME_DID_UPDATE",
  METADATA = "METADATA",
}

export type WebHookSubType =
  | "USER_INPUT_INTERACTION"
  | "NEW_SESSION"
  | "SURVEY"
  | "STORY"
  | "USER_CHAT"
  | "CHAT_REFRESH"
  | "CHARACTER_CHAT"
  | "ACTION_ROLL"
  | "GAME_UPDATE"
  | "CONNTECT"
  | "DISCONNECT"
  | "GLOBAL_GAME_UPDATE";

export interface BaseMessage {
  id?: number;
  metaData?: IMetadata;
  subType: WebHookSubType;
  created?: string;
}

export interface IMetadata {
  gameId?: number;
  charId?: number;
  path?: string;
  value?: string | number;
  userOnlineIds?: number[];
}

export interface GameUpdateMessage extends BaseMessage {
  type: WebHookType.GameUpdate;
}

export interface GameDidUpdateMessage extends BaseMessage {
  type: WebHookType.GameDidUpdate;
  game: Game;
}

export interface UserTextBaseMessage extends BaseMessage {
  user?: string;
  message?: string;
  color?: string;
  receiver?: string;
  receiverId?: number;
  contextObjectId?: number;
}

export interface UserInteractionMessage extends UserTextBaseMessage {
  type: WebHookType.UserInteraction;
}

export interface UserTextMessage extends UserTextBaseMessage {
  type: WebHookType.UserText;
  user: string;
  message: string;
}
export interface UserTextRefreshMessage extends UserTextBaseMessage {
  type: WebHookType.UserTextRefresh;
}

export type WebHookMessage =
  | UserInteractionMessage
  | GameUpdateMessage
  | UserTextMessage
  | UserTextRefreshMessage
  | GameDidUpdateMessage;
