import { IconButton, Tooltip } from "@mui/material";
import { useGame } from "../../hooks/useGame";
import "./Avatar.css";
import { AvatarImage } from "./AvatarImage";
import { Popover } from "../../../../shared/components/ui/popover/Popover";
import { CardTake } from "../../../cards/components/CardTake";
import { useUser } from "../../../auth/useUser";
import { Conditional } from "../../../../shared/components/logic/Conditional";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import HourglassDisabledIcon from "@mui/icons-material/HourglassDisabled";
import { t } from "i18next";
import { cuni } from "../../../../shared/services/cuni/cuni";
import { ReactComponent as CardIcon } from "../../../../assets/icons/card.svg";
import { useCallback, useState } from "react";
import { AvatarModal } from "./modal/AvatarModal";

export const AvatarView = () => {
  const user = useUser();
  const { game } = useGame();

  const [modal, setModal] = useState("");
  const handleClose = useCallback(() => setModal(""), []);
  if (!game.characters) return null;

  const userOnlineIds = game.userOnlineIds ?? [];

  const components = Object.entries(game.characters)
    .map((x) => x[1])
    .map((character, idx) => {
      const panel = character.__panels?.find((x) => x.__type === "card");
      const cards = panel?.cards as any[];
      const avatarComponent = (onClick?: any) => (
        <Tooltip title={character.name}>
          <div
            key={character.key}
            className="avatar__image--container"
            style={{
              right: 80 * (Object.entries(game.characters).length - idx - 1),
            }}
            onClick={onClick}
          >
            <AvatarImage
              online={userOnlineIds.includes(character.__userId)}
              round={game.root.currentPlayerId === character?.__userId}
              imgUrl={character.__avatarUrl || ""}
            />
            <Conditional
              condition={game.root.currentPlayerId === character?.__userId}
            >
              <div className="avatar__image--round">
                <HourglassBottomIcon />
              </div>
            </Conditional>
            <Conditional condition={character.__userId === user?.id}>
              <div className="avatar__image--me" />
            </Conditional>

            <Conditional condition={panel}>
              <div className="avatar__image--cards">
                {cards?.length}
                <CardIcon />
              </div>
            </Conditional>
          </div>
        </Tooltip>
      );
      if (character.__userId === user?.id)
        return avatarComponent(() => setModal("avatar"));
      return (
        <Popover anchor="top" renderButton={avatarComponent}>
          <div className="deck-place-container">
            <CardTake character={character} />
          </div>
        </Popover>
      );
    });
  return (
    <div className="avatar__container">
      <Tooltip title={t("COMMON.ROUND")}>
        <div
          className="paper avatar__image--container"
          style={{
            right: 80 * Object.entries(game.characters).length,
            padding: 0,
            bottom: 3,
          }}
        >
          <IconButton
            disabled={
              !!game.root.currentPlayerId &&
              game.root.currentPlayerId !== user?.id
            }
            onClick={() => {
              if (game.root.currentPlayerId === user?.id) {
                const chars = Object.values(game.characters);
                const current = chars.findIndex((x) => x.__userId === user?.id);
                if (current === -1) {
                  console.warn("User not found");
                  cuni.updateGame("currentPlayerId", 0);
                  return;
                }

                const newPlayer = chars[(current + 1) % chars.length];
                if (!newPlayer) {
                  console.warn("User not found");
                  cuni.updateGame("currentPlayerId", 0);
                  return;
                }
                cuni.updateGame("currentPlayerId", newPlayer.__userId);
              } else {
                cuni.updateGame("currentPlayerId", user?.id);
              }
            }}
          >
            <Conditional
              condition={game.root.currentPlayerId === user?.id}
              success={() => <HourglassDisabledIcon />}
              failure={() => <HourglassBottomIcon />}
            />
          </IconButton>
        </div>
      </Tooltip>

      {components}
      <AvatarModal open={modal === "avatar"} handleClose={handleClose} />
    </div>
  );
};
