import { IconButton, Popover } from "@mui/material";
import { clsx } from "../../../shared/utils/clsx";
import "./Hex.css";
import { useHexActions } from "./HexActionProvider";
import React, { useRef, useState } from "react";
import { useMaps } from "./MapProvider";
import CancelIcon from "@mui/icons-material/Cancel";
import { useGamePermission } from "../../../shared/hooks/useGamePermission";
import { Conditional } from "../../../shared/components/logic/Conditional";
import { HexProps, HexRowProps } from "./types";
import { IconToken } from "./IconToken";
import { useDrop } from "react-dnd";
import { MapItem } from "../../../model/Map";

export const Hex = (props: HexProps) => {
  const { even, item, hasBackground: inHasBackground } = props;
  const anchorEl = useRef<HTMLButtonElement>();
  const [currentToken, setToken] = useState("");

  const { updateHex } = useMaps();

  const [{ isOver }, drop] = useDrop({
    accept: "TOKEN",
    drop: (dropItem) => {
      const { item: inItem, token: inToken } = dropItem as {
        item: MapItem;
        token: string;
      };
      updateHex(inItem.id, {
        tokens: inItem.tokens?.filter((x) => x !== inToken),
      });

      updateHex(item.id, {
        tokens: [...(item.tokens || []), inToken],
      });
    },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
        canDrop: !!monitor.canDrop(),
      }),
  });
  let hasBackground = isOver || inHasBackground;
  const { hasGamePermission } = useGamePermission();
  const { open } = useHexActions();
  const color = item.exist ? undefined : item.color;
  return (
    <div
      ref={drop}
      className={clsx("hex", {
        even,
        exist: item.exist,
        empty: !item.exist,
      })}
      onClick={(e) => {
        if (!hasGamePermission("canEditMap")) return;
        open(e.currentTarget, item.id);
      }}
    >
      <div
        style={{
          borderRightColor: color,
          opacity: hasBackground ? 0.45 : 1,
        }}
        className="hex-left"
      ></div>
      <div
        style={{
          background: color,
          opacity: hasBackground ? 0.45 : 1,
        }}
        className="hex-middle"
      >
        <Popover
          open={Boolean(currentToken)}
          onClose={() => setToken("")}
          anchorEl={anchorEl?.current}
        >
          <IconButton
            color="error"
            onClick={(e) => {
              e.stopPropagation();
              updateHex(item.id, {
                tokens: item.tokens?.filter((x) => x !== currentToken),
              });
              setToken("");
            }}
          >
            <CancelIcon />
          </IconButton>
        </Popover>
        <Conditional condition={!item.exist}>
          <React.Fragment>
            {item.tokens?.map((token) => (
              <IconToken
                key={token}
                token={token}
                item={item}
                onClick={(e) => {
                  e.stopPropagation();
                  anchorEl.current = e.currentTarget;
                  setToken(token);
                }}
              />
            ))}
          </React.Fragment>
        </Conditional>
      </div>
      <div
        style={{
          opacity: hasBackground ? 0.45 : 1,
          borderLeftColor: color,
        }}
        className="hex-right"
      ></div>
    </div>
  );
};

export const HexRow = (props: HexRowProps) => {
  return (
    <div className="hex-row">
      {props.row.map((item, idx) => (
        <Hex even={idx % 2 === 0} item={item} {...props} />
      ))}
    </div>
  );
};
