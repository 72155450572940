import { Button, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { useCallback, useState } from "react";
import { ModalProps } from "../../../../shared/components/modal/ModalProps";
import { useGame } from "../../../character/hooks/useGame";
import { useAddCharacter } from "../../hooks/useAddCharacter";
import { rootStore } from "../../../../store/RootStore";
import { useTranslation } from "react-i18next";

type AddCharacterModalProps = ModalProps;

export const AddCharacterModal = (props: AddCharacterModalProps) => {
  const { open, handleClose } = props;

  const { t } = useTranslation();

  const [text, setText] = useState("");
  const { game } = useGame();
  const { mutate } = useAddCharacter(game);

  const submit = useCallback(() => {
    if (text)
      mutate(
        { name: text },
        {
          onSuccess: async () => {
            rootStore.init();
          },
        },
      );
    handleClose();
  }, [mutate, handleClose, text]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box className="modal">
        <Typography variant="h6" component="h2">
          {t("GAME.ADD_CHAR")}
        </Typography>
        <TextField
          label={t("COMMON.NAME")}
          onChange={(e) => setText(e.target.value)}
          value={text}
        />
        <Button variant="contained" onClick={submit}>
          {t("COMMON.ADD")}
        </Button>
        <Button onClick={handleClose}> {t("COMMON.CLOSE")}</Button>
      </Box>
    </Modal>
  );
};
