import React, { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery, Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const DesktopOnlyPlaceholder = ({ children }: PropsWithChildren) => {
  const isDesktop = useMediaQuery("(min-width:1024px)");
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleBackToHome = () => {
    navigate("/");
  };

  return (
    <Box>
      {!isDesktop && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
            width: "100vw",
            textAlign: "center",
            background: "black",
            zIndex: 9000,
          }}
        >
          <Typography color="white" variant="h4" gutterBottom>
            {t("ERROR.NOT_AVAILABLE_VIEW")}
          </Typography>
          <Button variant="contained" onClick={handleBackToHome}>
            {t("ERROR.BACK_TO_HOME")}
          </Button>
        </Box>
      )}
      {isDesktop && children}
    </Box>
  );
};

export default DesktopOnlyPlaceholder;
