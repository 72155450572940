import { PropsWithChildren, useMemo } from "react";
import {
  MutationCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useToast } from "./toast/ToastProvider";
import { useTranslation } from "react-i18next";

export const AppQueryProvider = (props: PropsWithChildren) => {
  const { children } = props;
  const { addToast } = useToast();
  const { t } = useTranslation();

  const errors = useMemo<Record<number, string>>(() => {
    return [401, 403, 404].reduce(
      (p, x) => ({ ...p, [x]: t(`ERROR.${x}`) }),
      {},
    );
  }, [t]);

  const queryClient = useMemo(
    () =>
      new QueryClient({
        mutationCache: new MutationCache({
          onError(error) {
            const apiError = error as unknown as AxiosError;
            const text = apiError?.response?.status
              ? errors[apiError.response.status]
              : "Unexpected error occured. Please contact support.";
            addToast(text, "ERROR");
          },
        }),
      }),
    [addToast, errors],
  );
  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};
