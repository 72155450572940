import {
  MutableRefObject,
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
  useRef,
} from "react";

interface MapDragState {
  current: MutableRefObject<string | null>;
  setCurrent: (input: string | null) => void;
}

const MapDragContext = createContext<MapDragState>({
  current: { current: null },
  setCurrent: () => {},
});

interface MapProviderProps {}

export const MapDragProvider = (props: PropsWithChildren<MapProviderProps>) => {
  const currentRef = useRef<string | null>(null);

  const value = useMemo(() => {
    return {
      current: currentRef,
      setCurrent: (input: string | null) => {
        currentRef.current = input;
      },
    };
  }, []);

  return (
    <MapDragContext.Provider value={value}>
      {props.children}
    </MapDragContext.Provider>
  );
};

export const useMapDragHandler = () => {
  const context = useContext(MapDragContext);
  return context;
};
