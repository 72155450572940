import { useState, useCallback } from "react";
import { CreateGameModal } from "./modal/CreateGameModal";
import React from "react";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import { PaymentTooltip } from "../../shared/components/ui/PaymentTooltip";
import { useUser } from "../auth/useUser";
import { DefaultGame } from "../../model/Game";

interface GameListActionsProps {
  games: DefaultGame[] | undefined;
}
export const GameListActions = (props: GameListActionsProps) => {
  const { games = [] } = props;
  const [modal, setModal] = useState("");
  const { t } = useTranslation();
  const handleClose = useCallback(() => setModal(""), []);
  const user = useUser();
  const ownGames = games.filter((x) => user.id === x.gameMasterId);
  return (
    <React.Fragment>
      <PaymentTooltip
        title={t("INFO.UPGRADE")}
        visible={user.licensePlanData.gameCount <= ownGames.length}
      >
        <Button
          disabled={user.licensePlanData.gameCount <= ownGames.length}
          sx={{
            height: "100%",
          }}
          variant="outlined"
          onClick={() => setModal("create")}
          startIcon={<AddIcon />}
          size="large"
        >
          {t("GAME.CREATE")}
        </Button>
      </PaymentTooltip>

      <CreateGameModal open={modal === "create"} handleClose={handleClose} />
    </React.Fragment>
  );
};
