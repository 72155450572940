import React, { PropsWithChildren } from "react";
import MusicPlayer from "../game-general/components/MusicPlayer";

export const AppMusicProvider = (props: PropsWithChildren) => {
  return (
    <React.Fragment>
      <MusicPlayer />
      {props.children}
    </React.Fragment>
  );
};
