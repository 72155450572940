import "./App.css";
import { useCuni } from "./shared/services/cuni/useCuni";
import { AppRouter } from "./shared/components/router/AppRouter";
import { AppProvider } from "./shared/providers/AppProvider";
import { useBackgroundImage } from "./shared/hooks/useBackgoundImage";

(function () {
  const originalSetItem = localStorage.setItem;
  localStorage.setItem = function (key, value) {
    const event = new Event("localStorageChange");
    // @ts-ignore
    event.key = key;
    // @ts-ignore
    event.newValue = value;
    document.dispatchEvent(event);
    // @ts-ignore
    originalSetItem.apply(this, arguments);
  };
})();

function App() {
  useCuni();
  useBackgroundImage();
  return (
    <AppProvider>
      <AppRouter />
    </AppProvider>
  );
}

export default App;
