import { useFormContext } from "react-hook-form";
import { ColorPicker } from "../../../../shared/components/ui/color-picker/ColorPicker";
import { ActionFormValues } from "../ActionForm";

interface ColorPickerFieldProps {
  onChange?: (color?: string) => void;
}

export const ColorPickerField = (props: ColorPickerFieldProps) => {
  const form = useFormContext<ActionFormValues>();
  return (
    <ColorPicker
      selected={form.watch("color")}
      onColorChange={(color) => {
        form.setValue("color", color);
        props.onChange?.(color);
      }}
    />
  );
};
