import { useQuery } from "@tanstack/react-query";
import { config } from "../../../config";
import { Deck } from "../../../model/Project";
import { apiClient } from "../../../shared/services/apiClient";
import { Button } from "@mui/material";

interface ProjectDeckDetailViewProps {
  deckId: number;
}

export const ProjectDeckDetailView = (props: ProjectDeckDetailViewProps) => {
  const { deckId } = props;
  const { data: deck } = useProjectDeck(deckId);

  console.debug(deck)
  const add = () => {};
  return (
    <div>
      <Button variant="outlined" onClick={add}>
        Scan card
        {/* {t("CARD.DECK_ADD")} */}
      </Button>
    </div>
  );
};

const useProjectDeck = (id: number | undefined) => {
  return useQuery({
    queryKey: ["deck"],
    queryFn: () =>
      apiClient
        .get<Deck>(`${config.baseUrl}/api/deck/${id}`)
        .then((res) => res.data),
  });
};
