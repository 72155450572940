import React, { PropsWithChildren, useMemo } from "react";
import {
  Box,
  Typography,
  Paper,
  IconButton,
  Button,
  TextField,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useDrag, useDrop } from "react-dnd";
import { useTranslation } from "react-i18next";
import { GameCheckbox } from "./GameCheckbox";
import { useGame } from "../../character/hooks/useGame";
import { cuni } from "../../../shared/services/cuni/cuni";
import { Conditional } from "../../../shared/components/logic/Conditional";
import {
  GameLayout,
  GameLayoutPanel,
  GameLayoutPanelType,
} from "../../../model/Game";

interface Item {
  id: string;
  name: string;
  children?: JSX.Element;
}

interface PanelProps {
  id: string;
  items: Item[];
  minWidth?: number;
  minHeight?: number;
  onDrop: (item: Item, panelId: string) => void;
  isVisible: boolean;
  onToggleVisibility: () => void;
  size?: number;
  onSizeChange?: (input: number) => void;
  sizeY?: number;
  onYSizeChange?: (input: number) => void;
}

const ItemTypes = {
  ITEM: "item",
};

const DraggableItem: React.FC<{ item: Item }> = ({ item }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.ITEM,
    item,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <Paper
      ref={drag}
      className="paper-outline"
      sx={{
        padding: 1,
        marginBottom: 1,
        opacity: isDragging ? 0.8 : 1,
        cursor: "move",
      }}
    >
      {item.name}
      <Conditional condition={item.children}>
        <Box>{item.children}</Box>
      </Conditional>
    </Paper>
  );
};

const Panel: React.FC<PropsWithChildren<PanelProps>> = ({
  id,
  items,
  onDrop,
  minWidth,
  minHeight,
  isVisible,
  onToggleVisibility,
  size,
  sizeY,
  onSizeChange,
  onYSizeChange,
  children,
}) => {
  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.ITEM,
    drop: (item: Item) => {
      onDrop(item, id);
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  const { t } = useTranslation();

  return (
    <Box
      ref={drop}
      sx={{
        position: "relative",
        border: "1px solid gray",
        borderRadius: "4px",
        padding: 2,
        minWidth: minWidth ?? 200,
        minHeight: minHeight ?? 300,
        marginRight: 2,
        borderColor: isOver ? "white" : "gray",
      }}
    >
      <Typography variant="h6" gutterBottom style={{ paddingBottom: "3rem" }}>
        {t("LAYOUT." + id)}
      </Typography>
      <Conditional condition={onSizeChange}>
        <>
          <TextField
            label={t("COMMON.WIDTH")}
            sx={{
              position: "absolute",
              top: 54,
              left: "1rem",
              width: 80,
            }}
            size="small"
            type="number"
            value={size}
            onChange={(e) => onSizeChange?.(parseInt(e.target.value))}
          />
          <TextField
            label={t("COMMON.HEIGHT")}
            sx={{
              position: "absolute",
              top: 54,
              left: "7rem",
              width: 80,
            }}
            size="small"
            type="number"
            value={sizeY}
            onChange={(e) => onYSizeChange?.(parseFloat(e.target.value))}
          />
        </>
      </Conditional>
      <IconButton
        onClick={onToggleVisibility}
        sx={{
          position: "absolute",
          top: 8,
          right: 8,
        }}
      >
        {isVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
      </IconButton>
      {isVisible &&
        items.map((item) => <DraggableItem key={item.id} item={item} />)}
    </Box>
  );
};

export const BOARD_LAYOUT: GameLayout = {
  drawer: { hidden: true, panels: [], size: 9, sizeY: 1 },
  left: {
    hidden: false,
    panels: ["character"],
    collapsed: true,
    size: 2,
    sizeY: 1,
  },
  middle: { hidden: false, panels: ["table"], size: 5, sizeY: 1 },
  right: { hidden: true, panels: ["story", "chat"], size: 2, sizeY: 1 },
};

export const DEFAULT_LAYOUT: GameLayout = {
  drawer: { hidden: true, panels: [], size: 9, sizeY: 1 },
  left: { hidden: false, panels: ["character", "chat"], size: 2, sizeY: 1 },
  middle: { hidden: false, panels: ["table"], size: 5, sizeY: 1 },
  right: { hidden: false, panels: ["story"], size: 2, sizeY: 1 },
};

export const LayoutView: React.FC = () => {
  const { t } = useTranslation();

  // State to manage items in each panel
  const lookUp = useMemo(() => {
    return {
      character: {
        name: t("GAME.CHARACTER_LEFT"),
        children: (
          <GameCheckbox
            label={t("GAME.HIDE_ACTIONS")}
            parameter="hidePlayerActions"
          />
        ),
      },
      table: { name: t("GAME.TABLE") },
      chat: {
        name: t("GAME.CHAT"),
        children: (
          <Box display="flex" flexDirection="column">
            <GameCheckbox
              label={t("GAME.SHOW_USER_CHAT")}
              parameter="showUserChat"
            />
            <GameCheckbox
              label={t("GAME.SHOW_PLAYER_CHAT")}
              parameter="showPlayerChat"
            />
            <GameCheckbox
              label={t("GAME.SHOW_LOG_CHAT")}
              parameter="showLogChat"
            />
            <GameCheckbox
              label={t("GAME.SHOW_MAP_CHAT")}
              parameter="showMapChat"
            />
          </Box>
        ),
      },
      story: { name: t("GAME.STORY_RIGHT") },
    };
  }, [t]);

  const { game } = useGame();
  if (!game.root) return null;

  const handleDrop = (item: Item, panelKey: string) => {
    const panelId = panelKey as "left" | "middle" | "right" | "drawer";
    const layout = game.root.layout || DEFAULT_LAYOUT;
    // Remove item from its current panel
    Object.entries(layout).forEach(([_, panel]: [string, GameLayoutPanel]) => {
      panel.panels = panel.panels?.filter((i) => i !== item.id) || [];
    });

    layout[panelId].panels ??= [];
    layout[panelId].panels = [
      ...(layout[panelId].panels || []),
      item.id as GameLayoutPanelType,
    ];
    cuni.updateGame("layout", layout);
  };

  const togglePanelVisibility = (
    panelId: "left" | "middle" | "right" | "drawer",
  ) => {
    game.root.layout ??= DEFAULT_LAYOUT;
    game.root.layout[panelId].hidden = !game.root.layout[panelId].hidden;
    cuni.updateGame("layout", game.root.layout);
  };

  const handlePanelSizeChange = (
    panelId: "left" | "middle" | "right" | "drawer",
    size: number,
  ) => {
    game.root.layout ??= DEFAULT_LAYOUT;
    game.root.layout[panelId].size = size;
    cuni.updateGame("layout", game.root.layout);
  };

  const handlePanelYSizeChange = (
    panelId: "left" | "middle" | "right" | "drawer",
    size: number,
  ) => {
    game.root.layout ??= DEFAULT_LAYOUT;
    game.root.layout[panelId].sizeY = size;
    cuni.updateGame("layout", game.root.layout);
  };

  const layout = game.root.layout || DEFAULT_LAYOUT;

  const getPanels = (key: keyof GameLayout) => {
    return (
      layout[key].panels?.map(
        (x) =>
          ({
            id: x,
            ...lookUp[x],
          } as Item),
      ) || []
    );
  };

  const reset = () => {
    cuni.updateGame("layout", DEFAULT_LAYOUT);
  };

  return (
    <Box sx={{ padding: 4 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          width: "max-content",
        }}
      >
        <Button variant="outlined" sx={{ maxWidth: 200 }} onClick={reset}>
          Reset
        </Button>

        <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr" }}>
          {(["left", "middle", "right"] as ("left" | "middle" | "right")[]).map(
            (x) => (
              <Panel
                key={x}
                id={x}
                items={getPanels(x)}
                onDrop={handleDrop}
                isVisible={!layout[x].hidden}
                onToggleVisibility={() => togglePanelVisibility(x)}
                size={layout[x].size}
                onSizeChange={(newSize) => handlePanelSizeChange(x, newSize)}
                sizeY={layout[x].sizeY}
                onYSizeChange={(newSize) => handlePanelYSizeChange(x, newSize)}
              />
            ),
          )}
        </Box>
        <Panel
          id="drawer"
          items={getPanels("drawer")}
          onDrop={handleDrop}
          minHeight={100}
          isVisible={!layout.drawer.hidden}
          onToggleVisibility={() => togglePanelVisibility("drawer")}
        />
      </Box>
    </Box>
  );
};
