import { observer } from "mobx-react";
import React, { CSSProperties, useRef, useState } from "react";
import { Character, Game } from "../../model/Game";
import { rootStore } from "../../store/RootStore";
import { GenericTemplateView } from "./components/generic/GenericTemplateView";
import { GenericPanelView } from "./components/generic/GenericPanelView";
import { Conditional } from "../../shared/components/logic/Conditional";
import "./Character.css";
import {
  CharacterTabProvider,
  useCharacterTabs,
} from "./components/generic/CharacterTabProvider";
import { GenericTabPanels } from "./components/generic/GenericTabPanels";
import { CharacterActions } from "./components/generic/CharacterActions";
import { QuickEditModalProvider } from "../quick-edit/hooks/useQuickEdit";
import { useGame } from "./hooks/useGame";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { Box, Button, Tooltip } from "@mui/material";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { useGamePermission } from "../../shared/hooks/useGamePermission";
import { SwitchPanelView } from "../schema/SwitchPanelView";
import FullScreen from "../../shared/components/ui/full-screen/FullScreen";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import { mapCharacterToTab } from "../../model/utils/charaterUtils";
import { clsx } from "../../shared/utils/clsx";
import { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { Measurer } from "../../shared/components/ui/Measurer";
import { layoutStorage } from "../../shared/services/storage";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useTranslation } from "react-i18next";
import PrintIcon from "@mui/icons-material/Print";
import { PaymentTooltip } from "../../shared/components/ui/PaymentTooltip";
import { usePlanPermissions } from "../../shared/hooks/usePlanPermissions";

export const WORKSPACE_BREAKPOINTS = { lg: 1200, md: 996, sm: 768, xs: 480 };

interface ICharacterViewProps {
  game: Game | null;
  style?: CSSProperties;
  minimized?: boolean;
  setMinimized?: (input: boolean) => void;
}

const CharacterView: React.FC<ICharacterViewProps> = (
  props: ICharacterViewProps,
) => {
  const { game, style, setMinimized, minimized } = props;
  const [isFullscreen, setIsFullscreen] = useState(false);
  const { t } = useTranslation();

  const { tab, tabId } = useCharacterTabs();
  const navigate = useNavigate();

  const { hasGamePermission } = useGamePermission();
  const { hasPermission } = usePlanPermissions();

  if (!game) return null;

  return (
    <FullScreen
      isFullscreen={isFullscreen}
      onClose={() => setIsFullscreen(false)}
      panel={
        <>
          <PaymentTooltip
            title={t("INFO.UPGRADE")}
            visible={!hasPermission("canExportPdf")}
          >
            <Button
              variant="contained"
              startIcon={<PrintIcon />}
              disabled={!hasPermission("canExportPdf")}
              //@ts-ignore
              onClick={() => window.print2()}
            >
              {t("COMMON.PRINT")}
            </Button>
          </PaymentTooltip>
        </>
      }
    >
      <Conditional
        condition={game.me}
        success={(character) => (
          <QuickEditModalProvider character={character}>
            <div
              className={clsx("char__container", {
                "char__container-grid": isFullscreen,
              })}
              style={{
                ...style,
                paddingTop: isFullscreen ? "1rem" : undefined,
              }}
            >
              {isFullscreen ? (
                <ResponsiveLayout character={character} />
              ) : (
                <>
                  <Conditional condition={hasGamePermission("canEditGame")}>
                    <Box display="flex" gap="0.25rem">
                      <Button
                        sx={{ flex: 1 }}
                        variant="contained"
                        onClick={() => navigate(`/game/${game.id}/modify`)}
                      >
                        {t("GAME.EDIT")}
                      </Button>

                      <Tooltip title="Full screen">
                        <Button
                          variant="contained"
                          size="small"
                          onClick={() => setIsFullscreen(true)}
                        >
                          <ZoomOutMapIcon />
                        </Button>
                      </Tooltip>
                      <Conditional condition={setMinimized}>
                        <Tooltip title="Toggle collapse">
                          <Button
                            variant="contained"
                            size="small"
                            onClick={() => setMinimized?.(true)}
                          >
                            {minimized ? (
                              <AddIcon />
                            ) : (
                              <KeyboardDoubleArrowLeftIcon />
                            )}
                          </Button>
                        </Tooltip>
                      </Conditional>
                    </Box>
                  </Conditional>
                  <Conditional
                    condition={character.__tabs}
                    success={(tabs) => <GenericTabPanels tabs={tabs} />}
                  />
                  <Conditional
                    condition={tab.title === "main"}
                    success={() => (
                      <React.Fragment>
                        <Conditional condition={!game.root.hidePlayerActions}>
                          <CharacterActions />
                        </Conditional>
                        <GenericTemplateView template={character} path="" />
                        <Conditional
                          condition={game.me?.__panels}
                          success={(panels) => (
                            <GenericPanelView panels={panels} path="__panels" />
                          )}
                        />
                      </React.Fragment>
                    )}
                    failure={() => (
                      <Conditional
                        condition={tab.__panels}
                        success={(panels) => (
                          <GenericPanelView
                            panels={panels}
                            path={`__tabs.${tabId - 1}.__panels`}
                          />
                        )}
                      />
                    )}
                  />
                </>
              )}
            </div>
          </QuickEditModalProvider>
        )}
      />
    </FullScreen>
  );
};

export default observer(
  (
    props: Pick<ICharacterViewProps, "style" | "setMinimized" | "minimized">,
  ) => {
    const { game } = useGame();
    const { t } = useTranslation();

    const { hasGamePermission } = useGamePermission();
    const navigate = useNavigate();
    return (
      <Conditional
        condition={game.me}
        failure={() => (
          <Conditional condition={hasGamePermission("canEditGame")}>
            <div className="paper char__container" style={{ height: "unset" }}>
              <Button
                variant="contained"
                onClick={() => navigate(`/game/${game.id}/modify`)}
              >
                {t("GAME.EDIT")}
              </Button>
            </div>
          </Conditional>
        )}
      >
        <CharacterTabProvider character={game.me}>
          <CharacterView game={rootStore.game} {...props} />
        </CharacterTabProvider>
      </Conditional>
    );
  },
);

interface ResponsiveLayoutProps {
  character: Character;
}

//@ts-ignore
window.print2 = async () => {
  const doc = new jsPDF("p", "pt", "a4");
  // const div = window.document.getElementsByClassName("App")[0];
  var source = window.document.getElementsByClassName(
    "char__container",
  )[0] as HTMLElement;
  // Store original styles
  const originalHeight = source.style.height;
  const originalOverflow = source.style.overflow;
  const elementsToHide = document.querySelectorAll(".paper__menu");
  const moreToHide = document.querySelectorAll(".react-resizable-handle ");
  moreToHide.forEach((element: any) => {
    element.style.display = "none";
  });
  elementsToHide.forEach((element: any) => {
    element.style.display = "none";
  });

  const papers = document.querySelectorAll(".paper");
  papers.forEach((element: any) => {
    element.style.background = "none";
    element.style.color = "black";
    element.style.border = "1px dashed black";
    element.style.borderRadius = "8px";
  });

  // Expand the element to show all content
  source.style.height = "auto";
  source.style.overflow = "visible";
  await html2canvas(source).then((canvas) => {
    const imgWidth = 595.28; // A4 width in points
    const pageHeight = 841.89; // A4 height in points
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    let heightLeft = imgHeight;

    let position = 0;

    // Add the first page
    doc.addImage(
      canvas.toDataURL("image/png"),
      "PNG",
      6,
      position,
      imgWidth,
      imgHeight,
    );
    heightLeft -= pageHeight;

    // Add new pages if necessary
    while (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      doc.addPage();
      doc.addImage(
        canvas.toDataURL("image/png"),
        "PNG",
        6,
        position,
        imgWidth,
        imgHeight,
      );
      heightLeft -= pageHeight;
    }

    doc.save("test.pdf"); // Save and download the PDF
    doc.output("dataurlnewwindow"); // Open the PDF in a new window
  });
  // Restore original styles
  source.style.height = originalHeight;
  source.style.overflow = originalOverflow;

  // Show elements with class 'paper__menu--handler' again
  elementsToHide.forEach((element: any) => {
    element.style.display = "";
  });

  papers.forEach((element: any) => {
    element.style.removeProperty("background");
    element.style.removeProperty("color");
    element.style.removeProperty("border");
    element.style.removeProperty("borderRadius");
  });
};

const rowHeight = 30;
const cols = { lg: 3, md: 2, sm: 1, xs: 1, xss: 1 };
const ResponsiveGridLayout = WidthProvider(Responsive);
export const ResponsiveLayout = (props: ResponsiveLayoutProps) => {
  const { character } = props;

  const tabs = [mapCharacterToTab(character), ...(character.__tabs || [])];
  const [layouts, setLayouts] = useState<ReactGridLayout.Layouts>(
    layoutStorage.get<ReactGridLayout.Layouts>() || {},
  );
  const onLayoutChange = (_: any, newLayouts: any) => {
    setLayouts(newLayouts);
    // localStorage is ommited for now
    layoutStorage.set(newLayouts);
  };

  const sizeRef = useRef<Record<string, number>>({});
  const onChange = (index: string, height: number) => {
    if (sizeRef.current[index] === height) return;
    sizeRef.current[index] = height;
    const entries = Object.entries(sizeRef.current);
    if (entries.length === tabs.length) {
      const createLayout = (colsKey: keyof typeof cols) => {
        const offsets = Array(cols[colsKey]).fill(0);
        const layout = entries.map(([i, size], index) => {
          const key = index % cols[colsKey];
          const currentH = offsets[key];
          const value = Math.ceil(size / rowHeight);
          offsets[key] += value;
          return {
            i,
            h: value,
            w: 1,
            x: key,
            y: currentH,
          };
        });
        return layout;
      };
      const newLayouts = {
        lg: createLayout("lg"),
        md: createLayout("md"),
        sm: createLayout("sm"),
        xs: createLayout("xs"),
        xss: createLayout("xss"),
      };
      setLayouts(newLayouts);
    }
  };
  return (
    /* @ts-ignore */
    <ResponsiveGridLayout
      layouts={layouts}
      breakpoints={WORKSPACE_BREAKPOINTS}
      cols={cols}
      rowHeight={rowHeight}
      onLayoutChange={onLayoutChange}
      isDraggable
      isDroppable
      compactType="vertical"
      margin={[10, 0]}
      containerPadding={[0, 0]}
    >
      {tabs.map((x, idx) => (
        <div key={idx.toString()}>
          <Measurer
            onChange={(height) => {
              onChange(idx.toString(), height);
            }}
          >
            {({ measureRef }) => (
              <div
                ref={measureRef}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                }}
              >
                <SwitchPanelView character={character} tabId={idx} tab={x} />
              </div>
            )}
          </Measurer>
        </div>
      ))}
    </ResponsiveGridLayout>
  );
};
