import { Button } from "@mui/material";
import React, { useState } from "react";
import { AreYouSureModal } from "../../../shared/components/modal/AreYouSureModal";
import { cuni } from "../../../shared/services/cuni/cuni";
import { Character } from "../../../model/Game";

interface Props {
  path: string;
  label: string;
  handleClose: () => void;
  character: Character;
}
export const DeletePropertyButton = (props: Props) => {
  const { label, path, handleClose, character } = props;
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <Button variant="outlined" color="error" onClick={() => setOpen(true)}>
        Delete
      </Button>
      <AreYouSureModal
        title="Delete property"
        content={`Are you sure you want to delete property ${label} ?`}
        handleClose={() => setOpen(false)}
        confirmText="Delete"
        confirmAction={() => {
          cuni.update(character.key, path, null);
          handleClose();
        }}
        open={open}
      />
    </React.Fragment>
  );
};
