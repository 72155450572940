import {
  Button,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  TextField,
} from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { ModalProps } from "../../../../shared/components/modal/ModalProps";
import "./SurveyModal.css";
import { Controller, useForm, useFormContext } from "react-hook-form";
import React from "react";
import { Conditional } from "../../../../shared/components/logic/Conditional";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { ActionsController } from "./ActionsController";
import { useMutation } from "@tanstack/react-query";
import { Action, Survey } from "../../../../model/Survey";
import { config } from "../../../../config";
import { apiClient } from "../../../../shared/services/apiClient";
import { cuni } from "../../../../shared/services/cuni/cuni";
import { WebHookType } from "../../../../model/WebHookMessage";
import { ActionFormValues } from "../ActionForm";
import { useTranslation } from "react-i18next";

type SurveyModalProps = ModalProps;

// TODO
// 1. Consider load old surveys
// 2. Consider load old actions
// 3. Consider make a chain of consequences of actions (some day after...)
// 4. Quick action checkbox
// 5. Adress survey via Message ReceiverId

interface SurveyForm {
  title: string;
  description: string;
  actions: Action[];

  quickAction: boolean;
  timeout: number;
}

export const SurveyModal = (props: SurveyModalProps) => {
  const { open, handleClose } = props;

  const { t } = useTranslation();
  const { watch } = useFormContext<ActionFormValues>();

  const { register, handleSubmit, control, reset } = useForm<SurveyForm>({
    defaultValues: {
      title: "",
      actions: [],
      quickAction: false,
      timeout: 5,
    },
  });

  const onClose = () => {
    reset();
    handleClose();
  };

  const { mutate } = useSurveyPost();

  const onSubmit = (form: SurveyForm) => {
    const dto: Survey = {
      ...form,
      actions: form.actions.map((action) => ({
        action,
      })),
    };
    mutate(dto, {
      onSuccess(data, variables, context) {
        cuni.pushMessage((game) => {
          return {
            type: WebHookType.UserText,
            color: "aquamarine",
            subType: "SURVEY",
            user: `Survey`,
            receiverId: watch("receiverId"),
            message: dto.description,
            contextObjectId: data.data.id,
          };
        });
      },
      // TODO: on Error
    });
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box className="modal">
          <Typography variant="h6" component="h2">
            {t("SURVEY.SURVEY")}
          </Typography>
          <Typography id="modal-avatar-description" sx={{ mt: 2 }}>
            {t("SURVEY.SURVEY_DESC")}
          </Typography>

          <TextField
            multiline
            label={t("COMMON.QUESTION")}
            {...register("description")}
          />
          <Controller
            name="quickAction"
            control={control}
            render={({ field }) => (
              <React.Fragment>
                <FormControlLabel
                  control={
                    <Checkbox checked={field.value} onChange={field.onChange} />
                  }
                  label={t("SURVEY.QUICK_ACTION")}
                />
                <Conditional condition={field.value}>
                  <TextField
                    type="number"
                    label={t("SURVEY.TIMEOUT")}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccessTimeIcon />
                        </InputAdornment>
                      ),
                    }}
                    {...register("timeout")}
                  />
                </Conditional>
              </React.Fragment>
            )}
          />

          <Controller
            name="actions"
            control={control}
            render={({ field }) => (
              <ActionsController
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
          <Button type="submit" variant="contained">
            {t("COMMON.SEND")}
          </Button>
          <Button onClick={onClose}>{t("COMMON.CLOSE")}</Button>
        </Box>
      </form>
    </Modal>
  );
};

export const useSurveyPost = () => {
  return useMutation({
    mutationFn: (data: Survey) =>
      apiClient.post<Survey>(`${config.baseUrl}/api/survey`, data),
  });
};
