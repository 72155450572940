import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { ModalProps } from "../../shared/components/modal/ModalProps";
import { Character } from "../../model/Game";
import { Autocomplete, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { useEffect, useMemo, useRef } from "react";
import { flattenDict } from "./flattenDict";
import { useCuni } from "../../shared/services/cuni/useCuni";
import { DeletePropertyButton } from "./components/DeletePropertyButton";
import { QuickEditState } from "./hooks/useQuickEdit";
import { Conditional } from "../../shared/components/logic/Conditional";
import { TypedInput } from "./components/TypedInput";
import { useTranslation } from "react-i18next";

// TODO: Table panel generic has no title visible
// TODO: Add other quick edits like Table or richText

interface QuickEditFormValues {
  path?: string;
  value?: string | number;
}

type QuickEditModalProps = ModalProps & {
  character: Character;
} & Partial<QuickEditState>;

export const NOT_EDITABLE_CHARACTER_KEYS = [
  "__userId",
  "key",
  "visible",
  "__contextName",
  "__type",
  "__interactions",
  "__note",
  "__avatarUrl",
  "__path",
];

function canBeParsedToFloat(str: string) {
  const floatRegex = /^[+-]?\d+(\.\d+)?([eE][+-]?\d+)?$/;
  return floatRegex.test(str);
}

export const QuickEditModal = (props: QuickEditModalProps) => {
  const {
    open,
    handleClose,
    character,
    path,
    value,
    label: forceLabel,
    type,
  } = props;
  const { handleSubmit, reset, watch, setValue } = useForm<QuickEditFormValues>(
    {
      defaultValues: {
        path,
        value,
      },
    },
  );
  const { t } = useTranslation();

  useEffect(() => {
    reset({ path, value });
  }, [reset, path, value]);

  const { cuni } = useCuni();
  const onSubmit = (form: QuickEditFormValues) => {
    if (character.key && form.path && form.value) {
      const finalValue = canBeParsedToFloat(`${form.value}`)
        ? parseFloat(`${form.value}`)
        : form.value;
      cuni.update(character.key, form.path, finalValue);
    } else throw new Error("You tried submit with nonsense data");
    handleClose();
  };

  const hints = useMemo(() => {
    const flatten = flattenDict(character);
    return Object.entries(flatten)
      .filter(
        (x) =>
          !NOT_EDITABLE_CHARACTER_KEYS.some(
            (y) => x[0].startsWith(y) || x[0].includes(y),
          ),
      )
      .map((x) => ({
        label: mapDictToLabel(x[0], flatten, undefined, t("COMMON.TITLE_OF")), // + "[" + x[0] + "]",
        key: x[0],
        value: x[1],
      }));
  }, [character, t]);

  const pathSelected = watch("path");

  // when path has changed - look text panel
  useEffect(() => {
    const hint = hints.find((x) => x.key === pathSelected);
    setValue("value", hint?.value);
  }, [pathSelected, hints, setValue]);

  const valueSelected = watch("value");
  const hint = hints.find((x) => x.key === pathSelected);
  const displayPath = forceLabel || hint?.label || pathSelected;

  const bigText = (valueSelected?.toString()?.length ?? 0) > 25;
  const formRef = useRef<HTMLFormElement | null>(null);

  return (
    <Modal open={open} onClose={handleClose}>
      <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
        <Box className="modal">
          <Box
            display="flex"
            gap="1rem"
            flexDirection={bigText ? "column" : undefined}
          >
            <Autocomplete
              sx={{ flex: 1 }}
              disablePortal
              id="combo-box"
              options={hints}
              onChange={(x, y) => {
                if (y?.key) {
                  setValue("path", y.key);
                  const newValue = hints.find((x) => x.key === y.key)?.value;
                  setValue("value", newValue);
                } else throw new Error("There should be already a value");
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={displayPath}
                  placeholder={t("COMMON.FIND_PROPERTY")}
                />
              )}
            />
            <Conditional
              condition={type}
              success={() => (
                <TypedInput
                  {...props}
                  hints={hints}
                  value={valueSelected || props.value}
                  onChange={(value) => {
                    setValue("value", value);
                    handleSubmit(onSubmit)();
                  }}
                />
              )}
              failure={() => (
                <TextField
                  autoFocus
                  onFocus={(e) => {
                    setTimeout(() => {
                      e.target.select();
                    }, 0);
                  }}
                  sx={{ flexBasis: 200 }}
                  value={valueSelected}
                  multiline={bigText}
                  maxRows={bigText ? 5 : undefined}
                  rows={5}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                      handleSubmit(onSubmit)();
                    }
                  }}
                  onChange={(e) => {
                    setValue("value", e.target.value);
                  }}
                />
              )}
            />
            <input hidden type="submit" />
          </Box>
          {pathSelected && displayPath && (
            <DeletePropertyButton
              character={character}
              path={pathSelected}
              label={displayPath}
              handleClose={handleClose}
            />
          )}
        </Box>
      </form>
    </Modal>
  );
};
function mapDictToLabel(
  key: string,
  character: any,
  removedSufix: string = "",
  titleOf?: string,
): string {
  const fullKey = removedSufix ? `${removedSufix}.${key}` : key;
  if (key.startsWith("__")) {
    const parts = key.split(".");
    if (key.endsWith("title") && parts.length === 3) {
      return `${titleOf} ${character[fullKey]}`;
    }
    const begin = parts.slice(0, 2).join(".");
    const fullBegin = removedSufix ? `${removedSufix}.${begin}` : begin;
    const beginLabel =
      character[`${fullBegin}.title`] || character[`${fullBegin}.name`];
    const rest = parts.slice(2).join(".");
    if (parts.slice(2).length < 2) return `${beginLabel} -> ${rest}`;
    const restLabel = mapDictToLabel(rest, character, fullBegin, titleOf);
    return `${beginLabel} -> ${restLabel}`;
  }

  return key;
}
