import { useMutation } from "@tanstack/react-query";
import { config } from "../../../config";
import { apiClient } from "../../../shared/services/apiClient";

export const useDeleteGame = () => {
  return useMutation({
    mutationFn: (data: number) =>
      apiClient.delete(`${config.baseUrl}/game/${data}`),
  });
};
