import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { Controller, useForm } from "react-hook-form";
import { ModalProps } from "../../../shared/components/modal/ModalProps";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { config } from "../../../config";
import { apiClient } from "../../../shared/services/apiClient";
import { useTranslation } from "react-i18next";
import { DefaultGame, Game } from "../../../model/Game";
import {
  BOARD_LAYOUT,
  DEFAULT_LAYOUT,
} from "../../game-general/components/LayoutView";
import { DEFAULT_MAP } from "../../map-hex/components/AddMapModal";
import { Conditional } from "../../../shared/components/logic/Conditional";
import SelectField, {
  LoseOption,
} from "../../../shared/components/logic/SelectField";
import { useGameAll } from "../GameList";
import { usePlanPermissions } from "../../../shared/hooks/usePlanPermissions";
import { PaymentTooltip } from "../../../shared/components/ui/PaymentTooltip";
import { file } from "../../../shared/utils/file";
import AttachFileIcon from "@mui/icons-material/AttachFile";

type GameModalProps = ModalProps;

// TODO
// 1. Consider load old surveys
// 2. Consider load old actions
// 3. Consider make a chain of consequences of actions (some day after...)
// 4. Quick action checkbox
// 5. Adress survey via Message ReceiverId

interface CreateGameForm {
  name: string;
  url?: string;
  type: "board" | "rpg";
  payload?: string;
  useTemplate?: boolean;
  template?: LoseOption;
  game?: DefaultGame & { fileName: string };
}

export const CreateGameModal = (props: GameModalProps) => {
  const { open, handleClose } = props;
  const { t } = useTranslation();

  const { hasPermission } = usePlanPermissions();

  const { data: games } = useGameAll();

  const { register, handleSubmit, reset, control, watch, setValue } =
    useForm<CreateGameForm>({
      defaultValues: { type: "board" },
    });

  const onClose = () => {
    reset();
    handleClose();
  };

  const queryClient = useQueryClient();

  const { mutate } = useGamePost();
  const onSubmit = async (form: CreateGameForm) => {
    if (form.game) {
      form.payload = JSON.stringify(form.game);
    } else if (form.template) {
      const result = await apiClient.get<Game>(
        `${config.baseUrl}/game/${form.template.key}`,
      );
      const initialGame: Pick<DefaultGame, "root" | "characters"> = {
        root: result.data.root,
        characters: {},
      };

      form.url = result.data.url!;
      form.payload = JSON.stringify(initialGame);
    } else if (form.type === "board") {
      const initialGame: Pick<DefaultGame, "root" | "characters"> = {
        root: {
          showAllChat: true,
          showLogChat: true,
          showMapChat: true,
          showPlayerChat: true,
          gmNote: "",
          charactersNames: [],
          hidePlayerActions: false,
          layout: form.type === "board" ? BOARD_LAYOUT : DEFAULT_LAYOUT,
          maps: [{ ...DEFAULT_MAP, active: true }],
          overrideAvatars: false,
          overrideMusic: false,
          playerEditSelf: true,
          showChapters: false,
          showUserChat: false,
        },
        characters: {},
      };

      form.payload = JSON.stringify(initialGame);
    }

    mutate(form, {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["game-all"],
          exact: true,
        });
      },
    });
    onClose();
  };

  const name = watch("name");

  const importGame = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const result = (await file.import(event)) as DefaultGame & {
      fileName: string;
    };
    setValue("game", result);
    const url = watch("url");
    if (!name && result.name)
      setValue("name", result.name, { shouldTouch: true });
    if (!url && result.url) setValue("url", result.url, { shouldTouch: true });
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-avatar-title"
      aria-describedby="modal-avatar-description"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box className="modal">
          <Typography variant="h6" component="h2">
            {t("GAME.GAME")}
          </Typography>
          <Typography id="modal-avatar-description" sx={{ mt: 2 }}>
            {t("GAME.CREATE_MODAL_INFO")}
          </Typography>

          <TextField label={t("GAME.GAME_NAME")} {...register("name")} />

          <Controller
            control={control}
            name="useTemplate"
            render={({ field }) => {
              return (
                <>
                  <Conditional condition={!watch("game")}>
                    <>
                      <PaymentTooltip
                        title={t("INFO.UPGRADE")}
                        visible={!hasPermission("canUseTemplates")}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              disabled={!hasPermission("canUseTemplates")}
                              checked={field.value}
                              onChange={field.onChange}
                            />
                          }
                          label={t("GAME.TEMPLATE_USE")}
                        />
                      </PaymentTooltip>
                      <Divider
                        color="white"
                        style={{ flex: 1, marginBottom: "1rem" }}
                      />

                      <Conditional
                        condition={field.value}
                        success={() => (
                          <SelectField<LoseOption>
                            label={t("CARD_TYPES.TYPE")}
                            value={watch("template")}
                            onChange={(e: LoseOption) => {
                              setValue("template", e);
                            }}
                            options={
                              games
                                ?.filter((x) => x.isTemplate)
                                .map((x) => ({
                                  key: x.id,
                                  name: x.name,
                                })) || []
                            }
                          />
                        )}
                        failure={() => (
                          <>
                            <TextField
                              label={t("GAME.GAME_WALLPAPER_URL")}
                              {...register("url")}
                            />

                            <FormControl component="fieldset">
                              <FormLabel component="legend">
                                {t("GAME.TYPE")}
                              </FormLabel>

                              <Controller
                                name="type"
                                control={control}
                                defaultValue="board"
                                render={({ field }) => (
                                  <RadioGroup {...field}>
                                    <FormControlLabel
                                      value="board"
                                      control={<Radio />}
                                      label={t("GAME.BOARD_GAME")}
                                    />
                                    <FormControlLabel
                                      value="rpg"
                                      control={<Radio />}
                                      label={t("GAME.RPG_GAME")}
                                    />
                                  </RadioGroup>
                                )}
                              />
                            </FormControl>
                          </>
                        )}
                      />
                    </>
                  </Conditional>
                </>
              );
            }}
          />

          <Conditional
            condition={watch("game")}
            success={(game) => (
              <Typography
                display="flex"
                alignItems="center"
                py="1rem"
                gap="1rem"
              >
                <AttachFileIcon />
                {game.fileName}
              </Typography>
            )}
          />

          <input
            type="file"
            id="panelFileInput"
            style={{ display: "none" }}
            accept="application/json"
            onChange={importGame}
          />
          <Button
            variant="contained"
            disabled={!hasPermission("canExportImport")}
            onClick={() => document.getElementById("panelFileInput")?.click()}
          >
            {t("COMMON.IMPORT")}
          </Button>

          <Button type="submit" variant="contained" disabled={!name}>
            {t("GAME.CREATE")}
          </Button>
          <Button onClick={onClose}>{t("COMMON.CLOSE")}</Button>
        </Box>
      </form>
    </Modal>
  );
};

export const useGamePost = () => {
  return useMutation({
    mutationFn: (data: CreateGameForm) =>
      apiClient.post<CreateGameForm>(`${config.baseUrl}/game`, data),
  });
};
