
export const tokens = [
//   "ra",
//   "ra-lg",
//   "ra-2x",
//   "ra-3x",
//   "ra-4x",
//   "ra-5x",
//   "ra-fw",
//   "ra-ul",
//   "ra-li",
//   "ra-border",
//   "ra-spin",
//   "ra-rotate-90",
//   "ra-rotate-180",
//   "ra-rotate-270",
//   "ra-flip-horizontal",
//   "ra-flip-vertical",
//   "ra-stack",
//   "ra-stack-1x",
//   "ra-stack-2x",
//   "ra-inverse",
  "ra-acid",
  "ra-zigzag-leaf",
  "ra-archer",
  "ra-archery-target",
  "ra-arena",
  "ra-aries",
  "ra-arrow-cluster",
  "ra-arrow-flights",
  "ra-arson",
  "ra-aura",
  "ra-aware",
  "ra-axe",
  "ra-axe-swing",
  "ra-ball",
  "ra-barbed-arrow",
  "ra-barrier",
  "ra-bat-sword",
  "ra-battered-axe",
  "ra-batteries",
  "ra-battery-0",
  "ra-battery-25",
  "ra-battery-50",
  "ra-battery-75",
  "ra-battery-100",
  "ra-battery-black",
  "ra-battery-negative",
  "ra-battery-positive",
  "ra-battery-white",
  "ra-batwings",
  "ra-beam-wake",
  "ra-bear-trap",
  "ra-beer",
  "ra-beetle",
  "ra-bell",
  "ra-biohazard",
  "ra-bird-claw",
  "ra-bird-mask",
  "ra-blade-bite",
  "ra-blast",
  "ra-blaster",
  "ra-bleeding-eye",
  "ra-bleeding-hearts",
  "ra-bolt-shield",
  "ra-bomb-explosion",
  "ra-bombs",
  "ra-bone-bite",
  "ra-bone-knife",
  "ra-book",
  "ra-boomerang",
  "ra-boot-stomp",
  "ra-bottle-vapors",
  "ra-bottled-bolt",
  "ra-bottom-right",
  "ra-bowie-knife",
  "ra-bowling-pin",
  "ra-brain-freeze",
  "ra-brandy-bottle",
  "ra-bridge",
  "ra-broadhead-arrow",
  "ra-sword",
  "ra-broadsword",
  "ra-broken-bone",
  "ra-broken-bottle",
  "ra-broken-heart",
  "ra-broken-shield",
  "ra-broken-skull",
  "ra-bubbling-potion",
  "ra-bullets",
  "ra-burning-book",
  "ra-burning-embers",
  "ra-burning-eye",
  "ra-burning-meteor",
  "ra-burst-blob",
  "ra-butterfly",
  "ra-campfire",
  "ra-cancel",
  "ra-cancer",
  "ra-candle",
  "ra-candle-fire",
  "ra-cannon-shot",
  "ra-capitol",
  "ra-capricorn",
  "ra-carrot",
  "ra-castle-emblem",
  "ra-castle-flag",
  "ra-cat",
  "ra-chain",
  "ra-cheese",
  "ra-chemical-arrow",
  "ra-chessboard",
  "ra-chicken-leg",
  "ra-circle-of-circles",
  "ra-circular-saw",
  "ra-circular-shield",
  "ra-cloak-and-dagger",
  "ra-clockwork",
  "ra-clover",
  "ra-clovers",
  "ra-clovers-card",
  "ra-cluster-bomb",
  "ra-coffee-mug",
  "ra-cog",
  "ra-cog-wheel",
  "ra-cold-heart",
  "ra-compass",
  "ra-corked-tube",
  "ra-crab-claw",
  "ra-cracked-helm",
  "ra-cracked-shield",
  "ra-croc-sword",
  "ra-crossbow",
  "ra-crossed-axes",
  "ra-crossed-bones",
  "ra-crossed-pistols",
  "ra-crossed-sabres",
  "ra-crossed-swords",
  "ra-crown",
  "ra-crown-of-thorns",
  "ra-crowned-heart",
  "ra-crush",
  "ra-crystal-ball",
  "ra-crystal-cluster",
  "ra-crystal-wand",
  "ra-crystals",
  "ra-cubes",
  "ra-cut-palm",
  "ra-cycle",
  "ra-daggers",
  "ra-daisy",
  "ra-dead-tree",
  "ra-death-skull",
  "ra-decapitation",
  "ra-defibrillate",
  "ra-demolish",
  "ra-dervish-swords",
  "ra-desert-skull",
  "ra-diamond",
  "ra-diamonds",
  "ra-diamonds-card",
  "ra-dice-five",
  "ra-dice-four",
  "ra-dice-one",
  "ra-dice-six",
  "ra-dice-three",
  "ra-dice-two",
  "ra-dinosaur",
  "ra-divert",
  "ra-diving-dagger",
  "ra-double-team",
  "ra-doubled",
  "ra-dragon",
  "ra-dragon-breath",
  "ra-dragon-wing",
  "ra-dragonfly",
  "ra-drill",
  "ra-dripping-blade",
  "ra-dripping-knife",
  "ra-dripping-sword",
  "ra-droplet",
  "ra-droplet-splash",
  "ra-droplets",
  "ra-duel",
  "ra-egg",
  "ra-egg-pod",
  "ra-eggplant",
  "ra-emerald",
  "ra-energise",
  "ra-explosion",
  "ra-explosive-materials",
  "ra-eye-monster",
  "ra-eye-shield",
  "ra-eyeball",
  "ra-fairy",
  "ra-fairy-wand",
  "ra-fall-down",
  "ra-falling",
  "ra-fast-ship",
  "ra-feather-wing",
  "ra-feathered-wing",
  "ra-fedora",
  "ra-fire",
  "ra-fire-bomb",
  "ra-fire-breath",
  "ra-fire-ring",
  "ra-fire-shield",
  "ra-fire-symbol",
  "ra-fireball-sword",
  "ra-fish",
  "ra-fizzing-flask",
  "ra-flame-symbol",
  "ra-flaming-arrow",
  "ra-flaming-claw",
  "ra-flaming-trident",
  "ra-flask",
  "ra-flat-hammer",
  "ra-flower",
  "ra-flowers",
  "ra-fluffy-swirl",
  "ra-focused-lightning",
  "ra-food-chain",
  "ra-footprint",
  "ra-forging",
  "ra-forward",
  "ra-fox",
  "ra-frost-emblem",
  "ra-frostfire",
  "ra-frozen-arrow",
  "ra-gamepad-cross",
  "ra-gavel",
  "ra-gear-hammer",
  "ra-gear-heart",
  "ra-gears",
  "ra-gecko",
  "ra-gem",
  "ra-gem-pendant",
  "ra-gemini",
  "ra-glass-heart",
  "ra-gloop",
  "ra-gold-bar",
  "ra-grappling-hook",
  "ra-grass",
  "ra-grass-patch",
  "ra-grenade",
  "ra-groundbreaker",
  "ra-guarded-tower",
  "ra-guillotine",
  "ra-halberd",
  "ra-hammer",
  "ra-hammer-drop",
  "ra-hand",
  "ra-hand-emblem",
  "ra-hand-saw",
  "ra-harpoon-trident",
  "ra-health",
  "ra-health-decrease",
  "ra-health-increase",
  "ra-heart-bottle",
  "ra-heart-tower",
  "ra-heartburn",
  "ra-hearts",
  "ra-hearts-card",
  "ra-heat-haze",
  "ra-heavy-fall",
  "ra-heavy-shield",
  "ra-helmet",
  "ra-help",
  "ra-hive-emblem",
  "ra-hole-ladder",
  "ra-honeycomb",
  "ra-hood",
  "ra-horn-call",
  "ra-horns",
  "ra-horseshoe",
  "ra-hospital-cross",
  "ra-hot-surface",
  "ra-hourglass",
  "ra-hydra",
  "ra-hydra-shot",
  "ra-ice-cube",
  "ra-implosion",
  "ra-incense",
  "ra-insect-jaws",
  "ra-interdiction",
  "ra-jetpack",
  "ra-jigsaw-piece",
  "ra-kaleidoscope",
  "ra-kettlebell",
  "ra-key",
  "ra-key-basic",
  "ra-kitchen-knives",
  "ra-knife",
  "ra-knife-fork",
  "ra-knight-helmet",
  "ra-kunai",
  "ra-lantern-flame",
  "ra-large-hammer",
  "ra-laser-blast",
  "ra-laser-site",
  "ra-lava",
  "ra-leaf",
  "ra-leo",
  "ra-level-four",
  "ra-level-four-advanced",
  "ra-level-three",
  "ra-level-three-advanced",
  "ra-level-two",
  "ra-level-two-advanced",
  "ra-lever",
  "ra-libra",
  "ra-light-bulb",
  "ra-lighthouse",
  "ra-lightning",
  "ra-lightning-bolt",
  "ra-lightning-storm",
  "ra-lightning-sword",
  "ra-lightning-trio",
  "ra-lion",
  "ra-lit-candelabra",
  "ra-load",
  "ra-locked-fortress",
  "ra-love-howl",
  "ra-maggot",
  "ra-magnet",
  "ra-mass-driver",
  "ra-match",
  "ra-meat",
  "ra-meat-hook",
  "ra-medical-pack",
  "ra-metal-gate",
  "ra-microphone",
  "ra-mine-wagon",
  "ra-mining-diamonds",
  "ra-mirror",
  "ra-monster-skull",
  "ra-mountains",
  "ra-moon-sun",
  "ra-mp5",
  "ra-muscle-fat",
  "ra-muscle-up",
  "ra-musket",
  "ra-nails",
  "ra-nodular",
  "ra-noose",
  "ra-nuclear",
  "ra-ocarina",
  "ra-ocean-emblem",
  "ra-octopus",
  "ra-omega",
  "ra-on-target",
  "ra-ophiuchus",
  "ra-overhead",
  "ra-overmind",
  "ra-palm-tree",
  "ra-pawn",
  "ra-pawprint",
  "ra-perspective-dice-five",
  "ra-perspective-dice-four",
  "ra-perspective-dice-one",
  "ra-perspective-dice-random",
  "ra-perspective-dice-six",
  "ra-perspective-dice-two",
  "ra-perspective-dice-three",
  "ra-pill",
  "ra-pills",
  "ra-pine-tree",
  "ra-ping-pong",
  "ra-pisces",
  "ra-plain-dagger",
  "ra-player",
  "ra-player-despair",
  "ra-player-dodge",
  "ra-player-king",
  "ra-player-lift",
  "ra-player-pain",
  "ra-player-pyromaniac",
  "ra-player-shot",
  "ra-player-teleport",
  "ra-player-thunder-struck",
  "ra-podium",
  "ra-poison-cloud",
  "ra-potion",
  "ra-pyramids",
  "ra-queen-crown",
  "ra-quill-ink",
  "ra-rabbit",
  "ra-radar-dish",
  "ra-radial-balance",
  "ra-radioactive",
  "ra-raven",
  "ra-reactor",
  "ra-recycle",
  "ra-regeneration",
  "ra-relic-blade",
  "ra-repair",
  "ra-reverse",
  "ra-revolver",
  "ra-rifle",
  "ra-ringing-bell",
  "ra-roast-chicken",
  "ra-robot-arm",
  "ra-round-bottom-flask",
  "ra-round-shield",
  "ra-rss",
  "ra-rune-stone",
  "ra-sagittarius",
  "ra-sapphire",
  "ra-satellite",
  "ra-save",
  "ra-scorpio",
  "ra-scroll-unfurled",
  "ra-scythe",
  "ra-sea-serpent",
  "ra-seagull",
  "ra-shark",
  "ra-sheep",
  "ra-sheriff",
  "ra-shield",
  "ra-ship-emblem",
  "ra-shoe-prints",
  "ra-shot-through-the-heart",
  "ra-shotgun-shell",
  "ra-shovel",
  "ra-shuriken",
  "ra-sickle",
  "ra-sideswipe",
  "ra-site",
  "ra-skull",
  "ra-skull-trophy",
  "ra-slash-ring",
  "ra-small-fire",
  "ra-snail",
  "ra-snake",
  "ra-snorkel",
  "ra-snowflake",
  "ra-soccer-ball",
  "ra-spades",
  "ra-spades-card",
  "ra-spawn-node",
  "ra-spear-head",
  "ra-speech-bubble",
  "ra-speech-bubbles",
  "ra-spider-face",
  "ra-spikeball",
  "ra-spiked-mace",
  "ra-spiked-tentacle",
  "ra-spinning-sword",
  "ra-spiral-shell",
  "ra-splash",
  "ra-spray-can",
  "ra-sprout",
  "ra-sprout-emblem",
  "ra-stopwatch",
  "ra-suckered-tentacle",
  "ra-suits",
  "ra-sun",
  "ra-sun-symbol",
  "ra-sunbeams",
  "ra-super-mushroom",
  "ra-supersonic-arrow",
  "ra-surveillance-camera",
  "ra-syringe",
  "ra-target-arrows",
  "ra-target-laser",
  "ra-targeted",
  "ra-taurus",
  "ra-telescope",
  "ra-tentacle",
  "ra-tesla",
  "ra-thorn-arrow",
  "ra-thorny-vine",
  "ra-three-keys",
  "ra-tic-tac-toe",
  "ra-toast",
  "ra-tombstone",
  "ra-tooth",
  "ra-torch",
  "ra-tower",
  "ra-trail",
  "ra-trefoil-lily",
  "ra-trident",
  "ra-triforce",
  "ra-trophy",
  "ra-turd",
  "ra-two-dragons",
  "ra-two-hearts",
  "ra-uncertainty",
  "ra-underhand",
  "ra-unplugged",
  "ra-vase",
  "ra-venomous-snake",
  "ra-vest",
  "ra-vial",
  "ra-vine-whip",
  "ra-virgo",
  "ra-water-drop",
  "ra-wifi",
  "ra-wireless-signal",
  "ra-wolf-head",
  "ra-wolf-howl",
  "ra-wooden-sign",
  "ra-wrench",
  "ra-wyvern",
  "ra-x-mark",
  "ra-zebra-shield",
  "ra-arcane-mask",
  "ra-aquarius",
  "ra-apple",
  "ra-anvil",
  "ra-ankh",
  "ra-angel-wings",
  "ra-anchor",
  "ra-ammo-bag",
  "ra-alligator-clip",
  "ra-all-for-one",
  "ra-alien-fire",
  "ra-acorn"
];
