import { PropsWithChildren } from "react";
import "./FullScreen.css";
import { calculateGameBackground } from "../../../hooks/useBackgoundImage";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

interface FullScreenProps {
  isFullscreen: boolean;
  onClose: () => void;
  panel?: JSX.Element;
}

const FullScreen = (props: PropsWithChildren<FullScreenProps>) => {
  const { isFullscreen, onClose, children, panel } = props;
  const { t } = useTranslation();

  const backButton = (
    <div className="fullscreen-left-container">
      <Button variant="text" startIcon={<ArrowBackIosIcon />} onClick={onClose}>
        {t("COMMON.BACK")}
      </Button>
      <div>{panel}</div>
    </div>
  );
  return (
    <div
      style={
        isFullscreen
          ? {
              background: calculateGameBackground(),

              backgroundSize: "cover",
            }
          : undefined
      }
      className={`fullscreen-div ${isFullscreen ? "fullscreen" : ""}`}
    >
      {isFullscreen && backButton}
      {children}
    </div>
  );
};

export default FullScreen;
