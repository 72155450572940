import CancelIcon from "@mui/icons-material/Cancel";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const NavigateBack = () => {
  const navigate = useNavigate();
  return (
    <IconButton
      style={{
        position: "absolute",
      }}
      className="navigate-back"
      onClick={() => navigate("/list")}
    >
      <CancelIcon />
    </IconButton>
  );
};
