import React, { useRef, useState } from "react";

import { observer } from "mobx-react";
import { webHookStore } from "../../store/WebHookStore";
import { UserTextMessage } from "../../model/WebHookMessage";
import { ChatPanel } from "./ChatPanel";
import { ChatMessageView } from "./components/chat-message-view/ChatMessageView";
import { MapHexDisplay } from "../map-hex/MapHex";
import { MapProvider } from "../map-hex/components/MapProvider";
import { ChatSessions } from "./components/chat-sessions/ChatSessions";
import { useGame } from "../character/hooks/useGame";
import ChatInput, { IChatInput } from "./ChatInput";
import { MapDragProvider } from "../dices/MapDragProvider";

interface IChatWindow extends Pick<IChatInput, "sendMessage"> {
  chat: UserTextMessage[];
}

const ChatWindow: React.FC<IChatWindow> = (props: IChatWindow) => {
  const [value, setValue] = useState(0);
  const { game } = useGame();

  const construct = () => {
    const lookup: (string | null)[] = [null];

    if (game.root?.showUserChat) lookup.push("USER_CHAT");
    if (game.root?.showPlayerChat) lookup.push("CHARACTER_CHAT");
    if (game.root?.showLogChat) lookup.push("ACTION_ROLL");
    if (game.root?.showMapChat) lookup.push("MAP");
    return lookup;
  };
  const lookup = construct();
  const lookupKey = lookup[value];

  const chat = props.chat
    .filter((x) => lookupKey === null || x.subType === lookupKey)
    .map((m) => <ChatMessageView key={m.id} {...m} />);

  const horizontalListContainer = useRef<HTMLDivElement>(null);
  const loaderRef = useRef<HTMLDivElement>(null);

  return (
    <React.Fragment>
      <ChatSessions scrollRef={horizontalListContainer} loaderRef={loaderRef} />
      <ChatPanel value={value} setValue={setValue} />
      {lookupKey === "MAP" ? (
        <MapProvider>
          <MapDragProvider>
            <MapHexDisplay />
          </MapDragProvider>
        </MapProvider>
      ) : (
        <>
          <div ref={horizontalListContainer} className="paper chat__window">
            {/* <div ref={loaderRef}>
        <CircularProgress />
      </div> */}
            {chat}
          </div>
          <ChatInput sendMessage={props.sendMessage} />
        </>
      )}
    </React.Fragment>
  );
};

export default observer((props: Pick<IChatInput, "sendMessage">) => (
  <ChatWindow {...props} chat={webHookStore.chat} />
));
