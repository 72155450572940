import { ThemeProvider, createTheme } from "@mui/material";
import { PropsWithChildren } from "react";

const theme = createTheme({
  typography: {
    fontFamily: "cuni-fantasy",
    fontWeightBold: 600,
    fontWeightLight: 600,
    fontWeightMedium: 600,
    fontWeightRegular: 600,
  },
  components: {
    // MuiInputAdornment: {
    //   styleOverrides: {
    //     root: {
    //       color: "white",
    //     },
    //   },
    // },
    // MuiCheckbox: {
    //   styleOverrides: {
    //     root: {
    //       color: "white",
    //     },
    //   },
    // },
    // MuiIconButton: {
    //   styleOverrides: {
    //     root: {
    //       color: "white",
    //     },
    //   },
    // },
    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //       "& label": {
    //         color: "white",
    //       },
    //       "& label.Mui-focused": {
    //         color: "white",
    //       },
    //       "& .MuiInput-underline:after": {
    //         borderBottomColor: "white",
    //       },
    //       "& .MuiOutlinedInput-root": {
    //         "& fieldset": {
    //           borderColor: "white",
    //         },
    //         "&:hover fieldset": {
    //           borderColor: "white",
    //           borderWidth: "0.15rem",
    //         },
    //         "&.Mui-focused fieldset": {
    //           borderColor: "white",
    //         },
    //       },
    //     },
    //   },
    // },
    // MuiInputBase: {
    //   styleOverrides: {
    //     input: {
    //       color: "white",
    //     },
    //   },
    // },
  },

  palette: {
    primary: {
      main: "#fff",
    },
    action: {
      active: '#fff',
      selected: '#fff'
    },
    mode: "dark",
    text: {
      primary: '#fff'
    }
  },
});

export const AppThemeProvider = (props: PropsWithChildren<unknown>) => {
  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
};
