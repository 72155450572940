import { GameToken } from "../../../model/Game";
import { useGame } from "../../character/hooks/useGame";
import { TokenLink } from "./TokenLink";

interface TokenPickerProps {
  onClick: (token: GameToken) => void;
}

export const TokenPicker = (props: TokenPickerProps) => {
  const { game } = useGame();

  const options = game.root.settings?.tokens?.map((token) => (
    <TokenLink onClick={() => props.onClick(token)} token={token} fixed />
  ));
  return <div className="token-picker">{options}</div>;
};
