import { IconButton, Tooltip } from "@mui/material";
import { PropsWithChildren } from "react";
import { cuni } from "../../../../../../shared/services/cuni/cuni";
import { useGamePermission } from "../../../../../../shared/hooks/useGamePermission";
import { useGameEdit } from "../../../../../schema/hooks/useGameEdit";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { file } from "../../../../../../shared/utils/file";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline";
import EditIcon from "@mui/icons-material/Edit";
import { Conditional } from "../../../../../../shared/components/logic/Conditional";
import { usePlanPermissions } from "../../../../../../shared/hooks/usePlanPermissions";
import { useTranslation } from "react-i18next";

interface PanelMenuProps {
  path: string;
  root: any;
  variant?: "row" | "card";
  setVariant: (input: "row" | "card") => void;
  onEditRow?: () => void;
}
/**
 * Generic version of PanelMenu for tables.
 */
export const GenericPanelMenu = (props: PropsWithChildren<PanelMenuProps>) => {
  const { children, path, root, variant, setVariant, onEditRow } = props;
  const { hasGamePermission } = useGamePermission();
  const { hasPermission } = usePlanPermissions();
  const { t } = useTranslation();

  const { generic } = useGameEdit();

  if (!hasGamePermission(generic ? "canEditGame" : "canEditCharacter"))
    return <>{children}</>;

  const exportGeneric = () => {
    const object = root[path];
    if (!object) throw new Error(`${path} not exist on ${root}`);
    file.export({ object, name: path.replace(".", "_") });
  };

  const genericReplace = () => {
    document.getElementById(`fileInput-${path}`)?.click();
  };

  const genericReplaceFromFile = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const result = await file.import(event);
    const object = root[path];
    if (!object) console.warn(`${path} not exist on ${root}`);
    cuni.update(root.key, path, result);
  };

  return (
    <div className="paper__menu--handler">
      <div className="paper__menu">
        <Conditional condition={onEditRow}>
          <Tooltip title="Select row">
            <IconButton
              size="small"
              onClick={onEditRow}
              sx={{ padding: 0, maxHeight: 24 }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        </Conditional>
        <Tooltip title="Variant">
          <IconButton
            size="small"
            onClick={() => {
              setVariant(variant === "card" ? "row" : "card");
            }}
          >
            {variant === "card" ? <ViewModuleIcon /> : <ViewHeadlineIcon />}
          </IconButton>
        </Tooltip>
        <Tooltip
          title={
            hasPermission("canExportImport")
              ? t("GAME.REPLACE_TABLE")
              : t("INFO.UPGRADE")
          }
        >
          <IconButton
            disabled={!hasPermission("canExportImport")}
            size="small"
            onClick={genericReplace}
          >
            <input
              type="file"
              id={`fileInput-${path}`}
              style={{ display: "none" }}
              accept="application/json"
              onChange={genericReplaceFromFile}
            />
            <SyncAltIcon />
          </IconButton>
        </Tooltip>
        <Tooltip
          title={
            hasPermission("canExportImport")
              ? t("GAME.EXPORT_PANEL")
              : t("INFO.UPGRADE")
          }
        >
          <IconButton
            disabled={!hasPermission("canExportImport")}
            size="small"
            onClick={exportGeneric}
          >
            <FileDownloadIcon />
          </IconButton>
        </Tooltip>
      </div>
      {children}
    </div>
  );
};
