import { useEffect, useRef, useState } from "react";
import { cuni } from "../../shared/services/cuni/cuni";
import { GamePosition } from "../../model/GamePosition";
import { Map } from "../../model/Map";
import { useMapDragHandler } from "./MapDragProvider";
import { gameStorage } from "../../shared/services/storage";
import { Game } from "../../model/Game";

interface UseMapDragProps {
  position?: GamePosition;
  key: string;
  draggableDb: "tokens" | "deckPlaces" | "dices" | "elements" | "onUpdate";
  fixed?: boolean;
  classNameSelector?: string;
  onUpdate?: (position: GamePosition) => void;
  prevent?: boolean;
}

export const useMapDrag = (props: UseMapDragProps) => {
  const {
    position: startingPosition,
    key,
    draggableDb,
    fixed,
    classNameSelector,
    onUpdate,
    prevent,
  } = props;
  const [position, setPosition] = useState(
    startingPosition ?? { x: 0, y: 0, zIndex: 200 },
  );
  useEffect(() => {
    if (
      startingPosition &&
      (startingPosition.x !== position.x ||
        startingPosition.y !== position.y ||
        startingPosition.zIndex !== position.zIndex)
    )
      setPosition(startingPosition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startingPosition?.zIndex, startingPosition?.x, startingPosition?.y]);

  const elements = classNameSelector
    ? document.querySelectorAll(`.${classNameSelector}`)
    : [];
  useEffect(() => {
    if (classNameSelector)
      addClassToAll(`.${classNameSelector}`, "disable-map");
  }, [classNameSelector, elements.length]);

  const { setCurrent: setCurrentDragHanlder } = useMapDragHandler();
  const isDraggingRef = useRef(false);
  const offset = useRef({ x: 0, y: 0 });

  const targetRef = useRef<HTMLElement | null>(null);

  // @ts-ignore
  const onMouseDown = (e) => {
    if (prevent) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (e.target.classList.value.includes("block-map")) return;
    targetRef.current = classNameSelector
      ? findParentWithClassName(e.target, classNameSelector)
      : e.target;

    isDraggingRef.current = true;
    setCurrentDragHanlder(key);
    const scale = getScaleFromMapContainer() ?? 1;

    if (e.changedTouches && e.changedTouches.length > 0) {
      const touch = e.changedTouches[0];

      const clientX = touch.clientX;
      const clientY = touch.clientY;

      offset.current = {
        x: clientX / scale - position.x,
        y: clientY / scale - position.y,
      };
    } else {
      offset.current = {
        x: e.clientX / scale - position.x,
        y: e.clientY / scale - position.y,
      };
    }

    document.addEventListener("mousemove", onMouseMoveWindow, {
      passive: false,
    });
    document.addEventListener("touchmove", onMouseMoveWindow, {
      passive: false,
    });
    document.addEventListener("mouseup", onMouseUpWindow, { passive: false });
    document.addEventListener("touchend", onMouseUpWindow, { passive: false });
  };

  // @ts-ignore
  const onMouseMoveWindow = (e) => {
    if (isDraggingRef.current) {
      targetRef.current!.style.pointerEvents = "none";
      const scale = getScaleFromMapContainer() ?? 1;

      if (e.changedTouches && e.changedTouches.length > 0) {
        const touch = e.changedTouches[0]; // Get the first touch object

        const clientX = touch.clientX;
        const clientY = touch.clientY;

        setPosition({
          x: clientX / scale - offset.current.x,
          y: clientY / scale - offset.current.y,
        });
        // Now you can use the touch coordinates, e.g., detect double-tap
      } else {
        setPosition({
          x: e.clientX / scale - offset.current.x,
          y: e.clientY / scale - offset.current.y,
        });
      }
    }
  };

  const positionRef = useRef<typeof position>();
  useEffect(() => {
    positionRef.current = position;
  }, [position]);

  // @ts-ignore
  const onMouseUpWindow = (e) => {
    isDraggingRef.current = false;
    setCurrentDragHanlder(null);
    offset.current = { x: 0, y: 0 };
    targetRef.current!.style.pointerEvents = "unset";

    document.removeEventListener("mousemove", onMouseMoveWindow);
    document.removeEventListener("touchmove", onMouseMoveWindow);
    document.removeEventListener("mouseup", onMouseUpWindow);
    document.removeEventListener("touchend", onMouseUpWindow);

    const all = gameStorage.get<Game>()?.root.maps || [];
    const current = all.find((x) => x.active);
    if (!all || !current) return;

    if (draggableDb === "onUpdate") {
      if (positionRef.current) onUpdate?.(positionRef.current);
      return;
    }
    const dbToken = (current[draggableDb] as any[]).find(
      (x: any) => x.key === key,
    );
    const maxZIndex = getTopIndex(current);
    if (dbToken && positionRef.current) {
      dbToken.position ??= { x: 0, y: 0, zIndex: 200 };
      dbToken.position.x = positionRef.current.x;
      dbToken.position.y = positionRef.current.y;
      positionRef.current.zIndex = maxZIndex + 1;
      dbToken.position.zIndex = maxZIndex + 1;
      cuni.object.update("maps", all, dbToken);
    }
  };

  const style: React.CSSProperties = fixed
    ? {}
    : {
        position: !fixed ? "absolute" : "unset",
        left: `${position.x}px`,
        top: `${position.y}px`,
        cursor: fixed ? "pointer" : "grab",
        zIndex: isDraggingRef.current ? 5000 : positionRef.current?.zIndex,
      };
  return {
    onMouseDown,
    onTouchStart: onMouseDown,
    style,
    position,
  };
};

function findParentWithClassName(
  target: EventTarget | null,
  className: string,
): HTMLElement | null {
  let element = target as HTMLElement;

  // @ts-ignore
  while (element && element !== document) {
    if (element.classList && element.classList.contains(className)) {
      return element;
    }
    element = element.parentElement as HTMLElement;
  }

  return null;
}

export const getTopIndex = (current: Map) => {
  const maxZIndex = [
    ...(current.tokens || []),
    ...(current.deckPlaces || []),
    ...(current.dices || []),
    ...(current.elements || []),
  ].reduce((p, c) => {
    const zIndex = c.position?.zIndex || 200;
    return p > zIndex ? p : zIndex;
  }, 200);
  return maxZIndex;
};

export const getBottomZIndex = (current: Map) => {
  const maxZIndex = [
    ...(current.tokens || []),
    ...(current.deckPlaces || []),
    ...(current.dices || []),
    ...(current.elements || []),
  ].reduce((p, c) => {
    const zIndex = c.position?.zIndex || 200;
    return p < zIndex ? p : zIndex;
  }, 200);
  return maxZIndex;
};

export const getScaleFromMapContainer = (): number | null => {
  const mapContainer = document.querySelector(".map__container")?.parentElement;
  if (mapContainer) {
    const transform = mapContainer.style.transform;
    const match = transform.match(/scale\(([^)]+)\)/);
    if (match && match[1]) {
      return parseFloat(match[1]);
    }
  }
  return 1; // Default to scale 1 if not found
};

const addClassToAll = (selector: string, className: string) => {
  const elements = document.querySelectorAll(selector);

  elements.forEach((element) => {
    element.classList.add(className);

    const children = element.querySelectorAll("*");
    children.forEach((child) => {
      child.classList.add(className);
    });
  });
};
