import {
  Box,
  Checkbox,
  FormControlLabel,
  TextField,
  Tooltip,
} from "@mui/material";
import { useEffect } from "react";
import { useGame } from "../../character/hooks/useGame";
import { usePatchGame } from "../hooks/usePatchGame";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { updateBodyBackground } from "../../../shared/hooks/useBackgoundImage";
import { useTranslation } from "react-i18next";
import UploadButton from "../../../shared/components/logic/UploadButton";
import { PaymentTooltip } from "../../../shared/components/ui/PaymentTooltip";
import { usePlanPermissions } from "../../../shared/hooks/usePlanPermissions";

export interface BasicDataUpdateState {
  url: string | null;
  name: string;
  isTemplate?: boolean;
}

export const BasicDataUpdate = () => {
  const { game } = useGame();
  const { t } = useTranslation();

  const { hasPermission } = usePlanPermissions();

  const { register, getValues, reset, setValue, control } =
    useForm<BasicDataUpdateState>({
      defaultValues: {
        url: game.url,
        name: game.name,
        isTemplate: game.isTemplate,
      },
    });

  useEffect(() => {
    reset({
      url: game.url,
      name: game.name,
      isTemplate: game.isTemplate,
    });
  }, [game, reset]);

  const { mutate } = usePatchGame();

  const save = () => {
    const values = getValues();
    const url = values.url?.trim() || " ";
    updateBodyBackground(values.url?.trim());
    mutate({ id: game.id, ...values, url: url });
  };

  return (
    <React.Fragment>
      <TextField
        label={t("GAME.GAME_NAME")}
        {...register("name")}
        onBlur={save}
      />
      <Box display="grid" gridTemplateColumns="3fr 1fr" gap="0.5rem">
        <TextField
          label={t("GAME.GAME_WALLPAPER_URL")}
          {...register("url")}
          onBlur={save}
        />
        <UploadButton
          label={t("COMMON.UPLOAD")}
          onUpload={(data) => {
            const { location: value } = data![0];
            setValue("url", value);
            save();
          }}
        />
        <Controller
          control={control}
          name="isTemplate"
          render={({ field }) => {
            return (
              <PaymentTooltip
                title={t("INFO.UPGRADE")}
                visible={!hasPermission("canUseTemplates")}
              >
                <FormControlLabel
                  disabled={!hasPermission("canUseTemplates")}
                  control={
                    <Checkbox checked={field.value} onChange={field.onChange} />
                  }
                  label={t("GAME.TEMPLATE")}
                />
              </PaymentTooltip>
            );
          }}
        />
      </Box>
    </React.Fragment>
  );
};
