export function findPath(obj: any, target: any, path = ""): string | null {
  // If the object itself is the target, return the current path
  if (obj === target) {
    return path;
  }

  // If the object is an array, iterate through each element
  if (Array.isArray(obj)) {
    for (let i = 0; i < obj.length; i++) {
      let result = findPath(obj[i], target, path ? `${path}.${i}` : `${i}`);
      if (result) return result;
    }
  }
  // If the object is an object, iterate through its keys
  else if (typeof obj === "object" && obj !== null) {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        let result = findPath(obj[key], target, path ? `${path}.${key}` : key);
        if (result) return result;
      }
    }
  }

  return null;
}
