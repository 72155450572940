import React, { useState } from "react";
import { BasicView } from "../shared/components/ui/BasicView";
import { useAuth } from "../features/auth/useAuth";
import { useNavigate } from "react-router-dom";
import { Alert, Button, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { Conditional } from "../shared/components/logic/Conditional";
import { FormValidation } from "../shared/services/validator/FormValidation";
import { LoadingButton } from "../shared/components/ui/LoadingButton";
import { useTranslation } from "react-i18next";

interface ForgotPasswordForm {
  email: string;
}

export const ForgotPasswordPage: React.FC = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<ForgotPasswordForm>();
  const [apiError, setApiError] = useState<string>();
  const [loading, setLoading] = useState(false);

  const submit = async ({ email }: ForgotPasswordForm) => {
    try {
      setLoading(true);
      await auth.generateCode(email);
      navigate("/confirm?redirect_uri=update-password");
    } catch (error) {
      setApiError(
        // @ts-ignore
        error.response.status === 401
          ? t("ERROR.WRONG_CREDENTIALS")
          : t("ERROR.TRY_AGAIN_LATER"),
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <BasicView>
        <label>{t("LOGIN.PASSWORD_RECOVERY")}</label>
        <TextField
          label={t("LOGIN.EMAIL")}
          type="email"
          {...register("email", {
            required: true,
            pattern: FormValidation.email,
          })}
          error={Boolean(errors.email)}
        />
        <Conditional condition={apiError}>
          <Alert severity="error">{apiError}</Alert>
        </Conditional>
        <LoadingButton
          loading={loading}
          variant="contained"
          type="submit"
          disabled={!isValid}
        >
          {t("LOGIN.VERIFY")}
        </LoadingButton>
        <Button onClick={() => navigate("/")}>
          {t("LOGIN.BACK_TO_LOGIN")}
        </Button>
      </BasicView>
    </form>
  );
};
