import { Box, Button, MenuItem } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { Character } from "../../model/Game";
import { GameEditFormValues } from "../../pages/GameEditPage";
import SelectField from "../../shared/components/logic/SelectField";
import { useGame } from "../character/hooks/useGame";
import "./GameSchemaMenu.css";
import { Conditional } from "../../shared/components/logic/Conditional";
import { useState } from "react";
import { AddCharacterModal } from "./components/AddCharacterModal/AddCharacterModal";
import { useFriends } from "../invitation/FriendList";
import { UserOption } from "../../model/User";
import { useMutation } from "@tanstack/react-query";
import { config } from "../../config";
import { apiClient } from "../../shared/services/apiClient";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Divider } from "../../shared/components/ui/Divider";
import { useUser } from "../auth/useUser";
import { PaymentTooltip } from "../../shared/components/ui/PaymentTooltip";

interface GameSchemaMenuProps {
  showCharacter: boolean;
}
export const GameSchemaMenu = (props: GameSchemaMenuProps) => {
  const { showCharacter } = props;
  const user = useUser();
  const { control, watch, setValue } = useFormContext<GameEditFormValues>();
  const { options: friends } = useFriends(true);
  const { game } = useGame();
  const [modal, setModal] = useState<string>("");
  const { t } = useTranslation();

  const menu = watch("menu");
  const character = watch("selected");
  const { mutate } = useConnectCharacterAndUser();
  const navigate = useNavigate();

  const characters = Object.values(game.characters);
  const addButton = (
    <PaymentTooltip
      title={t("INFO.UPGRADE")}
      visible={user.licensePlanData.playersCount <= characters.length}
    >
      <Button
        disabled={user.licensePlanData.playersCount <= characters.length}
        variant="outlined"
        onClick={() => setModal("addCharacter")}
      >
        {t("GAME.ADD_CHAR")}
      </Button>
    </PaymentTooltip>
  );
  return (
    <div className="gameSchemaMenu">
      <Button
        sx={{ marginBottom: "0.5rem", width: "100%" }}
        variant="contained"
        onClick={() => navigate("/")}
      >
        {t("GAME.JOIN")}
      </Button>
      <div className="paper">
        <MenuItem
          selected={menu === "General"}
          onClick={() => setValue("menu", "General")}
        >
          {t("GAME.GENERAL")}
        </MenuItem>
        {/* <Divider title="Character" /> */}

        <MenuItem
          selected={menu === "Tabs"}
          onClick={() => setValue("menu", "Tabs")}
        >
          {t("GAME.CHARACTER_LEFT")}
        </MenuItem>
        <MenuItem
          selected={menu === "Chat"}
          onClick={() => setValue("menu", "Chat")}
        >
          {t("GAME.CHAT")}
        </MenuItem>
        <MenuItem
          selected={menu === "Map"}
          onClick={() => setValue("menu", "Map")}
        >
          {t("GAME.MAP_EDITOR")}
        </MenuItem>
        <Divider title={t("GAME.RPG")} />
        <MenuItem
          selected={menu === "Sections"}
          onClick={() => setValue("menu", "Sections")}
        >
          {t("GAME.STORY_RIGHT")}
        </MenuItem>
        <MenuItem
          selected={menu === "Tokens"}
          onClick={() => setValue("menu", "Tokens")}
        >
          {t("GAME.TOKENS")}
        </MenuItem>
        <Divider title={t("GAME.BOARD")} />
        <MenuItem
          selected={menu === "Cards"}
          onClick={() => setValue("menu", "Cards")}
        >
          {t("GAME.CARDS")}
        </MenuItem>
        <MenuItem
          selected={menu === "Decks"}
          onClick={() => setValue("menu", "Decks")}
        >
          {t("GAME.DECKS")}
        </MenuItem>
        <MenuItem
          selected={menu === "Dices"}
          onClick={() => setValue("menu", "Dices")}
        >
          {t("GAME.DICES")}
        </MenuItem>
        <Divider title={t("COMMON.SETTINGS")} />
        <MenuItem
          selected={menu === "Database"}
          onClick={() => setValue("menu", "Database")}
        >
          {t("COMMON.DATABASE")}
        </MenuItem>
      </div>
      <Conditional condition={showCharacter}>
        <div className="paper gameSchemaMenu__char">
          <Conditional
            condition={characters.length}
            success={() => {
              return (
                <Controller
                  name="selected"
                  control={control}
                  render={({ field }) => {
                    return (
                      <SelectField<Character>
                        label={t("GAME.CHARACTER")}
                        value={field.value}
                        onChange={field.onChange}
                        options={characters}
                      />
                    );
                  }}
                />
              );
            }}
          />
          <Box display="flex" flexDirection="column" gap="0.5rem">
            <Conditional
              condition={character}
              success={(char) => (
                <React.Fragment>
                  <Controller
                    name="user"
                    control={control}
                    render={({ field: userField }) => {
                      return (
                        <SelectField<UserOption>
                          label={t("COMMON.USER")}
                          value={userField.value}
                          onChange={(e: UserOption) => {
                            userField.onChange(e);
                            mutate({
                              gameId: game.id,
                              characterKey: char.key,
                              newUserId: e.key,
                            });
                          }}
                          options={friends}
                        />
                      );
                    }}
                  />
                </React.Fragment>
              )}
            />
            {addButton}
          </Box>
        </div>
      </Conditional>
      <AddCharacterModal
        handleClose={() => setModal("")}
        open={modal === "addCharacter"}
      />
    </div>
  );
};

// TODO: move this hook or different file
export const useConnectCharacterAndUser = () => {
  return useMutation({
    mutationFn: ({
      gameId,
      characterKey,
      newUserId,
    }: {
      gameId: number;
      characterKey: number;
      newUserId: number;
    }) =>
      apiClient.post(
        `${config.baseUrl}/game/${gameId}/character/${characterKey}/user/${newUserId}`,
      ),
  });
};
