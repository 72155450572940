import { useMutation } from "@tanstack/react-query";
import { config } from "../../../config";
import { Character, Game } from "../../../model/Game";
import { apiClient } from "../../../shared/services/apiClient";

type CharacterInputDto = Partial<Character> & { name: string };

export const useAddCharacter = (game: Game) => {
  return useMutation({
    mutationFn: (data: CharacterInputDto) =>
      apiClient.post<CharacterInputDto>(
        `${config.baseUrl}/game/${game.id}/character`,
        data,
      ),
  });
};
