import QuizIcon from "@mui/icons-material/Quiz";
import { IconButton } from "@mui/material";
import React, { useCallback, useState } from "react";
import { Conditional } from "../../../shared/components/logic/Conditional";
import { CharacterSelect } from "./character-select/CharacterSelect";
import { SurveyModal } from "./survey/SurveyModal";
import { useGamePermission } from "../../../shared/hooks/useGamePermission";
import { CharacterNamesSelect } from "./character-names-select/CharacterNamesSelect";
import { ColorPickerField } from "./color-picker-field/ColorPickerField";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import { StoryNoteModal } from "./story-note/StoryNoteModal";
import FlagIcon from "@mui/icons-material/Flag";
import { NewSessionModal } from "./new-session/NewSessionModal";

interface ChatActionsProps {}

export const ChatActions = (props: ChatActionsProps) => {
  const { hasGamePermission } = useGamePermission();
  const [modal, setModal] = useState("");
  const handleClose = useCallback(() => setModal(""), []);

  return (
    <div className="chat__toolbar">
      <div className="chat__toolbar-section">
        <Conditional condition={hasGamePermission("sendSurvey")}>
          <React.Fragment>
            <IconButton
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                setModal("survey");
              }}
            >
              <QuizIcon />
            </IconButton>
            <SurveyModal open={modal === "survey"} handleClose={handleClose} />
          </React.Fragment>
        </Conditional>
        <Conditional condition={hasGamePermission("writeStory")}>
          <React.Fragment>
            <IconButton
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                setModal("story");
              }}
            >
              <AutoStoriesIcon />
            </IconButton>
            <StoryNoteModal
              open={modal === "story"}
              handleClose={handleClose}
            />
          </React.Fragment>
        </Conditional>
        <Conditional condition={hasGamePermission("chooseReceiver")}>
          <CharacterSelect />
        </Conditional>
        <Conditional condition={hasGamePermission("canChangeColor")}>
          <ColorPickerField />
        </Conditional>
        <Conditional condition={hasGamePermission("canStartSession")}>
          <React.Fragment>
            <IconButton
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                setModal("new_session");
              }}
            >
              <FlagIcon />
            </IconButton>
            <NewSessionModal
              open={modal === "new_session"}
              handleClose={handleClose}
            />
          </React.Fragment>
        </Conditional>
      </div>
      <div className="chat__toolbar-section">
        <Conditional condition={hasGamePermission("chooseName")}>
          <CharacterNamesSelect />
        </Conditional>
      </div>
    </div>
  );
};
