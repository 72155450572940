import React, { useState } from "react";
import { BasicView } from "../shared/components/ui/BasicView";
import { useAuth } from "../features/auth/useAuth";
import { useNavigate } from "react-router-dom";
import { Alert, Button, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { Conditional } from "../shared/components/logic/Conditional";
import { LoadingButton } from "../shared/components/ui/LoadingButton";
import { useTranslation } from "react-i18next";

interface UpdatePasswordForm {
  password: string;
}

export const UpdatePasswordPage: React.FC = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<UpdatePasswordForm>();
  const [apiError, setApiError] = useState<string>();
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const submit = async ({ password }: UpdatePasswordForm) => {
    try {
      setLoading(true);
      const result = await auth.updatePassword(password);
      if (result.data.succeeded) navigate("/profile");
      else setApiError(result.data.errors[0].description);
    } catch (error) {
      setApiError(
        // @ts-ignore
        error.response.status === 401
          ? t("ERROR.WRONG_CREDENTIALS")
          : t("ERROR.TRY_AGAIN_LATER"),
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <BasicView>
        <label>{t("LOGIN.UPDATE_PASSWORD")}</label>
        <TextField
          label={t("LOGIN.PASSWORD")}
          type="password"
          {...register("password", { minLength: 3, required: true })}
          error={Boolean(errors.password)}
          helperText={t("LOGIN.CHAR_LENGTH_REQUIREMENTS")}
        />
        <Conditional condition={apiError}>
          <Alert severity="error">{apiError}</Alert>
        </Conditional>
        <LoadingButton
          loading={loading}
          variant="contained"
          type="submit"
          disabled={!isValid}
        >
          {t("COMMON.UPDATE")}
        </LoadingButton>
        <Button onClick={() => navigate("/")}>
          {t("LOGIN.BACK_TO_LOGIN")}
        </Button>
      </BasicView>
    </form>
  );
};
