import { Typography, Button } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { GameCard, SETTINGS_ADMIN_KEY } from "../../model/Game";
import { cuni } from "../../shared/services/cuni/cuni";
import { uuid } from "../../shared/utils/uuid";
import { useGame } from "../character/hooks/useGame";
import { QuickEditModalProvider } from "../quick-edit/hooks/useQuickEdit";
import { Table, TableCellInputProps } from "../../shared/components/ui/Table";
import { SwitchView } from "../../shared/components/ui/switch-view/SwitchView";
import { CardDetailEditView } from "./CardDetailEditView";
import { useCards } from "../chat/hooks/useCards";
import UploadButton, {
  UploadResult,
} from "../../shared/components/logic/UploadButton";

export const CardEditView = () => {
  const { game } = useGame();
  const { t } = useTranslation();

  const cards = useCards();

  const upload = useCallback(
    (key: string) => {
      cards.push({
        key,
        type: "CARD",
        uiType: "CARD",
      });
      cuni.update(SETTINGS_ADMIN_KEY, "cards", cards);
    },
    [cards],
  );

  const uploadMultiple = useCallback(
    (data: UploadResult[]) => {
      data
        .map(({ location }) => ({
          key: uuid(),
          type: "CARD",
          uiType: "CARD",
          avatarUrl: location,
        }))
        .forEach((x) => cards.push(x as GameCard));

      cuni.update(SETTINGS_ADMIN_KEY, "cards", cards);
    },
    [cards],
  );

  const add = useCallback(() => upload(uuid()), [upload]);
  const [view, setView] = useState<string | null>(null);
  const [value, setValue] = useState<GameCard[]>([]);

  // Update view when token is updated
  // useEffect(() => {
  //   if (!value) return;
  //   const card = cards.filter((x) => value.map((y) => y.key).includes(x.key));
  //   setValue(card);
  // }, [game, cards, value]);

  const { rows, headers } = useMemo(() => {
    const items = cards;
    const headers = [
      {
        key: "key",
        title: t("CARD.CARD"),
        cell: (
          value: GameCard,
          idx: number,
          { variant }: TableCellInputProps,
        ) =>
          value.name ? (
            value.name
          ) : value.avatarUrl ? (
            <img
              alt=""
              style={
                variant === "card" ? { maxHeight: 100 } : { maxHeight: 20 }
              }
              src={value.avatarUrl}
            />
          ) : (
            value.key
          ),
      },
      {
        key: "amount",
        title: t("TOKEN.AMOUNT"),
      },
      {
        key: "action",
        title: t("COMMON.ACTION"),
        cell: (value: GameCard, index: number) => (
          <Button
            onClick={() => {
              setValue([value]);
              setView("detail");
            }}
          >
            {t("COMMON.OPEN")}
          </Button>
        ),
      },
    ];
    const rows = items.map((x) => ({
      ...x,
      avatarUrl: x.avatarUrl ? x.avatarUrl : "",
      amount: x.amount ? x.amount : 0,
      action: "",
    }));
    return { rows, headers };
  }, [cards, t]);

  return (
    <QuickEditModalProvider
      character={{ ...game.root.settings!, key: SETTINGS_ADMIN_KEY }}
    >
      <SwitchView
        setView={setView}
        view={view}
        viewComponents={{
          detail: <CardDetailEditView cards={value} />,
        }}
      >
        <div className="column" style={{ paddingRight: "1rem" }}>
          <Typography>{t("CARD.OVERRIDE_INFO")}</Typography>
          <UploadButton label={t("COMMON.UPLOAD")} onUpload={uploadMultiple} />
          <Button variant="outlined" onClick={add}>
            {t("CARD.ADD")}
          </Button>
          <Table
            titleSufix="cards"
            directList
            root={{ ...game.root.settings, key: SETTINGS_ADMIN_KEY }}
            headers={headers}
            className="paper-outline"
            rows={rows}
            variant="card"
            onEditRow={(value: GameCard[]) => {
              setValue(value);
              setView("detail");
            }}
          />
        </div>
      </SwitchView>
    </QuickEditModalProvider>
  );
};
