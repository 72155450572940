import axios from "axios";
import { authStorage } from "./storage";
import { AuthData } from "../../features/auth/AuthContext";

export const apiClient = (() => {
  const get = async <T>(url: string) => {
    const initAuth = authStorage.get<AuthData>();
    return await axios.get<T>(url, {
      headers: { Authorization: `Bearer ${initAuth?.token}` },
    });
  };

  const post = async <T = any>(url: string, data?: any) => {
    const initAuth = authStorage.get<AuthData>();
    return await axios.post<T>(url, data, {
      headers: { Authorization: `Bearer ${initAuth?.token}` },
    });
  };

  const put = async <T = any>(url: string, data: any) => {
    const initAuth = authStorage.get<AuthData>();
    return await axios.put<T>(url, data, {
      headers: { Authorization: `Bearer ${initAuth?.token}` },
    });
  };

  const patch = async <T = any>(url: string, data: any) => {
    const initAuth = authStorage.get<AuthData>();
    return await axios.patch<T>(url, data, {
      headers: { Authorization: `Bearer ${initAuth?.token}` },
    });
  };

  const deleteMethod = async <T = any>(url: string) => {
    const initAuth = authStorage.get<AuthData>();
    return await axios.delete<T>(url, {
      headers: { Authorization: `Bearer ${initAuth?.token}` },
    });
  };

  return {
    get,
    post,
    put,
    patch,
    delete: deleteMethod,
  };
})();

export function parseJwt(token?: string | null) {
  if (!token) throw new Error("This should be valid token");
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(""),
  );

  return JSON.parse(jsonPayload);
}

export const CLAIMS = {
  name: "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name",
  id: "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier",
  email: "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress",
  role: "http://schemas.microsoft.com/ws/2008/06/identity/claims/role",
  licensePlan: "LicensePlan",
  licenseExpiredDate: "LicenseExpiredDate",
};

export const UserPermissions = {};
export type UserPermission = keyof typeof UserPermissions;
