import { Tooltip } from "@mui/material";

interface PaymentTooltipProps {
  visible: boolean;
  title: string;
  children: JSX.Element;
}

export const PaymentTooltip = (
  props: PaymentTooltipProps,
) => {
  const { children, visible, title } = props;
  if (visible && children) return <Tooltip title={title}>{children}</Tooltip>;
  return children;
};
