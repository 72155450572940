import { AccountCircle } from "@mui/icons-material";
import LogoutIcon from "@mui/icons-material/Logout";
import MoreIcon from "@mui/icons-material/MoreVert";
import MusicNoteIcon from "@mui/icons-material/MusicNote";
// import NotificationsIcon from "@mui/icons-material/Notifications";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import DashboardIcon from "@mui/icons-material/Dashboard";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import FolderIcon from "@mui/icons-material/Folder";
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useAuth } from "../../../../features/auth/useAuth";
import { Conditional } from "../../logic/Conditional";
import { useTranslation } from "react-i18next";
import { musicStorage } from "../../../services/storage";
import { useUnsafeUser } from "../../../../features/auth/useUser";
import { Link } from "react-router-dom";

export var PRESSED_KEYS: any = {};
window.onkeyup = function (e) {
  delete PRESSED_KEYS[e.code];
};
window.onkeydown = function (e) {
  PRESSED_KEYS[e.code] = true;
};

export default function Layout() {
  const auth = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onMusicClick = () => {
    const music = musicStorage.get<{
      controls: boolean;
    }>() ?? { controls: false };
    music.controls = !music.controls;
    musicStorage.set(music);
  };

  const authenticated = !(
    !auth ||
    (auth?.expiration && new Date(auth?.expiration) < new Date())
  );
  useEffect(() => {
    if (
      !auth ||
      (auth?.expiration && new Date(auth?.expiration) < new Date())
    ) {
      auth.logout();
      navigate("/login");
    }
  }, [auth, navigate]);

  const user = useUnsafeUser();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    useState<null | HTMLElement>(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = (action?: () => void) => () => {
    action?.();
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = (action?: () => void) => () => {
    setAnchorEl(null);
    handleMobileMenuClose(action)();
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    auth.logout();
    navigate("/login");
  };

  const handleProfile = () => {
    navigate("/profile");
  };

  const handleGlossary = () => {
    navigate("/glossary");
  };

  const menuItemStyles = {
    display: "flex",
    gap: "1rem",
    padding: "1rem",
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose()}
    >
      <MenuItem onClick={handleMenuClose(handleProfile)}>
        {t("LOGIN.ACCOUNT")}
      </MenuItem>
      <MenuItem onClick={handleMenuClose(handleLogout)}>
        {t("LOGIN.LOGOUT")}
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose()}
    >
      <Conditional condition={localStorage.getItem("cuni__game")}>
        <MenuItem
          sx={menuItemStyles}
          onClick={handleMobileMenuClose(() => navigate("/"))}
        >
          <PlayArrowIcon />
          <div>{t("LOGIN.CONTINUE")}</div>
        </MenuItem>
      </Conditional>

      <MenuItem
        sx={menuItemStyles}
        onClick={handleMobileMenuClose(onMusicClick)}
      >
        <MusicNoteIcon />
        <div>{t("MUSIC.MUSIC")}</div>
      </MenuItem>

      <Conditional condition={user?.licensePlan === "Ultimate"}>
        <MenuItem
          sx={menuItemStyles}
          onClick={handleMobileMenuClose(onMusicClick)}
        >
          <FolderIcon />
          <div>{t("COMMON.PROJECTS")}</div>
        </MenuItem>
      </Conditional>

      <MenuItem
        sx={menuItemStyles}
        onClick={handleMobileMenuClose(() => navigate("/project"))}
      >
        <DashboardIcon />
        <div>{t("LOGIN.DASHBOARD")}</div>
      </MenuItem>

      {/* <MenuItem sx={menuItemStyles}>
        <Badge badgeContent={17} color="error">
          <NotificationsIcon />
        </Badge>
        <div>Notifications</div>
      </MenuItem> */}
      <MenuItem
        sx={menuItemStyles}
        onClick={handleMobileMenuClose(handleProfile)}
      >
        <AccountCircle />
        <div>{t("LOGIN.ACCOUNT")}</div>
      </MenuItem>
      <MenuItem
        sx={menuItemStyles}
        onClick={handleMobileMenuClose(handleLogout)}
      >
        <LogoutIcon />
        <div>{t("LOGIN.LOGOUT")}</div>
      </MenuItem>
    </Menu>
  );

  return (
    <div id="detail">
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" style={{ background: "transparent" }}>
          <Toolbar className="paper-ish">
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1 }}
              onClick={() => navigate("/list")}
            >
              Cuni
            </Typography>
            <Conditional
              condition={auth.token}
              success={() => (
                <>
                  <Box sx={{ display: { xs: "none", md: "flex" } }}>
                    <Conditional condition={localStorage.getItem("cuni__game")}>
                      <IconButton
                        size="large"
                        color="inherit"
                        onClick={() => navigate("/")}
                      >
                        <PlayArrowIcon />
                      </IconButton>
                    </Conditional>
                    <IconButton
                      size="large"
                      color="inherit"
                      onClick={onMusicClick}
                    >
                      <MusicNoteIcon />
                    </IconButton>
                    <IconButton
                      size="large"
                      aria-label="show dashboard"
                      color="inherit"
                      onClick={() => navigate("/list")}
                    >
                      <DashboardIcon />
                    </IconButton>
                    <Conditional condition={user?.licensePlan === "Ultimate"}>
                      <IconButton
                        size="large"
                        aria-label="show dashboard"
                        color="inherit"
                        onClick={() => navigate("/project")}
                      >
                        <FolderIcon />
                      </IconButton>
                    </Conditional>
                    {/* <IconButton
                      size="large"
                      aria-label="show 17 new notifications"
                      color="inherit"
                    >
                      <Badge badgeContent={17} color="error">
                        <NotificationsIcon />
                      </Badge>
                    </IconButton> */}
                    <IconButton
                      size="large"
                      edge="end"
                      aria-label="account of current user"
                      aria-controls={menuId}
                      aria-haspopup="true"
                      onClick={handleProfileMenuOpen}
                      color="inherit"
                    >
                      <AccountCircle />
                    </IconButton>
                  </Box>

                  <Box sx={{ display: { xs: "flex", md: "none" } }}>
                    <IconButton
                      size="large"
                      aria-label="show more"
                      aria-controls={mobileMenuId}
                      aria-haspopup="true"
                      onClick={handleMobileMenuOpen}
                      color="inherit"
                    >
                      <MoreIcon />
                    </IconButton>
                  </Box>
                </>
              )}
              failure={() => (
                <Button color="inherit" onClick={() => navigate("/login")}>
                  {t("LOGIN.LOGIN")}
                </Button>
              )}
            />
          </Toolbar>
        </AppBar>
        {renderMobileMenu}
        {renderMenu}
      </Box>
      <div className="support">
        {/* {t("LOGIN.SUPPORT")} */}
        <Conditional condition={user}>
          <Link to="/payments">{t("PAYMENTS.PAYMENTS")}</Link>
        </Conditional>
        <Link to="/privacy-policy">{t("PAYMENTS.POLICY")}</Link>
        <Link to="/terms-of-use">{t("PAYMENTS.TERMS")}</Link>
        <Link to="/glossary">{t("LOGIN.GLOSSARY")}</Link>
        {/* <a href="https://buycoffee.to/hubertnosek">buycoffee.to</a>! */}
      </div>
      <Conditional condition={authenticated} success={() => <Outlet />} />
    </div>
  );
}
