import { UserTextMessage } from "../../../../model/WebHookMessage";
import { useGame } from "../../../character/hooks/useGame";
import { SimpleMessageView } from "./SimpleMessageView";
import { SurveyMessageView } from "./SurveyMessageView";

const playerColors = [
  "#FF5733", // Red-Orange
  "#33FF57", // Green
  "#3357FF", // Blue
  "#FFD233", // Yellow
  "#8D33FF", // Purple
  "#33FFF1", // Aqua
  "#FF9633", // Orange
  "#FF33A1", // Pink
];

interface ChatMessageViewProps extends UserTextMessage {}

export const ChatMessageView = (props: ChatMessageViewProps) => {
  const { game } = useGame();
  switch (props.subType) {
    case "SURVEY":
      return <SurveyMessageView {...props} />;
    case "STORY":
      return <SimpleMessageView {...props} nameColor="#61dafb" />;
    case "NEW_SESSION":
      return <NewSessionMessageView {...props} />;
  }
  const key = Object.entries(game.characters).find(
    (x) => x[1].name === props.user,
  )?.[0];
  let nameColor = props.color;
  if (!props.color && key && parseInt(key)) {
    nameColor = playerColors[parseInt(key)];
  }
  return <SimpleMessageView {...props} nameColor={nameColor} />;
};

/// TODO: make better ui and move to new file
export const NewSessionMessageView = (props: UserTextMessage) => {
  return (
    <div className="chat__message chat__message--session">
      <div className="chat__message--name">
        <h3>{props.message}</h3>
      </div>
      <hr />
    </div>
  );
};
