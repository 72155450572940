import * as React from "react";
import "./GameTabEditView.css";
import {
  CharacterTabProvider,
  useCharacterTabs,
} from "../character/components/generic/CharacterTabProvider";
import { Conditional } from "../../shared/components/logic/Conditional";
import { GenericTabPanels } from "../character/components/generic/GenericTabPanels";
import { useFormContext } from "react-hook-form";
import { GameEditFormValues } from "../../pages/GameEditPage";
import { GenericPanelView } from "../character/components/generic/GenericPanelView";
import { GenericTemplateView } from "../character/components/generic/GenericTemplateView";
import { GameEditProvider, useGameEdit } from "./hooks/useGameEdit";
import { QuickEditModalProvider } from "../quick-edit/hooks/useQuickEdit";
import {
  ChacterEditActionView,
  GameCharacterEditActions,
} from "./components/GameCharacterEditActions/GameCharacterEditActions";
import { GameTabEditActions } from "./components/GameCharacterEditActions/GameTabEditActions";
import { InteractionView } from "./components/interactions-edit-view/InteractionView";
import { SwitchPanelView } from "./SwitchPanelView";

export interface GameTabEditViewInternalProps {
  style?: React.CSSProperties;
}

export const GameTabEditViewInternal = (
  props: GameTabEditViewInternalProps,
) => {
  const { tab, tabId, character } = useCharacterTabs();
  const [view, setView] = React.useState<ChacterEditActionView>("character");
  const withoutTabs = false;

  const { editEnabled } = useGameEdit();

  if (!character) return null;

  let component = <></>;
  if (view === "interactions") component = <InteractionView />;
  else {
    if (withoutTabs) {
      component = (
        <Conditional
          condition={character.__tabs}
          success={(tabs) => {
            return (
              <>
                {tabs.map((x, idx) => (
                  <SwitchPanelView character={character} tabId={idx} tab={x} />
                ))}
              </>
            );
          }}
        />
      );
    } else
      component = (
        <>
          <Conditional
            condition={character.__tabs}
            success={(tabs) => <GenericTabPanels padding tabs={tabs} />}
          />
          <Conditional
            condition={tab?.title === "main"}
            success={() => (
              <React.Fragment>
                <GenericTemplateView template={character} path="" />
                <Conditional
                  condition={character?.__panels}
                  success={(panels) => (
                    <GenericPanelView panels={panels} path="__panels" />
                  )}
                />
              </React.Fragment>
            )}
            failure={() => (
              <Conditional
                condition={tab?.__panels}
                success={(panels) => (
                  <GenericPanelView
                    panels={panels}
                    path={`__tabs.${tabId - 1}.__panels`}
                  />
                )}
              />
            )}
          />
          <Conditional condition={editEnabled}>
            <GameTabEditActions />
          </Conditional>
        </>
      );
  }

  return (
    <QuickEditModalProvider character={character}>
      <div className="gameTabEditView" {...props}>
        <Conditional condition={editEnabled}>
          <GameCharacterEditActions view={view} setView={setView} />
        </Conditional>
        {component}
      </div>
    </QuickEditModalProvider>
  );
};

export const GameTabEditView = () => {
  const { watch } = useFormContext<GameEditFormValues>();

  const character = watch("selected");

  if (!character) return null;
  return (
    <GameEditProvider editEnabled>
      <CharacterTabProvider character={character}>
        <GameTabEditViewInternal />
      </CharacterTabProvider>
    </GameEditProvider>
  );
};
