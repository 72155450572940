import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Game } from "../../../model/Game";
import { rootStore } from "../../../store/RootStore";
import { useGameConnection } from "../../chat/hooks/useGameConnection";

interface GameState {
  game: Game;
}

const GameContext = createContext<GameState | null>(null);

export const GameProvider = (
  props: PropsWithChildren<{ game: Game | null }>,
) => {
  const { children, ...others } = props;
  const [initialized, setInitialized] = useState(false);
  useEffect(() => {
    rootStore
      .init()
      .then(() => setInitialized(true))
      .catch(() => setInitialized(true));
  }, []);

  const game = useMemo(() => {
    return others.game;
  }, [others.game]);

  useGameConnection();

  if (!game || !initialized) return null;
  return (
    <GameContext.Provider value={{ game }}>
      {props.children}
    </GameContext.Provider>
  );
};

export const useGame = () => {
  const state = useContext(GameContext);
  if (!state) throw new Error("useGame need to be used in GameProvider");
  return state;
};
