import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { IconButton } from "@mui/material";
import { useGame } from "../../../character/hooks/useGame";
import { useFormContext } from "react-hook-form";
import { ActionFormValues } from "../ActionForm";
import { useTranslation } from "react-i18next";

interface CharacterSelectProps {}

export const CharacterSelect = (props: CharacterSelectProps) => {
  const form = useFormContext<ActionFormValues>();
  const { setValue, watch } = form;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { t } = useTranslation();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { game } = useGame();
  if (!game.characters) return null;
  const components = Object.entries(game.characters)
    .map((x) => x[1])
    .map((x) => (
      <MenuItem
        key={x.key}
        onClick={() => {
          setValue("receiverId", x.__userId);
          handleClose();
        }}
      >
        {x.name}
      </MenuItem>
    ));

  const receiverId = watch("receiverId");

  const current = Object.entries(game.characters)
    .map((x) => x[1])
    .find((x) => x.__userId === receiverId && receiverId);

  return (
    <div>
      <IconButton
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <PersonAddIcon />
      </IconButton>
      {current?.name}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            setValue("receiverId", undefined);
            handleClose();
          }}
        >
          {t("COMMON.CLEAR")}
        </MenuItem>
        {components}
      </Menu>
    </div>
  );
};
