import { Autocomplete, TextField } from "@mui/material";
import SelectField from "../../../shared/components/logic/SelectField";
import { QuickEditState } from "../hooks/useQuickEdit";

export interface TypedInputProps extends Partial<QuickEditState> {
  onChange: (value: string) => void;
  hints: {
    label: string;
    key: string;
    value: any;
  }[];
}
export const TypedInput = (props: TypedInputProps) => {
  switch (props.type) {
    case "INTERACTION_CONDITION": {
      return (
        <SelectField<string>
          label="Condition"
          value={props.value as string}
          onChange={props.onChange}
          options={["none", "positiveResult", "exist", "notExist"]}
        />
      );
    }
    case "INTERACTION_OPERAND": {
      return (
        <SelectField<string>
          label="Operand"
          value={props.value as string}
          onChange={props.onChange}
          options={["increase", "decrease", "replace"]}
        />
      );
    }
    case "HINTS": {
      return (
        <Autocomplete
          sx={{ flex: 1 }}
          disablePortal
          id="combo-box-in"
          options={props.hints}
          freeSolo
          onChange={(x, y) => {
            if (typeof y === "string") {
              props.onChange(y);
            } else if (y?.key) {
              const newValue = props.hints.find((x) => x.key === y.key);
              if (newValue?.key) props.onChange(newValue?.key);
            } else throw new Error("There should be already a value");
          }}
          renderInput={(params) => (
            <TextField {...params} placeholder="Find path" />
          )}
        />
      );
    }
    default:
      return null;
  }
};
