import { Button, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { ModalProps } from "../../../../shared/components/modal/ModalProps";
import { useForm, useFormContext } from "react-hook-form";
import { cuni } from "../../../../shared/services/cuni/cuni";
import { WebHookType, WebHookSubType } from "../../../../model/WebHookMessage";
import { ActionFormValues } from "../ActionForm";
import { useTranslation } from "react-i18next";

type NewSessionModalProps = ModalProps;

interface NewSessionForm {
  name: string;
}

export const NewSessionModal = (props: NewSessionModalProps) => {
  const { open, handleClose } = props;

  const { watch } = useFormContext<ActionFormValues>();

  const { t } = useTranslation();

  const { register, handleSubmit, reset } = useForm<NewSessionForm>({
    defaultValues: {
      name: "",
    },
  });

  const onClose = () => {
    reset();
    handleClose();
  };

  const onSubmit = (form: NewSessionForm) => {
    cuni.pushMessage((game) => {
      return {
        type: WebHookType.UserText,
        color: "gray",
        subType: "NEW_SESSION" as WebHookSubType,
        user: `Story`,
        receiverId: watch("receiverId"),
        message: form.name,
      };
    });
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box className="modal">
          <Typography variant="h6" component="h2">
            {t("GAME.NEW_SESSION")}
          </Typography>
          <Typography id="modal-avatar-description" sx={{ mt: 2 }}>
            {t("GAME.CREATE_SESSION_NAME")}
          </Typography>
          <TextField multiline label={t('COMMON.NAME')} {...register("name")} />
          <Button type="submit" variant="contained">
            {t("COMMON.SEND")}
          </Button>
          <Button onClick={onClose}> {t("COMMON.CLOSE")}</Button>
        </Box>
      </form>
    </Modal>
  );
};
