import { Button } from "@mui/material";
import React from "react";
import { Game } from "../../../model/Game";
import { useTranslation } from "react-i18next";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { file } from "../../../shared/utils/file";
import { usePlanPermissions } from "../../../shared/hooks/usePlanPermissions";
import { PaymentTooltip } from "../../../shared/components/ui/PaymentTooltip";

interface Props {
  game: Game;
}
export const ExportGameButton = (props: Props) => {
  const { game } = props;
  const { t } = useTranslation();
  const { hasPermission } = usePlanPermissions();

  const exportGame = () => {
    const object = JSON.parse(JSON.stringify(game)) as Game;
    object.gameMasterId = 0;
    object.messages = [];
    delete object.root.currentPlayerId;
    Object.entries(object.characters).forEach(([id, char]) => {
      char.__userId = 0;
    });
    object.root.maps.forEach((map) => {
      map.elements = [];
    });

    file.export({ object, name: game.name });
  };

  return (
    <PaymentTooltip
      title={t("INFO.UPGRADE")}
      visible={!hasPermission("canExportImport")}
    >
      <Button
        disabled={!hasPermission("canExportImport")}
        startIcon={<FileDownloadIcon />}
        variant="outlined"
        onClick={exportGame}
      >
        {t("COMMON.EXPORT")}
      </Button>
    </PaymentTooltip>
  );
};
