import React from "react";
import "./MobileToolbar.css";
import { Button } from "@mui/material";

interface MobileToolbarProps {
  current: string;
  tabs: string[];
  set: (input: string) => void;
}

export const MobileToolbar = (props: MobileToolbarProps) => {
  const { current, set, tabs } = props;
  return (
    <div className="mobile-toolbar">
      {tabs.map((tab) => (
        <Button
          key={tab}
          variant={current === tab ? "outlined" : "text"}
          onClick={() => set(tab)}
        >
          {tab}
        </Button>
      ))}
    </div>
  );
};
