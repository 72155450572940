import React from "react";
import { UserTextMessage } from "../../../../model/WebHookMessage";

interface SimpleMessageViewProps extends UserTextMessage {
  nameColor?: string;
}

export const SimpleMessageView: React.FC<SimpleMessageViewProps> = (
  props: SimpleMessageViewProps,
) => {
  return (
    <div className="chat__message" style={{ color: props.color }}>
      <div className="chat__message--name">
        <strong style={{ color: props.nameColor }}>{`${props.user}: `}</strong>
      </div>
      <div>{props.message}</div>
    </div>
  );
};
