import { IconButton, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ReactComponent as CardRemoveIcon } from "../../../assets/icons/card_remove.svg";
import { useMaps } from "./MapProvider";
import { cuni } from "../../../shared/services/cuni/cuni";

export const ClearAllCards = () => {
  const { t } = useTranslation();

  const { all, current } = useMaps();
  const clearMap = () => {
    if (!current) return;
    current.deckPlaces = current.deckPlaces.filter((x) => x.type !== "card");
    cuni.object.update("maps", all, current.deckPlaces);
  };

  return (
    <Tooltip title={t("MAP.CLEAR_CARDS")}>
      <IconButton>
        <CardRemoveIcon onClick={clearMap} />
      </IconButton>
    </Tooltip>
  );
};
