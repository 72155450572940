import { GameDice } from "../../model/Game";
import { DiceView } from "./DiceView";

export interface DiceViewContainerProps {
  dice: GameDice;
}

export const DiceViewContainer = (props: DiceViewContainerProps) => {
  const { dice } = props;
  return <DiceView item={dice} sendRoll />;
};
