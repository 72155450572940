import { Button } from "@mui/material";
import { useState } from "react";
import { DeckSetModal, DeckSetModalProps } from "./DeckSetModal";
import { useCards } from "../chat/hooks/useCards";
import { useGame } from "../character/hooks/useGame";
import { CardView } from "../cards/CardView";

interface DeckSetViewProps extends Pick<DeckSetModalProps, "deckKey"> {}
export const DeckSetView = (props: DeckSetViewProps) => {
  const [open, setOpen] = useState(false);

  const { game } = useGame();
  const cards = useCards();
  const currentDeck = game.root.settings?.decks.find(
    (x) => x.key === props.deckKey,
  );

  const currentSubset = currentDeck?.subset || [];
  const components = cards
    .filter((x) => currentSubset.includes(x.key))
    .map((x) => <CardView {...x} />);

  return (
    <>
      <DeckSetModal {...props} open={open} handleClose={() => setOpen(false)} />
      <Button variant="contained" onClick={() => setOpen(true)}>
        Create subset
      </Button>
      <div>{components}</div>
    </>
  );
};
