import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import CancelIcon from "@mui/icons-material/Cancel";
import { IconButton } from "@mui/material";

export interface LoseOption {
  key: any;
  name?: string | JSX.Element;
  title?: string | JSX.Element | null;
}

export type SelectOption = LoseOption | string;

export interface FieldProps<T extends SelectOption> {
  label: string;
  value?: T | null;
  onChange: (...event: any[]) => void;
  options: T[];
  onClear?: boolean;
}

export default function SelectField<T extends SelectOption>(
  props: FieldProps<T>,
) {
  const { onChange, value, label, options, onClear } = props;

  const resolveKey = (opt?: SelectOption | null) =>
    typeof opt === "string" ? opt : opt?.key;
  const resolveName = (opt?: SelectOption | null) =>
    typeof opt === "string" ? opt : opt?.name || opt?.title;

  const handleChange = (event: SelectChangeEvent) => {
    onChange(options.find((x) => resolveKey(x) === event.target.value));
  };

  const handleClear = () => {
    onChange(undefined);
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id={`select-label-${label}`}>{label}</InputLabel>
        <Select
          labelId={`select-label-${label}`}
          id={`select-${label}`}
          value={value ? resolveKey(value) : ""}
          label={label}
          onChange={handleChange}
          startAdornment={
            onClear ? (
              <IconButton onClick={() => handleClear()}>
                <CancelIcon />
              </IconButton>
            ) : undefined
          }
        >
          {options.map((x) => (
            <MenuItem key={resolveKey(x)} value={resolveKey(x)}>
              {resolveName(x)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
