import { Button, ButtonProps, CircularProgress } from "@mui/material";
import React from "react";

interface LoadingButtonProps extends ButtonProps {
  loading?: boolean;
}
export const LoadingButton = React.forwardRef<
  HTMLButtonElement,
  LoadingButtonProps
>(({ loading, children, ...props }, ref) => {
  return (
    <Button ref={ref} {...props}>
      {loading ? (
        <CircularProgress
          sx={{ color: props.variant === "contained" ? "black" : "white" }}
          size="1.5rem"
        />
      ) : (
        children
      )}
    </Button>
  );
});
