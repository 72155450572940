import { useEffect, useMemo, useRef, useState } from "react";
import { SwitchView } from "../../../shared/components/ui/switch-view/SwitchView";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { apiClient } from "../../../shared/services/apiClient";
import { config } from "../../../config";
import { useForm } from "react-hook-form";
import { Button, TextField, Typography } from "@mui/material";
import { Resource, t } from "i18next";
import { Deck, Project } from "../../../model/Project";
import { Table } from "../../../shared/components/ui/Table";
import { useUser } from "../../auth/useUser";
import { ProjectDeckDetailView } from "./ProjectDeckDetailView";
import { Conditional } from "../../../shared/components/logic/Conditional";

export const ProjectDetails = () => {
  const [view, setView] = useState<string | null>(null);
  const currentDeck = useRef<Deck>();

  let { id } = useParams();
  const { data: project } = useProject(id);

  const { register, reset, watch } = useForm<Project>({
    defaultValues: project,
  });

  useEffect(() => {
    reset(project);
  }, [project, reset]);

  const user = useUser();
  const { mutate } = usePutProject();
  const { mutate: mutateDeck } = usePostDeck();

  // not sure if it make sense like it should also be possible to edit deck itself...
  const add = () => {
    if (project?.id)
      mutateDeck({
        creatorId: user?.id,
        projectDecks: [
          {
            projectId: project?.id,
          },
        ],
      });
  };

  const { rows, headers } = useMemo(() => {
    const items = project?.projectDecks.map((x) => x.deck) || [];
    const headers = [
      {
        key: "name",
        title: t("COMMON.NAME"),
      },
      {
        key: "action",
        title: t("COMMON.ACTION"),
        cell: (value: Deck) => (
          <Button
            onClick={() => {
              currentDeck.current = value;
              setView("deck");
            }}
          >
            {t("COMMON.OPEN")}
          </Button>
        ),
      },
    ];
    const rows = items.map((x) => ({
      ...x,
      name: x?.name || "",
      action: "",
    }));
    return { rows, headers };
  }, [project]);

  // TODO: Add deck display like a list and add scan option in the Project Deck Detail View
  return (
    <SwitchView
      setView={setView}
      view={view}
      viewComponents={{
        deck: (
          <Conditional
            condition={currentDeck.current?.id}
            success={(deckId) => <ProjectDeckDetailView deckId={deckId} />}
          />
        ),
      }}
    >
      <div>{t("COMMON.PROJECT")}</div>
      <TextField
        label={t("COMMON.NAME")}
        {...register("name")}
        onBlur={(e) => {
          mutate({ id: project?.id, name: watch("name") });
        }}
      />
      <Typography>{t("CARD.OVERRIDE_INFO")}</Typography>
      <Button variant="outlined" onClick={add}>
        {t("CARD.DECK_ADD")}
      </Button>

      <Table
        directList
        headers={headers}
        className="paper-outline"
        rows={rows}
        disableGenericMenu
        standalone
        onRowRemove={(resource: Resource) => {
          //   if (resource?.id) remove(resource?.id);
        }}
      />
    </SwitchView>
  );
};

const useProject = (id: string | undefined) => {
  return useQuery({
    queryKey: ["project"],
    queryFn: () =>
      apiClient
        .get<Project>(
          `${config.baseUrl}/api/project/${id}?expand=ProjectDecks&expand=ProjectDecks.Deck`,
        )
        .then((res) => res.data),
  });
};

const usePutProject = () => {
  return useMutation({
    mutationFn: (data: Partial<Project>) =>
      apiClient.put<Project>(`${config.baseUrl}/api/project`, data),
  });
};

const usePostDeck = () => {
  return useMutation({
    mutationFn: (data: Partial<Deck>) =>
      apiClient.post<Deck>(`${config.baseUrl}/api/deck`, data),
  });
};
