import { cuni } from "../../../../../../shared/services/cuni/cuni";
import { uuid } from "../../../../../../shared/utils/uuid";
import { useUser } from "../../../../../auth/useUser";
import { useGame } from "../../../../hooks/useGame";

export const useAddToMap = () => {
  const { game } = useGame();
  const user = useUser();

  const addToMap = (panelKey: string) => {
    const all = game?.root?.maps || [];
    const current = all.find((x) => x.active)!;
    if (!current) console.warn("There is no active map");

    current.elements ??= [];
    cuni.array.push("maps", all, current.elements, {
      key: uuid(),
      type: "PANEL",
      panelKey,
      ownerId: user?.id,
      userIds: user?.id ? [user?.id] : [],
    });
  };

  return { addToMap };
};
