import React, { useEffect, useMemo } from "react";
import { Box, Tabs, Tab } from "@mui/material";
import { useCharacterTabs } from "./CharacterTabProvider";
import { CharacterTabPanel } from "../../../../model/Game";
import { useGameEdit } from "../../../schema/hooks/useGameEdit";

export interface GenericTabPanelsProps {
  tabs: CharacterTabPanel[];
  padding?: boolean;
}
export const GenericTabPanels = (props: GenericTabPanelsProps) => {
  const { tabs, padding } = props;
  const { editEnabled } = useGameEdit();

  const { tabId, setTab, initialTab } = useCharacterTabs();
  const newTabs: CharacterTabPanel[] = useMemo(() => {
    return [initialTab, ...tabs];
  }, [tabs, initialTab]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    const tab = newTabs?.[newValue];
    if (!tab) throw new Error("There should be tab when selecting.");

    setTab({ tab, tabId: newValue });
  };

  useEffect(() => {
    setTab((prev) => {
      let newTab = newTabs[prev.tabId];
      if (newTab) return { tab: newTab, tabId: prev.tabId };
      newTab = newTabs[prev.tabId - 1];
      return { tab: newTab, tabId: prev.tabId - 1 };
    });
  }, [newTabs, setTab]);

  if (newTabs.length === 1 && !editEnabled) {
    if (padding) return <Box padding="0.25rem"/>;
    return null;
  }
  return (
    <Box className="paper paper__tab">
      <Tabs
        value={tabId}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
      >
        {newTabs.map((tab) => (
          <Tab
            key={tab.title}
            label={tab.title}
            style={
              editEnabled || tab.visible || tab.title === "main"
                ? undefined
                : { display: "none" }
            }
          />
        ))}
      </Tabs>
    </Box>
  );
};
