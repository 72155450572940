import { Box, Divider, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { config } from "../../config";
import { apiClient } from "../../shared/services/apiClient";
import { Friend } from "../../model/Friend";
import { useMemo } from "react";
import { UserOption, mapUserToOption } from "../../model/User";
import { useUser } from "../auth/useUser";
import { useTranslation } from "react-i18next";

export const FriendsList = () => {
  const { data, isLoading } = useFriends();
  const { t } = useTranslation();

  if (isLoading || !data || !data.length) return null;
  const friends = data.map((item) => (
    <Box
      key={item.id}
      display="flex"
      flexDirection="column"
      gap="1rem"
      padding="1rem"
    >
      <Typography>
        <u>{item.userName}</u>
      </Typography>
    </Box>
  ));
  return (
    <Box className="paper" display="flex" flexDirection="column" gap="0.25rem">
      <Typography variant="h6">{t("PROFILE.FRIENDS")}</Typography>
      <Divider />
      {friends}
    </Box>
  );
};

export const useGetFriend = () => {
  return useQuery({
    queryKey: ["friend"],
    queryFn: () =>
      apiClient
        .get<Friend[]>(
          `${config.baseUrl}/api/friend?expand=Receiver&expand=Sender`,
        )
        .then((res) => res.data),
  });
};

export const useFriends = (all: boolean = false) => {
  const user = useUser();
  const { data, ...others } = useGetFriend();

  const friendsData = useMemo(() => {
    const friends = (data ?? []).map((x) =>
      user?.id === x.senderId ? x.receiver : x.sender,
    );
    return all && user ? [...friends, user] : friends;
  }, [data, all, user]);

  const options = useMemo(() => {
    return friendsData.map<UserOption>(mapUserToOption);
  }, [friendsData]);

  return {
    data: friendsData,
    options,
    ...others,
  };
};
