import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { ModalProps } from "../../../../shared/components/modal/ModalProps";
import { useGame } from "../../hooks/useGame";
import { StoryNote } from "../../../../model/StoryNote";
import { apiClient } from "../../../../shared/services/apiClient";
import { useQuery } from "@tanstack/react-query";
import { config } from "../../../../config";
import { useUser } from "../../../auth/useUser";
import { Conditional } from "../../../../shared/components/logic/Conditional";
import "./StoryModal.css";
import { useTranslation } from "react-i18next";

type StoryModalProps = ModalProps;

export const StoryModal = (props: StoryModalProps) => {
  const { open, handleClose } = props;

  const { t } = useTranslation();
  const { game } = useGame();
  const { data } = useStoryNote(game.id);
  if (!data) return null;
  return (
    <Modal open={open} onClose={handleClose}>
      <Box className="modal">
        <Typography variant="h6" component="h2">
          {t('STORY.STORY')}
        </Typography>
        <ul className="storymodal__list">
          {data.map((event) => (
            <li key={event.id} className="storymodal__list-item">
              <Conditional
                condition={event.created}
                success={(created) => (
                  <div>
                    <strong className="storymodal__title">
                     {t("STORY.DATE_OF_ENTRY")}:
                    </strong>{" "}
                    {new Date(created).toLocaleString()}
                  </div>
                )}
              />
              <hr />

              <div>
                <strong className="storymodal__title">
                  {t("STORY.NOTE_POINT")}:
                </strong>{" "}
                {event.note}
              </div>
            </li>
          ))}
        </ul>
        <Button onClick={handleClose}>{t('COMMON.CLOSE')}</Button>
      </Box>
    </Modal>
  );
};

export const useStoryNote = (gameId: number) => {
  const user = useUser();
  return useQuery({
    queryKey: ["storynote-all"],
    queryFn: () =>
      apiClient
        .get<StoryNote[]>(
          `${config.baseUrl}/api/storynote/?Where=GameId == ${gameId}&Where=ReceiverId == ${user?.id} or ReceiverId == null`,
        )
        .then((res) => res.data),
  });
};
