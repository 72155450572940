import { Game, GameCard } from "../../model/Game";
import { cuni } from "../services/cuni/cuni";

export function shuffle<T>(array: T[]) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

/**
 * Generate deck via cards multipled by card amount.
 */
const generate = (deck: GameCard[]): string[] => {
  return shuffle(
    deck.flatMap((x) =>
      Array.from(Array(x.amount ?? 1).keys()).map((y) => x.key),
    ),
  );
};

const pickFromDeck = (game: Game, deckKey: string) => {
  const current = game.root?.maps.find((x) => x.active);
  const dbDeckPanel = current?.deckPlaces.find((x) => x.key === deckKey);
  if (!dbDeckPanel) return;

  let card: string | undefined = undefined;
  if (
    dbDeckPanel.type === "card" &&
    dbDeckPanel.card?.key &&
    current?.deckPlaces
  ) {
    card = dbDeckPanel.card.key;
    const toRemove = current.deckPlaces.find((x) => x.key === dbDeckPanel.key);
    cuni.object.remove("maps", game.root?.maps, toRemove);
  } else {
    card = dbDeckPanel.cards[dbDeckPanel.cards.length - 1];
    cuni.array.removeLast("maps", game.root?.maps, dbDeckPanel.cards);
    dbDeckPanel.cards.pop();
  }
  return card;
};

export const cardUtils = { pickFromDeck, generate, shuffle };
