import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useState } from "react";
import { IconButton, Tooltip } from "@mui/material";
import { AddMapModal } from "./AddMapModal";
import { AreYouSureModal } from "../../../shared/components/modal/AreYouSureModal";
import { useMaps } from "./MapProvider";
import { Conditional } from "../../../shared/components/logic/Conditional";
import HexagonIcon from "@mui/icons-material/Hexagon";
import SquareIcon from "@mui/icons-material/Square";
import { useTranslation } from "react-i18next";
import WallpaperIcon from "@mui/icons-material/Wallpaper";
import TokenIcon from "@mui/icons-material/Token";
import { Popover } from "../../../shared/components/ui/popover/Popover";
import { TokenPicker } from "./TokenPicker";
import { ReactComponent as CardIcon } from "../../../assets/icons/card.svg";
import CasinoIcon from "@mui/icons-material/Casino";
import { DicePicker } from "../../dices/DicePicker";

export const MapActions = () => {
  const {
    current,
    addDeck,
    onNext,
    onPrev,
    onRemove,
    markAsActive,
    changeType,
    addBackground,
    addToken,
    addDice,
  } = useMaps();
  const [modal, setModal] = useState("");

  const { t } = useTranslation();

  const isSquare = current?.isSquare;

  return (
    <div className="paper paper-0 flex">
      <Tooltip title={t("MAP.REMOVE")}>
        <IconButton color="error" onClick={() => setModal("REMOVE_MAP")}>
          <CancelIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title={t("MAP.ADD")}>
        <IconButton onClick={() => setModal("ADD_MAP")}>
          <AddIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title={t("MAP.PREVIOUS")}>
        <IconButton onClick={onPrev}>
          <ChevronLeftIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title={t("MAP.NEXT")}>
        <IconButton onClick={onNext}>
          <ChevronRightIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title={t("MAP.TYPE")}>
        <IconButton onClick={changeType}>
          {isSquare ? <HexagonIcon /> : <SquareIcon />}
        </IconButton>
      </Tooltip>

      <Tooltip title={t("MAP.MARK")}>
        <IconButton onClick={markAsActive}>
          <Conditional
            condition={current?.active}
            success={() => <CheckCircleIcon />}
            failure={() => <CheckCircleOutlineIcon />}
          />
        </IconButton>
      </Tooltip>

      <Tooltip title={t("MAP.BACKGROUND")}>
        <IconButton onClick={() => setModal("BACKGROUND")}>
          <WallpaperIcon />
        </IconButton>
      </Tooltip>

      <Popover
        renderButton={(onClick) => (
          <Tooltip title={t("MAP.TOKEN")}>
            <IconButton
              onClick={(e) => {
                onClick(e);
              }}
            >
              <TokenIcon />
            </IconButton>
          </Tooltip>
        )}
      >
        <TokenPicker
          onClick={(token) => {
            addToken(token);
          }}
        />
      </Popover>

      <Tooltip title={t("CARD.DECK")}>
        <IconButton onClick={() => addDeck()}>
          <CardIcon style={{ width: 24, height: 24 }} />
        </IconButton>
      </Tooltip>

      <Popover
        renderButton={(onClick) => (
          <Tooltip title={t("GAME.ADD_DICE")}>
            <IconButton
              onClick={(e) => {
                onClick(e);
              }}
            >
              <CasinoIcon />
            </IconButton>
          </Tooltip>
        )}
      >
        <DicePicker
          onClick={(dice) => {
            addDice(dice);
          }}
        />
      </Popover>

      <AreYouSureModal
        title={t("MAP.BACKGROUND")}
        content={t("MAP.BACKGROUND_DESC")}
        confirmAction={(fields) => {
          if (fields) addBackground(fields.value);
        }}
        open={modal === "BACKGROUND"}
        handleClose={() => setModal("")}
        fields={[
          {
            name: "value",
          },
        ]}
      />

      <AreYouSureModal
        title="Delete map"
        content={`Are you sure you want to delete current map ?`}
        handleClose={() => setModal("")}
        confirmText="Delete"
        confirmAction={onRemove}
        open={modal === "REMOVE_MAP"}
      />
      <AddMapModal
        open={modal === "ADD_MAP"}
        handleClose={() => setModal("")}
      />
    </div>
  );
};
